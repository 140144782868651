import { ALayoutSpacer } from 'component_lib';
import { Row } from 'component/layout/Row';
import { Text } from 'component/text/Text';
import { FormShippingDate } from '..';
import { useT } from '../../helper/i18n';

export const ReturnSelectionPickup: React.FC = () => {
  const { t } = useT();
  return (
    <>
      <ALayoutSpacer desktopHeight={'30px'} mobileHeight={'30px'} />
      <Text bold>{t('returns::book:title')}</Text>
      <ALayoutSpacer desktopHeight={'30px'} mobileHeight={'25px'} />
      <Row>
        <Text fontSizeDesktop="12px">{t('returns::book:text1')}</Text>
      </Row>
      <ALayoutSpacer height={'20px'} />
      <Text fontSizeDesktop="12px">{t('returns::book:text2')}</Text>
      <ALayoutSpacer desktopHeight={'40px'} mobileHeight={'25px'} />
      <FormShippingDate t={t} />
    </>
  );
};
