import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { IAppStore, IReturnSummary } from '../../common/type';

/* Interfaces */
interface IProps {
  returnSummary: IReturnSummary;
}

/* Helpers */
const mapStateToProps = ({ returnSummary }: IAppStore) => ({ returnSummary });

/* Component */
const RedirectIfEmptySummaryBase = ({ returnSummary }: IProps) =>
  !Object.keys(returnSummary).length ? <Redirect to={'/'} /> : null;

export const RedirectIfEmptySummary = withRouter(connect(mapStateToProps)(RedirectIfEmptySummaryBase));
