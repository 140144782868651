import { Resource } from 'i18next';
import { LanguagesConfig } from './types/LanguagesConfig';
import { getActiveLangOrOverride } from './helpers/getActiveLangOrOverride';
import {
  categoriesDescriptionsNamespace,
  categoriesNamesNamespace,
  departmentsNamespace,
  searchTermsNamespace,
} from './consts/namespaces';

export const loadI18nResources = async ({
  langIsoCode2,
  countryIsoCode2,
  namespaces = [],
  languagesConfig,
  i18nDataFileImporter,
}: {
  countryIsoCode2: string;
  i18nDataFileImporter: (relativePath: string) => Promise<any>;
  langIsoCode2: string;
  languagesConfig: LanguagesConfig;
  namespaces?: string[];
}): Promise<{
  categoryNamesToIds: Record<string, number>;
  resources: Resource;
  searchTerms: Record<string, Record<string, string>>;
}> => {
  const langOrOverride = getActiveLangOrOverride({
    countryIsoCode2,
    langIsoCode2,
    languagesConfig,
  });

  const jsonImports = namespaces.map(async (ns) => {
    let translations;
    try {
      translations = await i18nDataFileImporter(`${langOrOverride}/${ns}.json`);
    } catch (e) {
      // fallback for unsupported languages
      translations = {};
    }
    return { langOrOverrideCode: langOrOverride, ns, translations };
  });

  if (langOrOverride !== 'en') {
    const fallbackLangImports = namespaces.map(async (ns) => {
      const translations = await i18nDataFileImporter(`en/${ns}.json`);
      return { langOrOverrideCode: 'en', ns, translations };
    });
    jsonImports.push(...fallbackLangImports);
  }

  const translations = await Promise.all(jsonImports);

  const resources: Resource = {};
  const categoryNamesToIds: Record<string, number> = {};
  const searchTerms: Record<string, Record<string, string>> = {};

  for (let i = 0; i < translations.length; i++) {
    const { langOrOverrideCode: lc, ns, translations: ts } = translations[i];

    if (
      ![categoriesDescriptionsNamespace, categoriesNamesNamespace, searchTermsNamespace, departmentsNamespace].includes(
        ns,
      )
    ) {
      if (!resources[lc]) {
        resources[lc] = {};
      }
      resources[lc][ns] = ts;
      continue;
    }

    if (ns === 'searchTerms') {
      searchTerms[lc] = ts;
      continue;
    }

    const categories: Record<string, string> = {};
    Object.entries(ts).forEach(([categoryId, categoryData]) => {
      if (ns === categoriesNamesNamespace) {
        const { categoryName } = categoryData as any;
        if (categoryName) {
          categoryNamesToIds[categoryName.toLowerCase()] = parseInt(categoryId, 10);
        }
        categories[`name:${categoryId}`] = categoryName;
      }

      if (ns === categoriesDescriptionsNamespace) {
        const { categoryDescription } = categoryData as any;
        categories[`description:${categoryId}`] = categoryDescription;
      }

      if (ns === departmentsNamespace) {
        const { departmentDescription } = categoryData as any;
        categories[`description:${categoryId}`] = departmentDescription;
      }
    });

    if (!resources[lc]) {
      resources[lc] = {};
    }
    if (!resources[lc].category) {
      resources[lc].category = {};
    }
    resources[lc].category = {
      ...categories,
      ...(resources[lc].category as Record<string, string>),
    };
  }

  return { categoryNamesToIds, resources, searchTerms };
};
