import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { color } from 'component_lib';

/* Interfaces */
interface ISpacerProps {
  desktopHeight?: string;
  height?: string;
  mobileHeight?: string;
}

/* Components */
export const ALayoutFence = styled.div`
  flex: 1;
  width: 100%;
`;

export const ALayoutFenceRow = styled(Row)`
  height: 100%;
  max-width: 1040px;
  padding: 14px;
  width: 100vw;
`;

export const ALayoutFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  margin-top: 30px;
`;

export const ALayoutRowNoMargin = styled(Row)`
  &&& {
    margin: 0;
  }
`;

export const ALayoutSpacer = styled.div`
  @media (max-width: 1023px) {
    ${({ mobileHeight, height }: ISpacerProps) => (mobileHeight || height ? `height: ${mobileHeight || height};` : '')}
    ${({ mobileHeight, height }) => (mobileHeight || height ? 'width: 100%;' : '')}
  }

  @media (min-width: 1024px) {
    ${({ desktopHeight, height }: ISpacerProps) =>
      desktopHeight || height ? `height: ${desktopHeight || height};` : ''}
    ${({ desktopHeight, height }) => (desktopHeight || height ? 'width: 100%;' : '')}
  }
`;

export const ALayoutColAlignLeft = styled(Col)`
  text-align: left;
`;

export const ALayoutColAlignCenter = styled(Col)`
  text-align: center;
`;

export const ALayoutColAlignRight = styled(Col)`
  text-align: right;
`;

export const ALayoutColNoPad = styled(Col)`
  &&& {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ALayoutColNoPadLeft = styled(ALayoutColNoPad)`
  &&& {
    padding-right: 5px;
  }

  html[dir='rtl'] &&& {
    padding-left: 5px;
    padding-right: unset;
  }
`;

export const ALayoutColNoPadRight = styled(ALayoutColNoPad)`
  &&& {
    padding-left: 5px;
  }

  html[dir='rtl'] &&& {
    padding-left: unset;
    padding-right: 5px;
  }
`;

export const FooterDiv = styled.div`
  border-top: 1px solid ${color.greyLight};
  &&& {
    padding: 20px 20px;
  }
`;
