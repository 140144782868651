import styled from 'styled-components';

/* Components */

export const ACircleError = styled.div`
  background-image: url(https://d3nn9jcidi1lkh.cloudfront.net/mkt/icon/exclamation.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60px;
  box-sizing: border-box;
  display: inline-block;
  height: 60px;
  vertical-align: middle;
  width: 60px;
`;
