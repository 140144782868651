import { FC } from 'react';

export const GTMNoScript: FC = () => {
  const production = process.env.ENVIRONMENT === 'production';

  if (production) {
    return (
      <noscript>
        {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MT8FG3C&gtm_auth=OMugLOK4jOE0XKE7ykS_FA&gtm_preview=env-1&gtm_cookies_win=x"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
      </noscript>
    );
  }

  return (
    <noscript>
      {`
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MT8FG3C&gtm_auth=I6vEoKOM4HvivZgWFqVYTQ&gtm_preview=env-110&gtm_cookies_win=x"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `}
    </noscript>
  );
};
