import styled from 'styled-components';

/* Styles */
const SocialIcon = styled.img`
  height: 22px;
`;

/* Components */
export const ASocialPrimaryFacebook = () => (
  <a href="http://www.facebook.com/italistofficial">
    <SocialIcon src="https://www.italist.com/images/mkt/icon/icon_fb.png" />
  </a>
);

export const ASocialPrimaryInstagram = () => (
  <a href="http://instagram.com/italist">
    <SocialIcon src="https://www.italist.com/images/mkt/icon/icon_instagram.png" />
  </a>
);

export const ASocialPrimaryPinterest = () => (
  <a href="http://www.pinterest.com/italist">
    <SocialIcon src="https://www.italist.com/images/mkt/icon/icon_pinterest.png" />
  </a>
);

export const ASocialPrimaryTwitter = () => (
  <a href="http://www.twitter.com/italistofficial">
    <SocialIcon src="https://www.italist.com/images/mkt/icon/icon_twitter.png" />
  </a>
);
