import { Col } from 'react-flexbox-grid';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import { ACircleError, ALayoutRowNoMargin, ALayoutSpacer, ATitleSecondary } from '../../atom';
import { color } from '../../theme/StylePalette';

/* Interfaces */
interface IMErrorMessageProps {
  message: string;
}

/* Styles */
const ErrorText = styled(ATitleSecondary)`
  color: ${color.redDark};
  text-transform: initial;
`;

/* Component */
export const MErrorMessage = ({ message }: IMErrorMessageProps) => {
  if (message) {
    return (
      <>
        <ALayoutRowNoMargin center="xs" middle="xs">
          <ACircleError />

          <Col xs={9} sm={8} md={6}>
            <ErrorText>{message}</ErrorText>
          </Col>
        </ALayoutRowNoMargin>

        <MediaQuery maxWidth={767}>
          <ALayoutSpacer height={'20px'} />
        </MediaQuery>
      </>
    );
  }
  return null;
};
