export const apiPath = {
  defaults: () => '/api/defaults',
  getPreferredLanguage: () => '/api/get_preferred_language',
  orders: () => '/api/orders',
  ordersInvoices: (orderId: string) => `/api/orders/${orderId}/invoices`,
  profile: () => '/api/profile',
  returns: (returnId: string) => `/api/returns/${returnId}`,
  returnsFinalizeProcess: (storeOrderId: string, productStoreOrderId: string) =>
    `/api/returns/finalize_process/${storeOrderId}/${productStoreOrderId}`,
  returnsPickUpDates: (storeOrderId: string) => `/api/returns/pick_up_dates/${storeOrderId}`,
  returnsReason: (storeOrderId: string, productStoreOrderId: string) =>
    `/api/returns/reason/${storeOrderId}/${productStoreOrderId}`,
  trackOrder: (storeOrderId: string) => `/api/track_order/${storeOrderId}`,
  tracking: () => '/api/tracking',
  getReturnRestrictedCategories: () => '/api/return_restricted_categories',
};

export const browserPath = {
  chooseReturnShippingDate: () => '/choose_return_shipping_date',
  newReturn: (storeOrderId: string, productStoreOrderId: string, index: string) =>
    `/new_return/${storeOrderId}/${productStoreOrderId}/${index}`,
  newReturnWarning: (storeOrderId: string, productStoreOrderId: string, index: string) =>
    `/new_return_warning/${storeOrderId}/${productStoreOrderId}/${index}`,
  orderDetail: (orderId: string) => `/orders/${orderId}`,
  orders: () => '/',
  pack: (returnId?: string) => `/pack/${returnId ? `${returnId}/` : ''}`,
  printReturn: (returnId?: string) => `/print_return/${returnId ? `${returnId}/` : ''}`,
  profile: () => '/profile',
  returnOverview: (storeOrderId: string, productStoreOrderId: string, index: string) =>
    `/return_overview/${storeOrderId}/${productStoreOrderId}/${index}`,
};
