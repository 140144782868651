import React from 'react';
import { ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { setDefaultReturnReasonId } from '../../common/action/creator';
import { IAppStore, IReturnReason, ReturnReason } from '../../common/type';
import DifferentArticleSubtext from '../DifferentArticleSubtext';
import { FormReturnReason } from './FormReturnReason';
import { TranslationFunc } from '../../helper/i18n';
import { translateReasons } from '../../helper/helpers';
import { Text } from '../text/Text';

/* Interfaces */
interface IProps {
  handleSetDefaultReturnReasonId: () => void;
  returnReasonId: string;
  returnReasons: IReturnReason[];
  t: TranslationFunc;
}

/* Helpers */
const generateReturnReasonGroups = (reasons: IReturnReason[], t: TranslationFunc) => {
  let category = '';
  let i = 0;
  const headings = [t('returns::reasons:heading1'), t('returns::reasons:heading3')];
  const unusedReasons = [ReturnReason.colorHueSeemedDifferent, ReturnReason.materialSeemedDifferent];

  return reasons
    .filter((reason) => !unusedReasons.includes(reason.reasonId))
    .reduce((rows: React.ReactNode[], { reasonCategory, reasonId }: IReturnReason) => {
      let spacer = '20px';

      if (category !== reasonCategory) {
        spacer = '25px';
        rows.push(
          <>
            <ALayoutSpacer desktopHeight={'60px'} mobileHeight="40px" />
            {i === 1 && <DifferentArticleSubtext />}
            <Text bold key={reasonCategory} uppercase>
              {headings[i]}
            </Text>
          </>,
        );
        category = reasonCategory;
        i += 1;
      }

      rows.push(
        <FormReturnReason key={reasonId} label={translateReasons(reasonId, t)} reasonId={reasonId} spacer={spacer} />,
      );

      return rows;
    }, []);
};

const mapStateToProps = ({ returnReasonId, returnReasons }: IAppStore) => ({
  returnReasonId,
  returnReasons,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleSetDefaultReturnReasonId: () => dispatch(setDefaultReturnReasonId()),
});

/* Component */
class FormReturnReasonsBase extends React.Component<IProps> {
  public componentDidMount() {
    this.props.handleSetDefaultReturnReasonId();
  }

  public render() {
    const { t, returnReasons } = this.props;

    return (
      <>
        <ALayoutSpacer mobileHeight={'60px'} />
        <Text bold>{t('returns::reasons:helper')}</Text>
        <ALayoutSpacer desktopHeight={'25px'} mobileHeight={'35px'} />

        {generateReturnReasonGroups(returnReasons, t)}
      </>
    );
  }
}

export const FormReturnReasons = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormReturnReasonsBase));
