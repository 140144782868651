import langAr from 'i18n-iso-countries/langs/ar.json';
import langJa from 'i18n-iso-countries/langs/ja.json';

export const translatedCountryNames = {
  ar: langAr.countries,
  ja: langJa.countries,
};

export const translatedCurrencyCode = {
  ar: {
    eur: 'يورو',
  },
  ja: {
    eur: 'ユーロ',
  },
};

export const translatedLangNames = {
  ar: {
    ar: 'عربى',
    en: 'الإنجليزية',
  },
  en: {
    ar: 'Arabic',
    en: 'English',
    ja: 'Japanese',
    ko: 'Korean',
    zh: 'Chinese',
  },
  ja: {
    en: '英語',
    ja: '日本語',
  },
};

export const translatedCountryChangePopupMsgs = {
  ar: {
    goToCountry: ({ country }: { country: string }) => `انتقل إلى ${country}`,
    offerCountryChangePopup: ({ country }: { country: string }) => `نعتقد أنك في ${country}. هل توّد تحديث موقعك؟`,
    stayInCountry: ({ country }: { country: string }) => `مقيم في ${country}`,
  },
  en: {
    goToCountry: ({ country }: { country: string }) => `Go to ${country}`,
    offerCountryChangePopup: ({ country }: { country: string }) =>
      `We believe you are in ${country}. Would you like to update your location?`,
    stayInCountry: ({ country }: { country: string }) => `Stay in ${country}`,
  },
  ja: {
    goToCountry: ({ country }: { country: string }) => `${country}に変更します`,
    offerCountryChangePopup: ({ country }: { country: string }) =>
      `お客様は${country}にお住まいでお間違えないですか？お住まいの国を変更されますか？`,
    stayInCountry: ({ country }: { country: string }) => `${country}で間違いありません`,
  },
};
