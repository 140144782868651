import { AAnimationSpinnerPrimary, ALayoutSpacer, AModalDialogPrimary, ATextSecondary } from '../../atom';
import { color } from '../../theme/StylePalette';
import styled from 'styled-components';

/* Interfaces */
export interface IMModalProcessingMessage {
  message: string;
}

/* Styles */
const ProcessingMessage = styled(ATextSecondary)`
  color: ${color.white};
  text-align: center;
  text-transform: uppercase;
`;

/* Components */
export const MModalProcessingMessage = ({ message }: IMModalProcessingMessage) => (
  <AModalDialogPrimary>
    <AAnimationSpinnerPrimary />
    <ALayoutSpacer height={'25px'} />
    <ProcessingMessage>{message}</ProcessingMessage>
  </AModalDialogPrimary>
);
