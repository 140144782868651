import { colors, fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';

/* Interfaces */
export interface IMRadioButtonProps {
  checked: boolean;
  error?: boolean;
  fontSize?: string;
  label: string;
  labelId?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  textColor?: string;
  uppercase?: boolean;
  value: string;
}

const Radio = styled.input<{ error: boolean }>`
  cursor: pointer;
  appearance: none;
  background: ${colors.white}
  border: solid 1px ${({ error }) => (error ? colors.red : colors.black)};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 3px 20px 0 0;
  
  &.return {
    border: none;
    height: 12px;
    width: 12px;
    box-shadow: 0px 0px 0px 3px ${colors.white}, 0px 0px 0px 4px ${({ error }) => (error ? colors.red : colors.black)};
  } 

  &:checked {
    background: ${colors.black};
    border: ${colors.black};
  }

  html[dir='rtl'] & {
    margin-left: 20px;
    margin-right: 0;
  }
`;

const Label = styled.span`
  font-family: ${fontFamilies.regular};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
`;

const ReturnLabel = styled.label<{ fontSize?: string }>`
  font-family: ${fontFamilies.bold};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : '16px')};
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
  cursor: pointer;
`;

/* Component */
export const RadioButton = (props: IMRadioButtonProps) => {
  const { checked, onChange, label, value, error } = props;

  return (
    <Row>
      <Column flex={0}>
        <Radio checked={checked} onChange={onChange} value={value} error={error} type="radio" />
      </Column>
      <Column>
        <Label>{label}</Label>
      </Column>
    </Row>
  );
};

export const ReturnRadioButton = (props: IMRadioButtonProps) => {
  const { checked, onChange, label, labelId, value, error, fontSize } = props;
  return (
    <Row>
      <Column flex={0}>
        <Radio
          className={'return'}
          checked={checked}
          id={labelId}
          onChange={onChange}
          value={value}
          error={error}
          type="radio"
        />
      </Column>
      <Column>
        <ReturnLabel htmlFor={labelId} fontSize={fontSize}>
          {label}
        </ReturnLabel>
      </Column>
    </Row>
  );
};
