import React, { ComponentType, ReactElement, useMemo } from 'react';
import i18next, { TFunctionDetailedResult } from 'i18next';
import { useT } from '../useT';
import { TagTree } from './TagTree';

const TrImpl = <
  KeysWithNS extends Record<string, any>,
  Components extends Record<string, any>,
  Placeholders extends Record<string, any>,
  K extends keyof Components,
>(
  {
    k,
    components,
    placeholders,
    fallback,
    useEnglish,
  }: {
    components: Components;
    fallback?: () => ReactElement;
    k: K;
    placeholders?: Placeholders;
    useEnglish?: boolean;
  },
  overrideI18nextInstance?: typeof i18next,
  overrideCategoryNamesToIds?: Record<string, number>,
): ReactElement => {
  const {
    t,
    tp,
    i18next: i18nextInstance,
  } = useT<KeysWithNS, Components, Placeholders>(overrideI18nextInstance, overrideCategoryNamesToIds);

  let text: string | TFunctionDetailedResult<string, any> = '';
  if (fallback && !i18nextInstance.exists(k as string)) {
    return fallback();
  }
  if (placeholders) {
    text = tp(k as any, placeholders, { useEnglish });
  } else {
    text = t(k as any, { useEnglish });
  }

  const tree = useMemo(() => new TagTree(text, components), [text, components]);

  return <>{tree.getNodes()}</>;
};

const TrFactory =
  (overrideI18nextInstance?: typeof i18next, overrideCategoryNamesToIds?: Record<string, number>) =>
  <
    Components extends Record<string, any>,
    Placeholders extends Record<string, any>,
    K extends Exclude<keyof Components, keyof Placeholders>,
  >({
    k,
    components,
    fallback,
    useEnglish,
  }: {
    components: Record<Components[K], ComponentType>;
    fallback?: () => ReactElement;
    k: K;
    useEnglish?: boolean;
  }): ReactElement => {
    return TrImpl({ components, fallback, k, useEnglish } as any, overrideI18nextInstance, overrideCategoryNamesToIds);
  };

const TrWithPlaceholdersFactory =
  (overrideI18nextInstance?: typeof i18next, overrideCategoryNamesToIds?: Record<string, number>) =>
  <
    Components extends Record<string, any>,
    Placeholders extends Record<string, any>,
    K extends keyof Placeholders & keyof Components,
  >({
    k,
    components,
    placeholders,
    fallback,
    useEnglish,
  }: {
    components: Record<Components[K], ComponentType>;
    fallback?: () => ReactElement;
    k: K;
    placeholders: Record<Placeholders[K], string | number>;
    useEnglish?: boolean;
  }): ReactElement => {
    return TrImpl(
      { components, fallback, k, placeholders, useEnglish } as any,
      overrideI18nextInstance,
      overrideCategoryNamesToIds,
    );
  };

type TrFactoryReturnVals<Components extends Record<string, any>, Placeholders extends Record<string, any>> = {
  Tr: <K extends Exclude<keyof Components, keyof Placeholders>>({
    k,
    components,
    fallback,
    useEnglish,
  }: {
    components: Record<Components[K], ComponentType>;
    fallback?: () => ReactElement;
    k: K;
    useEnglish?: boolean;
  }) => ReactElement;

  Trp: <K extends keyof Placeholders & keyof Components>({
    k,
    components,
    placeholders,
    fallback,
    useEnglish,
  }: {
    components: Record<Components[K], ComponentType>;
    fallback?: () => ReactElement;
    k: K;
    placeholders: Record<Placeholders[K], string | number>;
    useEnglish?: boolean;
  }) => ReactElement;
};

export const trFactory = <Components extends Record<string, any>, Placeholders extends Record<string, any>>(
  overrideI18nextInstance?: typeof i18next,
  overrideCategoryNamesToIds?: Record<string, number>,
): TrFactoryReturnVals<Components, Placeholders> => {
  return {
    Tr: TrFactory(overrideI18nextInstance, overrideCategoryNamesToIds) as any,
    Trp: TrWithPlaceholdersFactory(overrideI18nextInstance, overrideCategoryNamesToIds) as any,
  };
};
