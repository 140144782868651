import { fontFamilies, colors } from '@italist/component-library';
import styled from 'styled-components';
import { TableRowOrderSummaryBold, TableRowOrderSummaryNormal } from './TableRowOrderSummary';
import { useT, TranslationFunc } from '../../helper/i18n';
import { arabic14FontSizeCSSRule } from '../../helper/fonts';

/* Interfaces */
interface IOrderSummaryProps {
  caTax: string;
  currency: string;
  customs: string;
  isReturn?: boolean;
  isTaxUeZone: 'Y' | 'N';
  promotion: string;
  shipping: string;
  shippingTooltip?: string;
  subtotal: string;
  tax: string;
  taxRate: string;
  title?: string;
  total: string;
}

/* Styles */
const SummaryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media (max-width: 1023px) {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid ${colors.grayE5};
  }
`;

const Summary = styled.div`
  font-size: 16px;
  line-height: 30px;
  width: 345px;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 28px;
    width: 100%;
    html[dir='rtl'] & {
      ${arabic14FontSizeCSSRule}
    }
  }

  html[dir='rtl'] & {
    text-align: right;
  }
`;

const TableTitle = styled.div`
  font-family: ${fontFamilies.bold};
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }
`;

/* Helpers */
const displayPromotion = (currency: string, promotion: string, t: TranslationFunc) => {
  if (+promotion > 0) {
    return (
      <TableRowOrderSummaryNormal
        amount={promotion}
        credit
        currency={currency}
        name={t('ordersAndShipping::promotion')}
      />
    );
  }
  return null;
};

const displayCustoms = (
  currency: string,
  customs: string,
  isTaxUeZone: string,
  t: TranslationFunc,
  isReturn?: boolean,
) => {
  if (isTaxUeZone === 'N') {
    return (
      <TableRowOrderSummaryNormal
        amount={customs}
        credit={isReturn}
        currency={currency}
        name={isReturn ? t('returns::dutiesCourierFees') : t('ordersAndShipping::customDuties')}
      />
    );
  }
  return null;
};

const displayCaSaleTax = (currency: string, caTax: string, t: TranslationFunc) => {
  if (+caTax > 0) {
    return <TableRowOrderSummaryNormal amount={caTax} currency={currency} name={t('ordersAndShipping::salesTax')} />;
  }
  return null;
};

const displaySalesTax = (currency: string, tax: string, taxRate: string, isTaxUeZone: string, t: TranslationFunc) => {
  if (+tax > 0) {
    return (
      <TableRowOrderSummaryNormal
        amount={tax}
        currency={currency}
        name={isTaxUeZone === 'Y' ? t('ordersAndShipping::vat') : t('ordersAndShipping::salesTax')}
        rate={taxRate}
      />
    );
  }
  return null;
};

/* Component */
export const TableOrderSummary = (props: IOrderSummaryProps) => {
  const { t } = useT();
  const {
    caTax,
    currency,
    customs,
    isReturn,
    isTaxUeZone,
    promotion,
    shipping,
    shippingTooltip,
    subtotal,
    tax,
    taxRate,
    title,
    total,
  } = props;

  return (
    <SummaryWrapper>
      <Summary>
        <TableTitle>{title}</TableTitle>

        <TableRowOrderSummaryNormal amount={subtotal} currency={currency} name={t('ordersAndShipping::subtotal')} />
        {displayPromotion(currency, promotion, t)}
        {displayCustoms(currency, customs, isTaxUeZone, t, isReturn)}
        {displayCaSaleTax(currency, caTax, t)}
        {displaySalesTax(currency, tax, taxRate, isTaxUeZone, t)}
        <TableRowOrderSummaryNormal
          amount={shipping}
          credit={isReturn}
          currency={currency}
          name={isReturn ? t('returns::shipping') : t('common::shipping')}
          tooltip={shippingTooltip}
        />

        <TableRowOrderSummaryBold
          amount={total}
          currency={currency}
          name={isReturn ? t('returns::totalRefund') : t('ordersAndShipping::grandTotal')}
        />
      </Summary>
    </SummaryWrapper>
  );
};
