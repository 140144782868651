import { Component } from 'react';

/* Component */
export class GTMScript extends Component {
  public componentDidMount() {
    const initConsentScript = document.createElement('script');
    initConsentScript.type = 'text/javascript';
    initConsentScript.innerHTML = `(function(){
          window.dataLayer = window.dataLayer || [];
          function gtag() {
              dataLayer.push(arguments);
          }
          gtag("consent", "default", {
              ad_user_data: "denied",
              ad_personalization: "denied",
              ad_storage: "denied",
              analytics_storage: "denied",
              wait_for_update: 2000
          });
          gtag("set", "ads_data_redaction", true);})();`;
    document.head.appendChild(initConsentScript);

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = process.env.ENVIRONMENT ? `(async function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=OMugLOK4jOE0XKE7ykS_FA&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MT8FG3C');` :
      `(async function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=I6vEoKOM4HvivZgWFqVYTQ&gtm_preview=env-110&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MT8FG3C');
      `;
    document.head.appendChild(s);
  }

  public render() {
    return null;
  }
}
