import i18next, { TFunctionDetailedResult } from 'i18next';
import { useContext } from 'react';
import { tWrapperFactory } from './helpers/tWrapperFactory';
import { I18nTSContext } from './I18nTSContext';
import { TranslationFuncOptions } from './translationFuncs';

export const useT = <KeysWithNS extends Record<string, any>, Components, Placeholders>(
  overrideI18nextInstance?: typeof i18next,
  overrideCategoryNamesToIds?: Record<string, number>,
): {
  i18next: typeof i18next;
  t: (k: any, options?: TranslationFuncOptions | undefined) => any;
  tp: (k: any, placeholders: any, options?: TranslationFuncOptions | undefined) => any;
} => {
  const { i18nextInstance, categoryNamesToIds } = useContext(I18nTSContext);

  const usedInstance = overrideI18nextInstance || (i18nextInstance as typeof i18next);

  const { tImpl, tpImpl } = tWrapperFactory({
    categoryNamesToIds: overrideCategoryNamesToIds || categoryNamesToIds,
    i18nextInstance: usedInstance,
  });

  return { i18next: usedInstance, t: tImpl, tp: tpImpl };
};
