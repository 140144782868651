import { BareFooter, BareFooterI18nTranslations } from '@italist/component-library';
import { useT } from 'helper/i18n';
import { arabic12FontSize, arabic14FontSize } from '../../helper/fonts';
import { Layout } from '../layout/Layout';

export const Footer = ({ localized }) => {
  const { t } = useT();

  const i18nTranslations: BareFooterI18nTranslations = {
    italistCopyright: t('common::footer:italistCopyright'),
    promotionsPolicy: t('common::footer:promotionsPolicy'),
    promotionsPolicyUrl: localized('/customer_service/returns_and_refunds#promotions_policy/'),
    termsOfUse: t('common::footer:termsOfUse'),
    termsOfUseUrl: localized('/terms/'),
  };

  return (
    <Layout maxWidthDesktop="calc(100% - 100px)" minWidthDesktop="950px">
      <div style={{ paddingLeft: document.documentElement.lang === 'ar' ? 20 : undefined }}>
        <BareFooter
          i18nTranslations={i18nTranslations}
          showTopBorder={false}
          fontSize={{
            desktop: document.documentElement.lang === 'ar' ? arabic12FontSize : undefined,
            mobile: document.documentElement.lang === 'ar' ? arabic14FontSize : undefined,
          }}
        />
      </div>
    </Layout>
  );
};
