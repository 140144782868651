import { ATextTertiary, color, OModalInfo } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { clearTrackShipment } from '../../common/action/creator';
import { IAppStore, ITrackShipment } from '../../common/type';
import { ModalTrackShipmentRows } from './ModalTrackShipmentRows';
import { useT } from '../../helper/i18n';
import styled from 'styled-components';

/* Interfaces */
interface IProps {
  handleClose: () => void;
  trackShipmentData: ITrackShipment[];
  trackShipmentMessage: string;
}

/* Styles */
const Message = styled(ATextTertiary)`
  color: ${color.greyDark};
  margin: 30px 0;
`;

/* Helpers */
const mapStateToProps = ({ trackShipmentData, trackShipmentMessage }: IAppStore) => ({
  trackShipmentData,
  trackShipmentMessage,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleClose: () => dispatch(clearTrackShipment()),
});

/* Component */
const ModalTrackShipmentBase = ({ handleClose, trackShipmentData, trackShipmentMessage }: IProps) => {
  const { t } = useT();
  return (
    <OModalInfo
      handleClose={handleClose}
      title={t('returns::followOrder')}
      visible={!!trackShipmentData.length || !!trackShipmentMessage}
    >
      {trackShipmentMessage ? (
        <Message>{t(`returns_errors_${trackShipmentMessage}` as any, {})}</Message>
      ) : (
        <ModalTrackShipmentRows trackShipmentData={trackShipmentData} />
      )}
    </OModalInfo>
  );
};

export const ModalTrackShipment = withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalTrackShipmentBase));
