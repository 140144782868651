"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = exports.fontURLs = exports.fontFamilies = exports.SMALL_MOBILE_WIDTH = exports.DESKTOP_MIN_WIDTH = void 0;
exports.DESKTOP_MIN_WIDTH = 1024;
exports.SMALL_MOBILE_WIDTH = 320;
exports.fontFamilies = {
    bold: "'Maison Neue Demi', 'Arial Black', sans-serif",
    light: "'Maison Neue Light', 'Arial Light', sans-serif",
    regular: "'Maison Neue Book', 'Arial', sans-serif"
};
exports.fontURLs = {
    book: 'https://assets.italist.com/fonts/MaisonNeueAPP-Book.otf',
    demi: 'https://assets.italist.com/fonts/MaisonNeueAPP-Demi.otf'
};
exports.colors = {
    black: '#000000',
    black03: 'rgba(0, 0, 0, 0.3)',
    blue: '#7E9DEE',
    gray1A: '#1A1A1A',
    gray99: '#999999',
    grayAA: '#AAAAAA',
    grayBF: '#BFBFBF',
    grayC4: '#C4C4C4',
    grayE0: '#E0E0E0',
    grayE5: '#E5E5E5',
    grayEE: '#EEEEEE',
    green: '#16FF73',
    orange: '#FF6534',
    red: '#FF3434',
    redSecretRoom: '#F25C5E',
    redWarning: '#B94A48',
    taupe: '#F1E9E6',
    white: '#ffffff',
    white05: 'rgba(255, 255, 255, 0.5)'
};
