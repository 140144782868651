import { Reducer } from 'redux';
import { CLEAR, UPDATE } from '../action/type';

export const returnReasonsSelected: Reducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE.RETURN_REASONS_SELECTED:
      return action.value || [];
    case CLEAR.RETURN_REASONS_SELECTED:
      return [];
    default:
      return state;
  }
};
