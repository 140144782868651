import styled from 'styled-components';
import { ATextSecondary, ATextTertiary } from '../../atom';
import { border } from '../../theme/StylePalette';

/* Interfaces */
interface IAddress {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  postalCode: string;
  stateRegion: string;
}

interface IAddressProps {
  address: IAddress;
}

interface IVerifiedProps extends IAddressProps {
  heading: string;
}

/* Styles */
const PadLeft = styled.div`
  padding-left: 25px;

  html[dir='rtl'] & {
    padding-left: unset;
    padding-right: 25px;
  }
`;

const AddressContainer = styled.div`
  cursor: pointer;
  &:hover ${PadLeft} {
    text-decoration: underline;
  }
`;

const Unrecognized = styled.div`
  border: ${border.black};
  border-radius: ${border.radiusPrimary};
  padding: 20px;
`;

/* Components */
export const MModalAddress = ({ address }: IAddressProps) => {
  const { address1, address2, city, country, postalCode, stateRegion } = address;

  return (
    <>
      <ATextTertiary>{address1}</ATextTertiary>
      {address2 ? <ATextTertiary>{address2}</ATextTertiary> : null}
      <ATextTertiary>
        {city}, {stateRegion} {postalCode}
      </ATextTertiary>
      <ATextTertiary>{country}</ATextTertiary>
    </>
  );
};

export const MModalAddressVerified = ({ address, heading }: IVerifiedProps) => (
  <AddressContainer>
    <ATextSecondary uppercase>{heading}</ATextSecondary>
    <PadLeft>
      <MModalAddress address={address} />
    </PadLeft>
  </AddressContainer>
);

export const MModalAddressUnrecognized = ({ address }: IAddressProps) => (
  <Unrecognized>
    <MModalAddress address={address} />
  </Unrecognized>
);
