import React from 'react';
import { ATextTertiary } from '../text/AText';
import styled from 'styled-components';

/* Interfaces */
interface IAListBulletItem {
  children: React.ReactNode;
}

/* Styles */
export const AListOrdered = styled(ATextTertiary.withComponent('ol'))`
  list-style-type: decimal;
  margin-top: 10px;
`;

export const AListUnordered = styled(AListOrdered.withComponent('ul'))`
  list-style-type: disc;
`;

export const AListNumberedItem = styled(ATextTertiary.withComponent('li'))`
  margin-left: 25px;
  padding-left: 10px;

  html[dir='rtl'] & {
    margin-left: unset;
    margin-right: 25px;
    padding-left: unset;
    padding-right: 10px;
  }
`;

const Bullet = styled(ATextTertiary.withComponent('li'))`
  font-size: 160%;
  list-style-position: outside;
  margin-left: 28px;
  padding-left: 8px;

  html[dir='rtl'] & {
    margin-left: unset;
    margin-right: 28px;
    padding-left: unset;
    padding-right: 8px;
  }
`;

const BulletText = styled(ATextTertiary.withComponent('span'))`
  font-sizer: 62.5%;
`;

export const AListBulletItem = ({ children }: IAListBulletItem) => (
  <Bullet>
    <BulletText>{children}</BulletText>
  </Bullet>
);
