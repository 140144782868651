import { Component } from 'react';

/* Component */
export class Zendesk extends Component {
  public componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = `
      (function () { 
        function loadZdBot() {
          setTimeout(function() {
            var s = document.createElement('script');
            s.id = 'ze-snippet'; 
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://static.zdassets.com/ekr/snippet.js?key=19721739-8313-4209-bd92-fb082eac2701';
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          });
        }
        loadZdBot();
    })();
      `;
    document.body.appendChild(s);
  }

  public render() {
    return null;
  }
}
