import { ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { setReturnType } from '../../common/action/creator';
import { IAppStore, ReturnType } from '../../common/type';
import { useT } from '../../helper/i18n';
import { Column } from '../layout/Column';
import { ReturnRadioButton } from '../button/RadioButton';
import { ReturnSelectionPickup } from './ReturnPickup';
import { ReturnSelectionDropOff } from './ReturnDropOff';
import { ReturnSepartorMobile } from './ReturnSeparationBar';

interface IProps {
  handleReturnShippingType: (e: React.FormEvent<HTMLInputElement>) => void;
  selection: string;
}

const mapStateToProps = ({ returnShippingType }: IAppStore) => ({
  selection: returnShippingType,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleReturnShippingType: (e: React.FormEvent<HTMLInputElement>) => {
    const returnType = e.currentTarget.value.toString();
    if (returnType === ReturnType.DROP_OFF || returnType === ReturnType.PICK_UP) dispatch(setReturnType(returnType));
  },
});

const ReturnShippingMobileBase = ({ selection, handleReturnShippingType }: IProps) => {
  const { t } = useT();
  return (
    <Column>
      {selection === ReturnType.PICK_UP && (
        <>
          <ReturnRadioButton
            checked
            label={t('returns::book:selectOrderPickup')}
            onChange={handleReturnShippingType}
            value={ReturnType.PICK_UP}
            labelId={ReturnType.PICK_UP}
            fontSize={'14px'}
          />
          <ReturnSelectionPickup />
        </>
      )}
      {selection === ReturnType.DROP_OFF && (
        <>
          <ReturnRadioButton
            checked
            label={t('returns::book:selectBringPackage')}
            onChange={handleReturnShippingType}
            value={ReturnType.DROP_OFF}
            labelId={ReturnType.DROP_OFF}
            fontSize={'14px'}
          />
          <ReturnSelectionDropOff />
        </>
      )}
      {selection === '' && (
        <>
          <ReturnRadioButton
            checked={false}
            label={t('returns::book:selectBringPackage')}
            onChange={handleReturnShippingType}
            value={ReturnType.DROP_OFF}
            labelId={ReturnType.DROP_OFF}
            fontSize={'14px'}
          />
        </>
      )}
      <ALayoutSpacer desktopHeight={'60px'} mobileHeight={'30px'} />
      <ReturnSepartorMobile />
      <ALayoutSpacer desktopHeight={'60px'} mobileHeight={'30px'} />
      {selection === ReturnType.PICK_UP && (
        <>
          <ReturnRadioButton
            checked={false}
            label={t('returns::book:selectBringPackage')}
            onChange={handleReturnShippingType}
            value={ReturnType.DROP_OFF}
            labelId={ReturnType.DROP_OFF}
            fontSize={'14px'}
          />
        </>
      )}
      {selection === ReturnType.DROP_OFF && (
        <>
          <ReturnRadioButton
            checked={false}
            label={t('returns::book:selectOrderPickup')}
            onChange={handleReturnShippingType}
            value={ReturnType.PICK_UP}
            labelId={ReturnType.PICK_UP}
            fontSize={'14px'}
          />
        </>
      )}
      {selection === '' && (
        <>
          <ReturnRadioButton
            checked={false}
            label={t('returns::book:selectOrderPickup')}
            onChange={handleReturnShippingType}
            value={ReturnType.PICK_UP}
            labelId={ReturnType.PICK_UP}
            fontSize={'14px'}
          />
        </>
      )}
    </Column>
  );
};

export const ReturnShippingMobile = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReturnShippingMobileBase));
