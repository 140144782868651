import { fontFamilies } from '@italist/component-library';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { ABarPrimary, ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {CLOUDINARY_CDN, getTotalPages, translateShipping} from 'helper/helpers';
import { Layout } from 'component/layout/Layout';
import { fetchOrders } from '../../common/action/creator';
import { IAppStore, IOrder, IPagination } from '../../common/type';
import { track, TrackingEvents } from '../../helper/tracking';
import { Pagination } from '../pagination/Pagination';
import { TableOrderListHeader } from './TableOrderListHeader';
import { TableOrderListRow } from './TableOrderListRow';
import { TranslationFunc } from '../../helper/i18n';
import { arabic14FontSizeCSSRule } from '../../helper/fonts';

/* Interfaces */
interface IProps {
  email: string;
  handleFetchOrders: (offset: number) => void;
  history: any;
  orders: IOrder[];
  pagination: IPagination;
  t: TranslationFunc;
}

/* Styles */
const Footnote = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //styleName: Desktop-B1-16px;
  font-family: ${fontFamilies.regular};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;

  @media (max-width: 1023px) {
    font-size: 14px;

    html[dir='rtl'] & {
      ${arabic14FontSizeCSSRule}
    }
  }
  @media (min-width: 1024px) {
    padding: 15px;
  }
`;

const Check = styled.img`
  height: 26px;
  margin-right: 12px;
  width: 26px;

  html[dir='rtl'] & {
    margin-left: 12px;
    margin-right: unset;
  }

  @media (min-width: 1024px) {
    margin-right: 22px;

    html[dir='rtl'] & {
      margin-left: 22px;
      margin-right: unset;
    }
  }
`;

function trackOrderSelected(order: IOrder, email: string) {
  return track(
    TrackingEvents.orderSelected,
    {
      order: {
        grand_total: order.price,
        order_date: order.orderDate,
        order_number: order.orderNumber,
        order_status: order.orderStatus.status,
        quantity: order.quantity,
        shipping_status: translateShipping(order.orderStatus.order_status_id),
      },
    },
    email,
  );
}

/* Helpers */
const isNotLastOrderRow = (orders, rows) => {
  return orders.length > 1 && rows.length < orders.length * 2 - 2;
};

const isPaginated = (total) => {
  return getTotalPages(total) > 1;
};

const generateRows = (orders: IOrder[], _history: any, email: string, total: number) =>
  orders.reduce((rows: React.ReactNode[], row: IOrder) => {
    rows.push(
      <TableOrderListRow
        currency={row.currency}
        imgSrc={row.imgSrc}
        key={row.orderNumber}
        orderId={row.orderId}
        orderDate={row.orderDate}
        orderNumber={row.orderNumber}
        orderStatus={row.orderStatus}
        productVersionOptionIds={row.productVersionOptionIds}
        price={row.price}
        quantity={row.quantity}
        storeOrderIds={row.storeOrderIds}
        storeOrderNumbers={row.storeOrderNumbers}
        trackOrderSelected={() => trackOrderSelected(row, email)}
      />,
    );

    if (isNotLastOrderRow(orders, rows) || isPaginated(total)) {
      rows.push(<ABarPrimary key={`bar-${row.orderNumber}`} />);
    }

    return rows;
  }, []);

const mapStateToProps = ({ email, orders, pagination }: IAppStore) => ({
  email,
  orders,
  pagination,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleFetchOrders: (offset: number) => {
    dispatch(fetchOrders(offset));
    window.history.pushState(
      { path: `${window.location.href.split('?')[0]}?offset=${offset}` },
      '',
      `${window.location.href.split('?')[0]}?offset=${offset}`,
    );
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  },
});

/* Component */
class TableOrderListBase extends React.Component<IProps> {
  public componentDidUpdate() {
    const { orders, email } = this.props;
    if (orders && email) {
      const orderIds = orders.map((order) => ({ order_id: order.orderId }));
      track(TrackingEvents.ordersAndReturnsViewed, { my_account: { orders: orderIds } }, email);
    }
  }

  public render() {
    const { email, orders, history, pagination, t } = this.props;
    const { total } = pagination;
    return (
      <>
        <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
          <MediaQuery maxWidth={1023}>
            <ALayoutSpacer mobileHeight={'40px'} />
            {!isEmpty(orders) && (
              <Footnote>
                <Check src={`${CLOUDINARY_CDN}/icons/check_with_circle`} alt="check-icon" />
                {t('ordersAndShipping::clickStartReturn')}
              </Footnote>
            )}
          </MediaQuery>
          <MediaQuery minWidth={1024}>
            <TableOrderListHeader />
          </MediaQuery>

          {orders ? generateRows(orders, history, email, total) : null}

          <MediaQuery minWidth={1024}>
            <ALayoutSpacer desktopHeight={'40px'} />
            <Footnote>
              {!isEmpty(orders) && (
                <>
                  <Check src={`${CLOUDINARY_CDN}/icons/check_with_circle_3x`} alt="check-icon" />
                  {t('ordersAndShipping::clickStartReturn')}
                </>
              )}
              {!total && <ALayoutSpacer desktopHeight={'40px'} />}
            </Footnote>
          </MediaQuery>
          {total && <Pagination totalOrders={total} handleFetchOrders={this.props.handleFetchOrders} />}
        </Layout>
      </>
    );
  }
}

export const TableOrderList = withRouter(connect(mapStateToProps, mapDispatchToProps)(TableOrderListBase));
