import { colors, fontFamilies } from '@italist/component-library';
import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { useT } from '../../helper/i18n';
import { Row } from '../layout/Row';
import { arabic12FontSizeCSSRule } from '../../helper/fonts';

/* Interfaces */
interface IProps {
  activeStep: number;
  paperlessReturnFlag?: boolean;
}

interface IStep {
  href?: string;
  name: string;
}

const StepTitle = styled.span<{ active?: boolean }>`
  font-family: ${fontFamilies.regular};
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 16px;
    font-weight: 600;
    color: ${({ active }) => (active ? colors.black : colors.grayE5)};
  }

  @media (min-width: 1024px) {
    margin-bottom: 20px;
    position: absolute;
    top: -40px;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  html[dir='rtl'] & {
    ${arabic12FontSizeCSSRule}
  }
`;

const StepContainer = styled.ul`
  display: flex;
  flex: 1;
  counter-reset: step;
`;

const Step = styled.li<{ active: boolean }>`
  list-style: none;
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: default;

  &:before {
    content: ' ';
    counter-increment: step;
    width: 10px;
    height: 10px;
    line-height: 10px;
    border: 1px solid ${({ active }) => (active ? colors.black : colors.grayE5)};
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: ${({ active }) => (active ? colors.black : colors.grayE5)};
  }

  &:first-child:after {
    content: none;
  }
`;

const Bar = styled.div<{ active: boolean }>`
  border-bottom: 2px solid ${({ active }) => (active ? colors.black : colors.grayE5)};
  width: 80%;
  height: 2px;
  margin: 3px 25px 0 25px;
`;

/* Component */
export const ReturnProgressBar = ({ activeStep }: IProps) => {
  const { t } = useT();

  const steps = React.useMemo(
    () => [
      { name: t('returns::progress:select') },
      { name: t('returns::progress:book') },
      { name: t('returns::progress:print') },
      { name: t('returns::progress:pack') },
  ],
    [],
  );

  return (
    <>
      <MediaQuery minWidth={1024}>
        <Row>
          <StepContainer>
            {steps.map((s: IStep, index) => (
              <>
                <Step active={index <= activeStep}>
                  <StepTitle>{s.name}</StepTitle>
                </Step>
                {index < steps.length - 1 && <Bar active={index < activeStep} />}
              </>
            ))}
          </StepContainer>
        </Row>
      </MediaQuery>
      <MediaQuery maxWidth={1023}>
        <Row justifyContent="space-between">
          {steps.map((s: IStep, index) => (
            <StepTitle style={{ display: 'flex', justifyContent: 'center' }} active={index === activeStep}>
              {s.name}
            </StepTitle>
          ))}
        </Row>
      </MediaQuery>
    </>
  );
};
