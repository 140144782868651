(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["React"], factory);
	else if(typeof exports === 'object')
		exports["@italist/i18n-ts"] = factory(require("react"));
	else
		root["@italist/i18n-ts"] = factory(root["React"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__639__) => {
return 