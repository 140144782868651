import styled from 'styled-components';
import { ALayoutSpacer, AListBulletItem, AListUnordered, ATextTertiary, ATitleTertiary } from '../../atom';
import { TextLink } from '../../../component/link/Link';

import { color } from '../../theme/StylePalette';
import { OModalInfo } from './OModalInfo';

/* Interfaces */
interface IOModalReturnsPolicy {
  basePathWeb: string;
  handleClose: () => void;
  visible: boolean;
}

/* Styles */
const Paragraph = styled(ATextTertiary)`
  margin-top: 10px;
`;

const Bold = styled.span`
  font-family: Avenir Black;
`;

/* Component */
export const OModalReturnsPolicy = ({ handleClose, visible }: IOModalReturnsPolicy) => (
  <OModalInfo handleClose={handleClose} title={'Returns and Refunds'} visible={visible}>
    <ATitleTertiary>RETURNS POLICY</ATitleTertiary>
    <Paragraph>
      <Bold>
        If you’re not satisfied with your purchase for any reason, you can always return it. Returns that do not comply
        with our policy will not be accepted. Please be sure to read our Returns Policy thoroughly before submitting
        your return.
      </Bold>
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      Remember, you’re importing luxury goods directly from Italy, so your returns are processed through Italian
      customs. This process typically requires additional shipping costs, customs duties, and/or courier charges which
      is why we don’t offer free returns at this time.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      <Bold>You will be responsible for the total cost of shipping costs, customs duties, and/or courier charges.</Bold>
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>All returns must meet the following requirements for processing:</Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      You are required to use italist’s courier, which is DHL in most parts of the world, to return your item.
      Additionally,{' '}
      <Bold>your return must be associated with the specific waybill and proforma invoice provided by DHL</Bold> (please
      see instructions about the return process{' '}
      <a style={{ color: color.black, textDecoration: 'underline' }} href={``}>
        here
      </a>
      ), otherwise it will not be accepted.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>All returns must be shipped to italist within 14 days of the package delivery date.</Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      Returns must be shipped from the same country as the original shipping address. You may not ship the order to one
      country, and then return it from an address outside that country.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      If you refuse delivery for any reason, then you are responsible for the contents of that order and will not be
      eligible for a full refund refund by italist.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      Items from different luxury boutiques should not be returned together. Please return each item individually using
      separate shipping boxes.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      The original brand box or shoebox is considered part of the product and must be placed in another shipping box to
      avoid damage.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      Products must be returned with their original tag(s) and label(s) still attached. If any tag(s) or label(s) are
      removed from the item, then the return will not be accepted.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>Items must be returned unworn, unwashed, unironed, unused, and undamaged.</Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      All returns must include the dust bag. brand box, shoebox, and all original packaging as these items are
      considered part of the product.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>We do not accept returns for underwear, swimwear, fur coats, down jackets, or jewelry.</Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      If under exceptional circumstances we accept a return for a down jacket or fur coat, please be aware there will be
      higher than normal return and restocking charges and fees.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>Final sale items cannot be returned nor exchanged.</Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      Please note that all shoes should be tried on inside, on a carpeted surface; be mindful not to scratch the soles
      or damage the shoebox (which is considered part of the product). Worn shoes will not be eligible for return.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      Please handle light-colored bags carefully. Dark clothing can transfer dye to light-colored bags. Bags that are
      scratched, worn or smeared will not be eligible for return.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      Please wear undergarments when trying on lingerie. And please note that hosiery must be returned unopened in its
      original packaging.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      Colors can appear as a slightly different shade on your display depending on your computer monitor, mobile device,
      or tablet. We always make our best effort to display colors as accurately as possible, but we cannot guarantee
      colors.
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      We monitor the number of returns made by each customer. Our team may flag any return and refuse it at our
      discretion. Excessive returns may lead to the closure of your italist account.
    </Paragraph>
    <ALayoutSpacer height={'20px'} />

    <ATitleTertiary>REQUESTING A RETURN:</ATitleTertiary>
    <AListUnordered>
      <AListBulletItem>
        Log into your account on italist and to go to "Orders & Returns" under the "My Account" tab.
      </AListBulletItem>
      <AListBulletItem>
        Click on the order number and choose the item you wish to return by clicking "Request Return."
      </AListBulletItem>
      <AListBulletItem>Select – select the reason for your return.</AListBulletItem>
      <AListBulletItem>
        Book – choose a date and time (within three business days) to schedule a pickup by the courier.
      </AListBulletItem>
      <AListBulletItem>
        Print – download and print the waybill and proforma invoice and insert them into the plastic folder provided by
        DHL, then affix the slip with the return information to the original shipping box, or a similar one.
      </AListBulletItem>
      <AListBulletItem>
        Pack & Ship – prepare carefully the return package for pickup. Insert the item with their identifying tag and
        original label(s) still attached. Dust bag and brand box (if received) are considered part of the product. The
        brand box (e.g., a shoebox) must be placed inside another shipping box to prevent damage.
      </AListBulletItem>
      <AListBulletItem>
        NOTE: Please return each item individually using separate shipping boxes. If you have any further questions or
        if you believe you have received a defective product or an incorrect item, then please send an email with a
        brief description of the issue and photographs to our customer service team at{' '}
        <TextLink href="mailto:customercare@italist.com">customercare@italist.com</TextLink>
      </AListBulletItem>
    </AListUnordered>
    <ALayoutSpacer height={'20px'} />

    <ATitleTertiary>EXCHANGES</ATitleTertiary>
    <Paragraph>
      Exchanges are treated like returns. If you would like to make an exchange, then you will need to return the
      original item first. Since we source our products from hundreds of partner boutiques across Italy, you will need
      to place another order for the new item you wish to purchase.
    </Paragraph>
    <ALayoutSpacer height={'20px'} />

    <ATitleTertiary>REFUNDS</ATitleTertiary>
    <Paragraph>
      Once your returned item is received, and we verify that it complies with our returns policy, we will submit a
      refund request through your original payment method.
      <Bold>
        Please note that refunds may take several days to process depending on your bank or credit card provider.
      </Bold>
    </Paragraph>
    <ALayoutSpacer height={'8px'} />
    <Paragraph>
      Once the return is processed and verified, you will receive a refund for the price of the order, excluding the
      total of shipping costs, customs duties, and/or courier charges incurred. Please note, customs duties are
      nonrefundable through italist. You may be able to recover customs duties by contacting the local customs bureau
      directly. This option may not be applicable in all countries, so we recommend that you hire a customs broker to
      determine whether you can apply for a refund for customs fees related to your return.
    </Paragraph>
    <ALayoutSpacer height={'20px'} />

    <ATitleTertiary>PREVENT RETURNS ABUSE</ATitleTertiary>
    <Paragraph>
      If you abuse italist’s return policy, you will be banned from future use of our shopping platform. italist takes
      returns very seriously and it uses a number of advanced software solutions such as Riskified, Adyen, VISA and
      Master Card Secured to manage its returns. Such solutions can detect fraudulent returns and ban shoppers from
      future online purchases on italist.com and other platforms.
    </Paragraph>
  </OModalInfo>
);
