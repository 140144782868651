import React from 'react';
import styled from 'styled-components';
import { border, color, fontFamily, fontSize, fontWeight } from '../../theme/StylePalette';

/* Interfaces */
interface IStyled {
  disabled?: boolean;
  error?: boolean;
}

interface IOptionProps {
  label: string;
  value: string;
}

export interface IADropdownProps {
  disabled?: boolean;
  error?: boolean;
  handler: (e: React.FormEvent<HTMLSelectElement>) => void;
  options: Record<string, any>[];
  placeholder?: string;
  value: string;
}

/* Styles */
const PrimarySelect = styled.select`
  appearance: none;
  background-color: ${({ disabled }: IStyled) => (disabled ? color.greyLight : color.white)};
  background-image: url(https://d3nn9jcidi1lkh.cloudfront.net/mkt/icon/down_arrow_grey.png);
  background-position: 97% center;
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: ${border.radiusPrimary};
  border: ${({ error }: IStyled) => (error ? border.redDark : border.greyDark)};
  box-sizing: border-box;
  color: ${color.black};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.secondary};
  font-weight: ${fontWeight.secondary};
  height: 44px;
  margin: ${({ error }: IStyled) => (error ? '10px 0 1px' : '10px 0 15px')};
  padding: 12px 18px 8px 10px;
  width: 100%;

  html[dir='rtl'] & {
    padding: 12px 10px 8px 18px;
  }
`;

/* Helpers */
const generateOptions = (options: Record<string, any>[], placeholder?: string) => {
  if (placeholder) {
    // eslint-disable-next-line no-param-reassign
    options = [{ label: placeholder, value: placeholder }, ...options];
  }

  return options.map(({ label, value }: IOptionProps, index: number) => (
    <option key={`selector-option-${value}-${index}}`} value={value}>
      {label}
    </option>
  ));
};

/* Component */
export const ADropdownPrimary = (props: IADropdownProps) => {
  const { disabled, error, handler, options, placeholder, value } = props;

  return (
    <PrimarySelect disabled={disabled} error={error} onChange={handler} value={value}>
      {generateOptions(options, placeholder)}
    </PrimarySelect>
  );
};
