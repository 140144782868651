/* eslint-disable no-param-reassign */
import styled from 'styled-components';

/* Interfaces */
export interface IASocialSecondaryFacebookProps {
  url: string;
}

export interface IASocialSecondaryPinterestProps {
  description: string;
  media: string;
  url: string;
}

export interface IASocialSecondaryTwitterProps {
  text: string;
  url: string;
  via: string;
}

/* Styles */
const SocialIcon = styled.img`
  height: 25px;
`;

/* Helpers */
const generateFacebookShareUrl = (url: string) => {
  url = encodeURIComponent(url);
  return `http://www.facebook.com/sharer.php?u=${url}`;
};

const generatePinterestShareUrl = (url: string, media: string, description: string) => {
  url = encodeURIComponent(url);
  media = encodeURIComponent(media);
  description = encodeURIComponent(description);
  return `http://pinterest.com/pin/create/button/?url=${url}&media=${media}&description=${description}`;
};

const generateTwitterShareUrl = (url: string, text: string, via: string) => {
  url = encodeURIComponent(url);
  text = encodeURIComponent(text);
  via = encodeURIComponent(via);
  return `https://twitter.com/share?url=${url}&text=${text}&via=${via}`;
};

/* Components */
export const ASocialSecondaryFacebook = ({ url }: IASocialSecondaryFacebookProps) => (
  <a href={generateFacebookShareUrl(url)}>
    <SocialIcon src="https://www.italist.com/images/mkt/icon/icon_black_fb.png" />
  </a>
);

export const ASocialSecondaryPinterest = ({ url, media, description }: IASocialSecondaryPinterestProps) => (
  <a href={generatePinterestShareUrl(url, media, description)}>
    <SocialIcon src="https://www.italist.com/images/mkt/icon/icon_black_pint.png" />
  </a>
);

export const ASocialSecondaryTwitter = ({ url, text, via }: IASocialSecondaryTwitterProps) => (
  <a href={generateTwitterShareUrl(url, text, via)}>
    <SocialIcon src="https://www.italist.com/images/mkt/icon/icon_black_twitter.png" />
  </a>
);
