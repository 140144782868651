import { fontFamilies } from '@italist/component-library';
import styled from 'styled-components';

export const PageTitle = styled.span`
  font-family: ${fontFamilies.regular};
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: -0.02em;
  text-align: left;

  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 29px;
  }

  html[dir='rtl'] & {
    text-align: right;
  }
`;
