import { createContext } from 'react';
import i18next from 'i18next';

export type I18nTSContextType = {
  categoryNamesToIds?: Record<string, number>;
  i18nextInstance?: typeof i18next;
};

export const I18nTSContext = createContext<I18nTSContextType>({
  i18nextInstance: undefined,
});
