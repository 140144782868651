import i18next, { TFunctionDetailedResult } from 'i18next';
import { TranslationFuncOptions } from '../translationFuncs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replaceCategorySquareBrackets = ({
  key,
  categoryNamesToIds,
}: {
  categoryNamesToIds: Record<string, number>;
  key: string;
}) => {
  if (!categoryNamesToIds) {
    return key;
  }

  const matches = key.match(/^category::[^:]+:\[([^\]]+)\]$/);
  if (matches && matches[1]) {
    const categoryName = matches[1];
    const categoryId = categoryNamesToIds[categoryName?.toLowerCase()];

    if (categoryId === undefined) {
      console.error(`couldn't resolve translation key`, { categoryName, categoryNamesToIds, key });
      return key;
    }
    return key.replace(`[${categoryName}]`, categoryId.toString());
  }

  return key;
};

export const tWrapperFactory = ({
  i18nextInstance,
  categoryNamesToIds,
}: {
  categoryNamesToIds?: Record<string, number>;
  i18nextInstance: typeof i18next;
}) => {
  const tImpl = (k: any, options?: TranslationFuncOptions) => {
    const useEnglish = options?.useEnglish;
    const fallback = options?.fallback;
    return tImplCore({ categoryNamesToIds, fallback, i18nextInstance, k, useEnglish });
  };

  const tpImpl = (k: any, placeholders: any, options?: TranslationFuncOptions) => {
    const useEnglish = options?.useEnglish;
    const fallback = options?.fallback;

    return tImplCore({ categoryNamesToIds, fallback, i18nextInstance, k, placeholders, useEnglish });
  };

  return { tImpl, tpImpl };
};

const tImplCore = ({
  fallback,
  categoryNamesToIds,
  i18nextInstance,
  placeholders,
  useEnglish,
  k,
}: {
  categoryNamesToIds?: Record<string, number>;
  fallback?: () => string;
  i18nextInstance: typeof i18next;
  k: string;
  placeholders?: any;
  useEnglish?: boolean;
}) => {
  if (fallback && !i18nextInstance.exists(k)) {
    return fallback();
  }

  let lng;
  if (useEnglish) {
    lng = 'en';
  }

  let key: string | string[] = k;
  if (categoryNamesToIds) {
    key = replaceCategorySquareBrackets({
      categoryNamesToIds,
      key: k,
    });
  }

  if (fallback !== undefined) {
    key = [key, ''];
  }

  let result: string | TFunctionDetailedResult<string, any>;
  if (!placeholders) {
    result = i18nextInstance.t(key, { lng });
  } else {
    // eslint-disable-next-line no-param-reassign
    placeholders.lng = lng;
    result = i18nextInstance.t(key, placeholders);
  }

  if (!result) {
    if (fallback) {
      return fallback();
    }
  }

  return result;
};
