import React from 'react';
import styled from 'styled-components';
import { CLOUDINARY_CDN } from 'helper/helpers';
import { border, color, fontFamily, fontSize, fontWeight, lineHeight } from '../../theme/StylePalette';
import { AAnimationFadeInPrimary } from '../animation/AFade';

/* Interfaces */
type TOrientation = 'left' | 'right';

interface IAInfoTooltipProps {
  orientation?: TOrientation;
  text: string;
}

interface IAInfoTooltipState {
  readonly visible: boolean;
}

interface IStyle extends IAInfoTooltipState {
  orientation: TOrientation;
}

const toggleOrientation = (orientation: TOrientation) => (orientation === 'left' ? 'right' : 'left');

/* Styles */
const Tooltip = styled.div`
  ${({ visible }: IStyle) => visible && `animation: ${AAnimationFadeInPrimary.getName()} 0.25s ease-in-out 1;`};
  background-color: ${color.white};
  border-radius: ${border.radiusPrimary};
  border: ${border.greyDark};
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  color: ${color.greyDark};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.tertiary};
  font-weight: ${fontWeight.secondary};
  line-height: ${lineHeight.tertiary};
  padding: 5px;
  position: absolute;
  top: 15px;
  transform: translate(${({ orientation = 'right' }: IStyle) => (orientation === 'left' ? '-111' : '32')}%, -50%);
  visibility: ${({ visible }: IStyle) => (visible ? 'visible' : 'hidden')};
  width: 110px;
  z-index: 1;

  &:after,
  &:before {
    border-bottom: 7px solid transparent;
    border-${({ orientation = 'right' }: IStyle) => orientation}: 12px solid ${color.greyDark};
    border-top: 7px solid transparent;
    content: '';
    display: inline-block;
    left: ${({ orientation = 'right' }: IStyle) => (orientation === 'left' ? '120' : '-12')}px;
    position: absolute;
    top: calc(50% - 7px);
  }

  &:after {
    border-color: transparent ${({ orientation = 'right' }: IStyle) =>
      orientation === 'left' ? `transparent transparent ${color.white}` : `${color.white} transparent transparent`};
    margin-left: ${({ orientation = 'right' }: IStyle) => (orientation === 'left' ? '-' : '')}2px;
  }

  html[dir='rtl'] & {
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.12);
    transform: translate(${({ orientation = 'right' }: IStyle) => (orientation === 'left' ? '111' : '-32')}%, -50%);

    &:after,
    &:before {
      border-${({ orientation = 'right' }: IStyle) => orientation}: unset;
      border-${({ orientation = 'right' }: IStyle) => toggleOrientation(orientation)}: 12px solid ${color.greyDark};
      left: unset;
      right: ${({ orientation = 'right' }: IStyle) => (orientation === 'left' ? '120' : '-12')}px;
    }

    &:after {
      border-color: transparent ${({ orientation = 'right' }: IStyle) =>
        orientation === 'left' ? `${color.white} transparent transparent` : `transparent transparent ${color.white}`};
      margin-left: unset;
      margin-right: ${({ orientation = 'right' }: IStyle) => (orientation === 'left' ? '-' : '')}2px;
    }
  }
`;

const Info = styled.div<{ visible?: boolean }>`
  background-image: url(${CLOUDINARY_CDN}/icons/info.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  margin-left: 5px;
  position: relative;
  vertical-align: middle;
  width: 30px;
`;

/* Component */
export class AInfoTooltip extends React.Component<IAInfoTooltipProps, IAInfoTooltipState> {
  // eslint-disable-next-line react/state-in-constructor
  state: IAInfoTooltipState = { visible: false };

  private handleVisibleHide = () => this.setState(() => ({ visible: false }));

  private handleVisibleShow = () => this.setState(() => ({ visible: true }));

  private handleVisibleToggle = () => this.setState(({ visible }) => ({ visible: !visible }));

  public render() {
    const { orientation = 'right', text } = this.props;
    const { visible } = this.state;

    return (
      <Info
        onMouseEnter={this.handleVisibleShow}
        onMouseLeave={this.handleVisibleHide}
        onTouchCancel={this.handleVisibleHide}
        onTouchEnd={this.handleVisibleToggle}
        visible={visible}
      >
        <Tooltip orientation={orientation} visible={visible}>
          {text}
        </Tooltip>
      </Info>
    );
  }
}
