import axios from 'axios';
import { UAParser } from 'ua-parser-js';
import { apiPath } from '../common/path';
import {
  IMyAccountTracking,
  IOrderTracking,
  IProductTracking,
  IReturnOrderTracking,
  IUiTracking,
  IUserTracking,
} from '../common/type';

export const TrackingEvents = {
  confirmProductViewed: 'Confirm Product Viewed',
  invoiceDownloaded: 'Invoice Downloaded',
  orderPageViewed: 'Order Page Viewed',
  orderSelected: 'Order Selected',
  ordersAndReturnsViewed: 'Orders and Returns Viewed',
  passwordInfoViewed: 'Password Info Viewed',
  profileEdited: 'Profile Edited',
  profileViewed: 'Profile Viewed',
  proformaDownloaded: ' Proforma Downloaded',
  proformaInfoViewed: 'Proforma Info Viewed',
  reasonSelected: 'Reason Selected',
  returnContinued: 'Return Continued',
  returnEnded: 'Return Ended',
  returnFinished: 'Return Finished',
  returnStarted: 'Return Started',
  waybillDownloaded: 'Waybill Downloaded',
  waybillInfoViewed: 'Waybill Info Viewed',
};

export const trackPeople = (
  people_prop_method: string,
  property_to_update: string,
  property_string_value: string,
  email: string,
) => {
  track(
    '',
    {
      user: {
        people_prop_method,
        property_string_value,
        property_to_update,
      },
    },
    email,
  );
};

const searchEngine = (referrer: string) => {
  if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
    return 'google';
  }
  if (referrer.search('https?://(.*)bing.com') === 0) {
    return 'bing';
  }
  if (referrer.search('https?://(.*)yahoo.com') === 0) {
    return 'yahoo';
  }
  if (referrer.search('https?://(.*)duckduckgo.com') === 0) {
    return 'duckduckgo';
  }
  return null;
};

export const track = (
  event?: string,
  {
    my_account,
    order,
    product,
    return_order,
    ui,
    user,
  }: {
    my_account?: IMyAccountTracking;
    order?: IOrderTracking;
    product?: IProductTracking;
    return_order?: IReturnOrderTracking;
    ui?: IUiTracking;
    user?: IUserTracking;
  } = {},
  email?: string,
) => {
  setTimeout(() => {
    const { search } = window.location;
    const urlParams = new URLSearchParams(search);
    const referrer = document.referrer || '';
    const parsedUa = new UAParser(window.navigator.userAgent);

    const userWithEmail: IUserTracking & { email?: string } = { ...user };
    if (!userWithEmail.email) {
      userWithEmail.email = email;
    }

    const commons = {
      browser: parsedUa.getBrowser().name,
      browser_version: parsedUa.getBrowser().major,
      current_url: window.location.href,
      device: parsedUa.getDevice().model,
      os: `${parsedUa.getOS().name} ${parsedUa.getOS().version}`,
      referrer,
      referring_domain: referrer ? new URL(referrer).hostname : '',
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      search_engine: searchEngine(referrer),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_content: urlParams.get('utm_content'),
      utm_medium: urlParams.get('utm_medium'),
      utm_source: urlParams.get('utm_source'),
    };
    axios.post(apiPath.tracking(), {
      commons,
      event,
      my_account,
      order,
      product,
      return_order,
      ui,
      user: userWithEmail,
    });
  }, 0);
};
