import React, { ComponentType } from 'react';
import i18next from 'i18next';
import { useT } from './useT';

export const withT =
  <
    P extends {
      t: ReturnType<typeof useT>['t'];
      tp: ReturnType<typeof useT>['tp'];
    },
  >(
    C: ComponentType<P>,
  ) =>
  ({
    overrideI18nextInstance,
    overrideCategoryNamesToIds,
    ...props
  }: Omit<P, 't' | 'tp'> & {
    overrideCategoryNamesToIds?: Record<string, number>;
    overrideI18nextInstance?: typeof i18next;
  }) => {
    const { t } = useT(overrideI18nextInstance, overrideCategoryNamesToIds);
    const newProps = { ...props, t };
    return <C {...(newProps as P)} />;
  };
