import { colors, fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { fontSize, fontWeight, lineHeight } from '../../theme/StylePalette';

/* Interfaces */
export interface IATextProps {
  textColor?: string;
  uppercase?: boolean;
}

/* Components */
export const ATextPrimary = styled.p`
  color: ${({ textColor }: IATextProps) => textColor || colors.black};
  font-family: ${fontFamilies.regular};
  font-size: ${fontSize.primary};
  font-weight: ${fontWeight.secondary};
  line-height: ${lineHeight.primary};
  ${({ uppercase }: IATextProps) => (uppercase ? 'text-transform: uppercase;' : null)};
`;

export const ATextSecondary = styled(ATextPrimary)`
  font-size: ${fontSize.secondary};
  line-height: ${lineHeight.secondary};
`;

export const ATextTertiary = styled(ATextPrimary)`
  font-size: ${fontSize.tertiary};
  line-height: ${lineHeight.tertiary};
`;

export const ATextError = styled(ATextPrimary)`
  color: ${colors.red};
  font-size: ${fontSize.error};
  line-height: ${lineHeight.error};
`;

export const ATextFinePrint = styled(ATextTertiary)`
  color: ${colors.grayAA};
  margin: 10px 0;
`;
