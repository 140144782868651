import styled from 'styled-components';
import { Button, colors, fontFamilies } from '@italist/component-library';
import { ALayoutSpacer } from 'component_lib';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getReturnDetails } from 'common/action/creator';
import { getReturnReasonId } from 'helper/return';
import { IAppStore, IOrderDetail, IReturnReasonSelected, ReturnType } from '../../common/type';
import { ReturnProgressBar } from '../../component';
import { TextLink } from '../../component/link/Link';
import { customerCareEmail, defectedOrIncorrectItemReasonIds, dhlDirectContact, mailTo } from '../../helper/helpers';
import { useT } from '../../helper/i18n';
import { Tr } from '../../helper/i18n/Tr';
import { Layout, ButtonWrapper, Block, Ol, Ul, Li } from '../../component/layout/Layout';
import { Column } from '../../component/layout/Column';
import { Text, Strong } from '../../component/text/Text';
import { RowResponsive } from '../../component/layout/Row';
import { DhlLink } from '../../component/ReturnShipping/DhlLink';

/* Interfaces */
interface IOwnProps {
  match: {
    params: {
      returnId: string;
    };
  };
}

interface IProps {
  basePathWeb: string;
  handleGetReturn: (id: string) => void;
  language: string;
  orders: IOrderDetail[];
  returnId: string;
  returnReasonsSelected: IReturnReasonSelected[];
  returnShippingType: string;
  shipConfirmationNumber: string;
  paperlessReturnFlag: boolean;
}

const Code = styled.span`
  display: block;
`;

const PackTitle = styled(Text)`
  @media (max-width: 1023px) {
    font-family: ${fontFamilies.regular};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    text-align: left;
  }
`;

/* Helpers */
const mapStateToProps = (
  { basePathWeb, i18n, orders, shipConfirmationNumber, returnReasonsSelected, returnId, returnShippingType, paperlessReturnFlag }: IAppStore,
  ownProps: IOwnProps,
) => ({
  basePathWeb,
  language: i18n?.i18nextInstance?.language,
  orders,
  shipConfirmationNumber,
  returnReasonsSelected,
  returnShippingType,
  returnId: ownProps.match.params.returnId || returnId,
  paperlessReturnFlag: paperlessReturnFlag,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleGetReturn: (returnId: string) => dispatch(getReturnDetails(returnId)),
});

/* Component */
const PReturnPackBase = ({
  basePathWeb,
  language,
  shipConfirmationNumber,
  orders,
  returnId,
  returnShippingType,
  returnReasonsSelected,
  handleGetReturn,
  paperlessReturnFlag
}: IProps) => {
  const { t } = useT();
  const isJapanLanguage = language === 'ja';

  useEffect(() => {
    if (!returnReasonsSelected.length && returnId) {
      handleGetReturn(returnId);
    }
  }, []);

  const returnedItemIsDefectedOrIncorrect = useMemo(() => {
    if (!returnReasonsSelected.length) {
      return false;
    }
    const returnReasonId = getReturnReasonId(orders, returnReasonsSelected, returnId);
    return defectedOrIncorrectItemReasonIds.includes(returnReasonId);
  }, [returnReasonsSelected]);

  const defectiveText = returnShippingType === ReturnType.DROP_OFF ? 'returns::pack:text6' : 'returns::pack:text';
  const dropoffText =
    paperlessReturnFlag ?
      (
        returnShippingType === ReturnType.DROP_OFF && !!returnedItemIsDefectedOrIncorrect
          ? 'returns::pack:text5Paperless'
          : 'returns::pack:text4Paperless'
      )
      :
      (
        returnShippingType === ReturnType.DROP_OFF && !!returnedItemIsDefectedOrIncorrect
          ? 'returns::pack:text5'
          : 'returns::pack:text4'
      )
  ;
  const textInfo = returnShippingType === ReturnType.PICK_UP ? 'returns::pack:text2' : dropoffText;

  return (
    <>
      <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
        <ALayoutSpacer desktopHeight={'25px'} mobileHeight={'40px'} />
        <ReturnProgressBar activeStep={3} />
      </Layout>

      <ALayoutSpacer desktopHeight={'100px'} mobileHeight={'50px'} />
      <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
        <PackTitle bold>{t('returns::pack:title')}</PackTitle>
        <ALayoutSpacer desktopHeight={'55px'} mobileHeight={'50px'} />
        <RowResponsive>
          <Column flex={1}>
            <Ol>
              {!!returnedItemIsDefectedOrIncorrect && (
                <li className="red">
                  <Text color={colors.red} bold>
                    {returnShippingType === ReturnType.PICK_UP && t('returns::pack:subtitle1')}
                    {returnShippingType === ReturnType.DROP_OFF && t('returns::pack:subtitle6')}
                  </Text>
                  <ALayoutSpacer desktopHeight={'8px'} mobileHeight={'8px'} />
                  <Text color={colors.red}>
                    <Tr
                      k={defectiveText}
                      components={{
                        anchor: ({ children }) => (
                          <TextLink fontSize="inherit" color="inherit" underline href={mailTo(customerCareEmail)}>
                            {children}
                          </TextLink>
                        ),
                        Strong: ({ children }) => <Strong>{children}</Strong>,
                      }}
                    />
                  </Text>
                  <ALayoutSpacer desktopHeight={'44px'} mobileHeight={'40px'} />
                </li>
              )}
              <li>
                <Text bold>{t('returns::pack:subtitle2')}</Text>
                <ALayoutSpacer desktopHeight={'8px'} mobileHeight={'8px'} />
                <Text>
                  <Tr
                    k="returns::pack:text1"
                    components={{
                      Ul: ({ children }) => <Ul>{children}</Ul>,
                      Li: ({ children }) => <Li>{children}</Li>,
                      Strong: ({ children }) => <Strong>{children}</Strong>,
                    }}
                  />
                </Text>
                <ALayoutSpacer desktopHeight={'44px'} mobileHeight={'40px'} />
              </li>
              <li>
                {returnShippingType === ReturnType.PICK_UP && <Text bold>{t('returns::pack:subtitle3')}</Text>}
                {returnShippingType === ReturnType.DROP_OFF && <Text bold>{t('returns::pack:subtitle5')}</Text>}
                <ALayoutSpacer desktopHeight={'8px'} mobileHeight={'8px'} />
                <Text>
                  <Tr
                    k={textInfo}
                    components={{
                      Block: ({ children }) => <Block>{children}</Block>,
                      Code: () => <Code>{shipConfirmationNumber || '—'}</Code>,
                      DHLDirectly: ({ children }) => (
                        <TextLink
                          fontSize="inherit"
                          color="inherit"
                          underline
                          target="_blank"
                          href={dhlDirectContact(isJapanLanguage)}
                        >
                          {children}
                        </TextLink>
                      ),
                      Strong: ({ children }) => <Strong>{children}</Strong>,
                    }}
                  />
                </Text>
                <ALayoutSpacer mobileHeight={'36px'} />
              </li>
            </Ol>
          </Column>
          <Column flex={1}>
            <Text bold>{t('returns::pack:subtitle4')}</Text>
            <ALayoutSpacer desktopHeight={'8px'} mobileHeight={'8px'} />
            <Text>
              <Tr
                k="returns::pack:text3"
                components={{
                  anchor: ({ children }) => (
                    <TextLink fontSize="inherit" color="inherit" underline href={mailTo(customerCareEmail)}>
                      {children}
                    </TextLink>
                  ),
                }}
              />
            </Text>
            {returnShippingType === ReturnType.DROP_OFF && (
              <>
                <ALayoutSpacer desktopHeight={'8px'} mobileHeight={'8px'} />
                <DhlLink desktopHeight={'8px'} mobileHeight={'8px'} desktopTextSize={'16px'} />
              </>
            )}
          </Column>
        </RowResponsive>

        <ALayoutSpacer desktopHeight={'64px'} mobileHeight={'64px'} />
        <ButtonWrapper maxWidth="300px">
          <TextLink href={basePathWeb}>
            <Button fullWidth text={t('returns::print:continueShopping')} />
          </TextLink>
        </ButtonWrapper>
        <ALayoutSpacer desktopHeight={'64px'} mobileHeight={'64px'} />
      </Layout>
    </>
  );
};

export const PReturnPack = withRouter(connect(mapStateToProps, mapDispatchToProps)(PReturnPackBase));
