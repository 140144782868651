import { Reducer } from 'redux';
import { CLEAR, UPDATE } from '../action/type';

export const returnDocumentsError: Reducer = (state = false, action) => {
  switch (action.type) {
    case UPDATE.RETURN_DOCUMENTS_ERROR:
      return true;
    case CLEAR.RETURN_DOCUMENTS_ERROR:
      return false;
    default:
      return state;
  }
};
