import { Component } from 'react';
import { ALayoutFence, ALayoutFlexColumn, ALayoutSpacer, FooterDiv } from 'component_lib';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import isEmpty from 'lodash/isEmpty';
import { I18nTSContext } from 'helper/i18n';
import { Footer } from 'component/footer/Footer';
import { loadI18n, updateAppStore } from './common/action/creator';
import { IAppStore } from './common/type';
import {GTMNoScript, GTMScript, Header, ModalProcessing, ModalReturnsPolicy, Zendesk} from './component';
import { NavigationHeading } from './component/NavigationHeading/NavigationHeading';
import { ModalTrackShipment } from './component/modal/ModalTrackShipment';

import {
  POrderDetail,
  POrderList,
  PProfile,
  PReturnNew,
  PReturnOverview,
  PReturnPack,
  PReturnPrint,
  PReturnShippingDate,
  PReturnWarning,
} from './page';
import {PosthogIdentify} from "./component/posthog/PosthogIdentify";

/* Interfaces */
interface IProps {
  basePathWeb: string;
  handleUpdateAppStore: () => void;
  i18n: any;
  keys: any;
  loadI18n: () => void;
  location: string;
  paperlessReturnFlag: boolean;
  mktCountry: string;
}

/* Helpers */
const mapStateToProps = ({ basePathWeb, i18n, keys, mktCountry, paperlessReturnFlag }: IAppStore) => ({
  basePathWeb,
  i18n,
  keys,
  mktCountry,
  paperlessReturnFlag,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleUpdateAppStore: () => {
    const urlParams = new URLSearchParams(window.location.search);
    const offset = urlParams.get('offset') || 0;
    dispatch(updateAppStore(offset));
  },
  loadI18n: async () => dispatch(await loadI18n()),
});

/* Component */
class AppBase extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.props.loadI18n();

    this.localized = this.localized.bind(this);
  }

  public async componentDidMount() {
    this.props.handleUpdateAppStore();
  }

  localized(url = '/') {
    const { basePathWeb } = this.props;
    if (url === '/') {
      return basePathWeb;
    }
    return `${basePathWeb}${url[0] === '/' ? url.substring(1) : url}`;
  }

  public render() {
    const {
      i18n,
      i18n: { i18nextInstance },
    } = this.props;

    return !isEmpty(i18n) ? (
      <I18nTSContext.Provider value={{ i18nextInstance }}>
        <Header />
        <ALayoutFlexColumn>
          <GTMScript />
          <GTMNoScript />
          <Zendesk />
          <PosthogIdentify />
          <ModalProcessing />
          <ModalReturnsPolicy />
          <ModalTrackShipment />

          <ALayoutFence>
            <NavigationHeading />

            <Route path="/profile" component={PProfile} />
            <Route path="/" exact component={POrderList} />
            <Route path="/orders/:orderId" component={POrderDetail} />
            <Route path="/new_return_warning/:storeOrderId/:productStoreOrderId/:index" component={PReturnWarning} />
            <Route path="/new_return/:storeOrderId/:productStoreOrderId/:index" component={PReturnNew} />
            <Route path="/return_overview/:storeOrderId/:productStoreOrderId/:index" component={PReturnOverview} />
            <Route path="/choose_return_shipping_date" component={PReturnShippingDate} />
            <Route path="/pack/:returnId?" component={PReturnPack} />
            <Route path="/print_return/:returnId?" component={PReturnPrint} />
          </ALayoutFence>

          <FooterDiv>
            <Footer localized={this.localized} />
          </FooterDiv>

          <ALayoutSpacer mobileHeight="20px" />
        </ALayoutFlexColumn>
      </I18nTSContext.Provider>
    ) : (
      <></>
    );
  }
}

export const App = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppBase));
