import React from 'react';
import { ABarPrimary, ALayoutRowNoMargin, ATextTertiary, ATitleTertiary, color } from 'component_lib';
import styled from 'styled-components';
import { ITrackShipment } from '../../common/type';
import { useT } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  trackShipmentData: ITrackShipment[];
}

interface IRow {
  children: React.ReactNode;
  dark?: boolean;
}

/* Styles */
const RowLight = styled(ALayoutRowNoMargin)`
  flex-wrap: nowrap;
  padding: 8px;
`;

const RowDark = styled(RowLight)`
  background-color: ${color.greyLight};
`;

const ColMiddle = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 5px;
  padding-right: 5px;
`;

const ColLeft = styled(ColMiddle)`
  flex-grow: 0;
  flex-shrink: 0;
  width: 80px;
  padding-left: 0;
`;

const ColRight = styled(ColLeft)`
  padding-left: 5px;
  padding-right: 0;
`;

const ColMiddleBottom = styled(ColMiddle)`
  align-items: flex-end;
`;

const ColLeftBottom = ColLeft.withComponent(ColMiddleBottom);
const ColRightBottom = ColRight.withComponent(ColMiddleBottom);

const Line = styled(ABarPrimary)`
  background-color: ${color.greyDark};
`;

/* Helpers */
const TrackingRow = ({ children, dark }: IRow) =>
  dark ? <RowDark>{children}</RowDark> : <RowLight>{children}</RowLight>;

const generateRow = ({ date, status, location }: ITrackShipment, dark: boolean) => (
  <>
    <TrackingRow dark={dark}>
      <ColLeft>
        <ATextTertiary>{date}</ATextTertiary>
      </ColLeft>

      <ColMiddle>
        <ATextTertiary>{status}</ATextTertiary>
      </ColMiddle>

      <ColRight>
        <ATextTertiary>{location}</ATextTertiary>
      </ColRight>
    </TrackingRow>

    <Line />
  </>
);

const generateRows = (rows: ITrackShipment[]) => rows.map((row: ITrackShipment, i) => generateRow(row, i % 2 === 0));

/* Component */
export const ModalTrackShipmentRows = ({ trackShipmentData }: IProps) => {
  const { t } = useT();
  return (
    <>
      <RowLight>
        <ColLeftBottom>
          <ATitleTertiary>{t('returns::track:update')}</ATitleTertiary>
        </ColLeftBottom>

        <ColMiddleBottom>
          <ATitleTertiary>{t('returns::track:status')}</ATitleTertiary>
        </ColMiddleBottom>

        <ColRightBottom>
          <ATitleTertiary>{t('returns::track:location')}</ATitleTertiary>
        </ColRightBottom>
      </RowLight>

      <Line />

      {generateRows(trackShipmentData)}
    </>
  );
};
