import { fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { Text } from 'component/text/Text';
import { useT } from '../../helper/i18n';
import { ALayoutSpacer } from '../../component_lib';

const ReturnLink = styled.a<{
  color?: string;
  fontSize?: string;
  underline?: boolean;
}>`
  font-family: ${fontFamilies.regular};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  ${({ color }) => (color ? `color: ${color};` : '')}
  ${({ underline }) => (underline ? 'text-decoration: underline;' : '')}
  
  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

interface IProps {
  desktopHeight: string;
  desktopTextSize: string;
  mobileHeight: string;
}
const DhlLink: React.FC<IProps> = ({ desktopHeight, mobileHeight, desktopTextSize }) => {
  const { t } = useT();
  const dhlLink = 'https://locator.dhl.com/';
  return (
    <>
      <Text bold>{t('returns::book:dropOffDHLLocations')}</Text>
      <ALayoutSpacer desktopHeight={desktopHeight} mobileHeight={mobileHeight} />
      <Text fontSizeDesktop={desktopTextSize}>{t('returns::book:checkNearestPoints')}</Text>
      <p>
        <ReturnLink href={dhlLink} target={'_blank'} fontSize={desktopTextSize} underline>
          {t('returns::book:findPoint')}
        </ReturnLink>
      </p>
    </>
  );
};

export { DhlLink };
