import { IOrderDetail, IOrderItem, IReturnReasonSelected } from 'common/type';

export const getItemByReturnId = (orders: IOrderDetail[], returnId: string): IOrderItem => {
  let foundItem;

  if (!orders || !orders.length) {
    return undefined;
  }

  for (let i = 0; i < orders.length; ++i) {
    const { items } = orders[i];

    for (let index = 0; index < items.length; ++index) {
      const item = items[index];
      if (item.returnId === returnId) {
        foundItem = item;
        break;
      }
    }

    if (foundItem) {
      break;
    }
  }

  return foundItem;
};

export const getReturnReasonId = (
  orders: IOrderDetail[],
  returnReasons: IReturnReasonSelected[],
  returnId: string,
): string => {
  const item = getItemByReturnId(orders, returnId);

  if (!item) {
    return undefined;
  }

  const { productStoreOrderId } = item;
  const reason = returnReasons.find((r) => !!productStoreOrderId && r.productStoreOrderId === productStoreOrderId);
  return reason?.reason_id || undefined;
};
