import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { initDataDogRum } from './helper/datadog';
import { App } from './App';
import { configureStore } from './common/store/configureStore';
import '@italist/component-library/build/styles.css';
import { unregister } from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';

initDataDogRum();

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

unregister();
