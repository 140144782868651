import { fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { arabic12FontSizeCSSRule } from '../../helper/fonts';

const TableLabel = styled.span<{ fontWeight?: string; marginRight?: string }>`
  font-family: ${fontFamilies.bold};
  font-size: 12px;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  line-height: 18px;
  letter-spacing: 0em;
  text-transform: capitalize;
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight};` : '')}

  html[dir='rtl'] & {
    ${arabic12FontSizeCSSRule}
  }
`;

const TableText = styled.span`
  font-family: ${fontFamilies.regular};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;

  html[dir='rtl'] & {
    text-align: right;
    ${arabic12FontSizeCSSRule}
  }
`;

export { TableLabel, TableText };
