import { Reducer } from 'redux';
import { UPDATE } from '../action/type';

export const pagination: Reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE.PAGINATION:
      return action.value || {};
    default:
      return state;
  }
};
