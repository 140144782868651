import styled from 'styled-components';

const TableRow = styled.div<{ desktopHeight?: string; mobileHeight?: string }>`
  display: flex;

  @media (max-width: 1023px) {
    ${({ mobileHeight }) => (mobileHeight ? `height: ${mobileHeight};` : null)}
    align-items: center;
  }

  @media (min-width: 1024px) {
    ${({ desktopHeight }) => (desktopHeight ? `height: ${desktopHeight};` : null)}
    align-items: center;
    text-align: left;

    html[dir='rtl'] & {
      text-align: right;
    }
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export { TableRow, InfoRow };
