import { Button } from '@italist/component-library';
import { ALayoutSpacer } from 'component_lib';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ButtonWrapper } from 'component/layout/Layout';
import MediaQuery from 'react-responsive';

/* Interfaces */
interface IProps {
  backHref: string;
  backText: string;
  forwardHref?: string;
  forwardText: string;
  handleBack?: () => void;
  handler?: () => void;
}

const ButtonBackForwardWrapper = styled.div`
  display: flex;

  & > * {
    text-transform: capitalize;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`;

const BackButton = (props: IProps) => {
  const { backHref, backText, handleBack } = props;
  return (
    <ButtonWrapper maxWidth="240px">
      <Link onClick={handleBack || null} to={handleBack ? '' : backHref}>
        <Button fullWidth lightTheme padding={{ x: 0, y: 15 }} text={backText} />
      </Link>
    </ButtonWrapper>
  );
};

const ForwardButton = (props: IProps) => {
  const { forwardHref, forwardText, handler } = props;
  return (
    <ButtonWrapper maxWidth="240px">
      {handler ? (
        <Button fullWidth onClick={handler} padding={{ x: 0, y: 15 }} text={forwardText} />
      ) : (
        <Link to={forwardHref}>
          <Button fullWidth padding={{ x: 0, y: 15 }} text={forwardText} />
        </Link>
      )}
    </ButtonWrapper>
  );
};

/* Component */
export const ButtonBackForward = (props: IProps) => {
  return (
    <>
      <ALayoutSpacer desktopHeight="64px" mobileHeight="64px" />
      <ButtonBackForwardWrapper>
        <MediaQuery maxWidth={1023}>
          <ForwardButton {...props} />
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <BackButton {...props} />
        </MediaQuery>
        <ALayoutSpacer mobileHeight={'30px'} />

        <MediaQuery maxWidth={1023}>
          <BackButton {...props} />
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <ForwardButton {...props} />
        </MediaQuery>
      </ButtonBackForwardWrapper>
      <ALayoutSpacer desktopHeight="64px" mobileHeight="64px" />
    </>
  );
};
