import styled from 'styled-components';
import { colors } from '@italist/component-library';
import { Column } from '../layout/Column';

const BarWeb = styled.div`
  margin: 0 50px 0 0;
  width: 1px;
  height: 650px;
  background: ${colors.grayE5};
`;

const BarMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  background: ${colors.grayE5};
`;

export const ReturnSeparatorWeb = () => (
  <Column>
    <BarWeb />
  </Column>
);
export const ReturnSepartorMobile = () => (
  <BarMobile>
    <div
      style={{
        margin: '10px 0',
        position: 'absolute',
        width: '100%',
        height: '1px',
        background: colors.grayE5,
      }}
    />
    <div
      style={{
        position: 'absolute',
        color: colors.grayE5,
        background: 'white',
        padding: '0 10px',
      }}
    >
      OR
    </div>
  </BarMobile>
);
