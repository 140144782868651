export const color = {
  black: '#000',
  greyDark: '#999',
  greyLight: '#ECEEEF',
  red: '#FF0000',
  redDark: '#B94A48',
  white: '#FFF',
  orange: '#FF6534',
};

export const border = {
  black: `1px solid ${color.black}`,
  greyDark: `1px solid ${color.greyDark}`,
  greyLight: `1px solid ${color.greyLight}`,
  radiusPrimary: '5px',
  radiusSecondary: '2px',
  red: `1px solid ${color.red}`,
  redDark: `1px solid ${color.redDark}`,
};

export const fontFamily = {
  primary: 'Avenir Black',
  secondary: 'Avenir Light',
};

export const fontSize = {
  error: '10px',
  primary: '30px',
  secondary: '15px',
  tertiary: '13px',
};

export const fontWeight = {
  primary: 900,
  secondary: 300,
};

export const lineHeight = {
  error: '14px',
  primary: '41px',
  secondary: '20px',
  tertiary: '18px',
};
