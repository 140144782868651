import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { ABarPrimary, ATitleTertiary } from '../../atom';

/* Styles */
const RowDivider = styled(ABarPrimary)`
  margin: 4px 0;
`;

/* Components */
export const MTableHeaderCart = () => (
  <>
    <Row between="xs" center="xs" middle="xs">
      <Col xs={3} />
      <Col xs={3}>
        <ATitleTertiary>description</ATitleTertiary>
      </Col>
      <Col xs={2}>
        <ATitleTertiary>size</ATitleTertiary>
      </Col>
      <Col xs={2}>
        <ATitleTertiary>quantity</ATitleTertiary>
      </Col>
      <Col xs={2}>
        <ATitleTertiary>price</ATitleTertiary>
      </Col>
    </Row>
    <RowDivider />
  </>
);
