import React from 'react';
import { ABarPrimary, ALayoutSpacer } from 'component_lib';

import { Column } from '../layout/Column';
import { Row } from '../layout/Row';
import { useT } from '../../helper/i18n';
import { TableTitle } from './TableTitle';

/* Interfaces */
interface IProps {
  showReturn?: boolean;
}

/* Component */
export const TableOrderDetailHeader = ({ showReturn }: IProps) => {
  const { t } = useT();

  const headings = React.useMemo(
    () => (
      <>
        <Column flex={3} paddingRight={'20px'}>
          <TableTitle>{t('ordersAndShipping::itemsPurchased')}</TableTitle>
        </Column>
        <Column flex={3} paddingRight={'20px'}>
          <TableTitle>{t('ordersAndShipping::description')}</TableTitle>
        </Column>
        <Column flex={1.5} paddingRight={'20px'}>
          <TableTitle>{t('ordersAndShipping::color')}</TableTitle>
        </Column>
        <Column flex={1.5} paddingRight={'20px'}>
          <TableTitle>{t('ordersAndShipping::size')}</TableTitle>
        </Column>
        <Column flex={1.5} paddingRight={'20px'}>
          <TableTitle>{t('ordersAndShipping::quantity')}</TableTitle>
        </Column>
        <Column flex={showReturn ? 1 : 0.5} paddingRight={'20px'}>
          <TableTitle>{t('ordersAndShipping::price')}</TableTitle>
        </Column>
        {showReturn && (
          <Column flex={2} paddingRight={'20px'}>
            <TableTitle>{t('returns::return')}</TableTitle>
          </Column>
        )}
      </>
    ),
    [showReturn],
  );

  return (
    <>
      <Row>{headings}</Row>
      <ALayoutSpacer height="20px" />

      <ABarPrimary />
    </>
  );
};
