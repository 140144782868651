import { fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { arabic12FontSizeCSSRule } from '../../helper/fonts';

const TableTitle = styled.span`
  font-family: ${fontFamilies.bold};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: capitalize;

  html[dir='rtl'] & {
    text-align: right;
    ${arabic12FontSizeCSSRule}
  }
`;

export { TableTitle };
