import styled from 'styled-components';
import { color, fontFamily, fontSize, fontWeight, lineHeight } from '../../theme/StylePalette';

/* Interfaces */
export interface IALabelProps {
  textColor?: string;
  uppercase?: boolean;
}

/* Component */
export const ALabelPrimary = styled.label`
  color: ${({ textColor }: IALabelProps) => textColor || color.black};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.tertiary};
  font-weight: ${fontWeight.secondary};
  line-height: ${lineHeight.secondary};
  ${({ uppercase }: IALabelProps) => (uppercase ? 'text-transform: uppercase;' : null)};
`;
