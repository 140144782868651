import { Reducer } from 'redux';
import { CLEAR, UPDATE } from '../action/type';

export const newPassword: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.NEW_PASSWORD:
      return action.value;
    case CLEAR.NEW_PASSWORD:
      return '';
    default:
      return state;
  }
};

export const confirmPassword: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.CONFIRM_PASSWORD:
      return action.value;
    case CLEAR.CONFIRM_PASSWORD:
      return '';
    default:
      return state;
  }
};

export const currentPassword: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.CURRENT_PASSWORD:
      return action.value;
    case CLEAR.CURRENT_PASSWORD:
      return '';
    default:
      return state;
  }
};

export const passwordIndicator: Reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE.NEW_PASSWORD_INDICATOR:
      return action.value;
    case CLEAR.CURRENT_PASSWORD_INDICATOR:
      return {};
    default:
      return state;
  }
};

export const newPasswordError: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.NEW_PASSWORD_ERROR:
      return action.value;
    case CLEAR.NEW_PASSWORD_ERROR:
      return '';
    default:
      return state;
  }
};

export const confirmPasswordError: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.CONFIRM_PASSWORD_ERROR:
      return action.value;
    case CLEAR.CONFIRM_PASSWORD_ERROR:
      return '';
    default:
      return state;
  }
};

export const currentPasswordError: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.CURRENT_PASSWORD_ERROR:
      return action.value;
    case CLEAR.CURRENT_PASSWORD_ERROR:
      return '';
    default:
      return state;
  }
};
