import { Col, Row } from 'react-flexbox-grid';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import {
  ACartImage,
  ALayoutColAlignCenter,
  ALayoutRowNoMargin,
  ALayoutSpacer,
  ATextSecondary,
  ATextTertiary,
  ATitleTertiary,
} from '../../atom';
import { color } from '../../theme/StylePalette';
import {formatPrice} from "../../../helper/formatPrice";

/* Interfaces */
export interface IMTableRowCartItem {
  brand: string;
  currency: string;
  description: string;
  imgSrc: string;
  price: string;
  quantity: number;
  size: string;
}

/* Styles */
const RowBackground = styled(ALayoutRowNoMargin)`
  &&& {
    background-color: ${color.greyLight};
    padding: 15px;
  }
`;

/* Components */
export const MTableRowCartItem = (props: IMTableRowCartItem) => {
  const { brand, currency, description, imgSrc, price, quantity, size } = props;

  return (
    <>
      <MediaQuery maxWidth={767}>
        <RowBackground between="xs">
          <ALayoutColAlignCenter xs={4} sm={3}>
            <ACartImage src={imgSrc} />
          </ALayoutColAlignCenter>

          <Col xs={4} sm={3}>
            <ATitleTertiary>Description</ATitleTertiary>
            <ATextTertiary>{brand}</ATextTertiary>
            <ATextTertiary>{description}</ATextTertiary>
          </Col>

          <Col xs={4} sm={3}>
            <ATitleTertiary>Size</ATitleTertiary>
            <ATextTertiary uppercase>{size}</ATextTertiary>

            <ALayoutSpacer height={'10px'} />

            <ATitleTertiary>Quantity</ATitleTertiary>
            <ATextTertiary>{quantity}</ATextTertiary>

            <ALayoutSpacer height={'10px'} />

            <ATitleTertiary>Price</ATitleTertiary>
            <ATextTertiary uppercase>
              {currency} {formatPrice(price, currency)}
            </ATextTertiary>
          </Col>
        </RowBackground>
      </MediaQuery>

      <MediaQuery minWidth={768}>
        <Row between="xs" center="xs" middle="xs">
          <Col xs={3}>
            <ACartImage src={imgSrc} />
          </Col>

          <Col xs={3}>
            <ATextSecondary>{brand}</ATextSecondary>
            <ATextSecondary textColor={color.greyDark}>{description}</ATextSecondary>
          </Col>

          <Col xs={2}>
            <ATextSecondary uppercase>{size}</ATextSecondary>
          </Col>

          <Col xs={2}>
            <ATextSecondary>{quantity}</ATextSecondary>
          </Col>

          <Col xs={2}>
            <ATextSecondary uppercase>
              {currency} {formatPrice(price, currency)}
            </ATextSecondary>
          </Col>
        </Row>
      </MediaQuery>
    </>
  );
};
