import { fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { arabic14FontSizeCSSRule } from '../../helper/fonts';

interface TextProps {
  bold?: boolean;
  capitalize?: boolean;
  fontSizeDesktop?: string;
  fontSizeMobile?: string;
  margin?: string;
  uppercase?: boolean;
}

const Text = styled.span<TextProps>`
  font-family: ${({ bold }) => (bold ? fontFamilies.bold : fontFamilies.regular)};
  font-size: ${({ fontSizeDesktop }) => fontSizeDesktop || '16px'};
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
  ${({ uppercase }) => (uppercase ? 'text-transform: uppercase;' : '')}
  ${({ color }) => (color ? `color: ${color};` : '')}

  @media (max-width: 1023px) {
    font-size: ${({ fontSizeMobile }) => fontSizeMobile || '14px'};

    html[dir='rtl'] & {
      ${arabic14FontSizeCSSRule}
    }
  }
`;

const Strong = styled.span`
  font-family: ${fontFamilies.bold};
`;

export { Text, Strong };
