/* eslint-disable no-useless-escape */

export const containsLetter = (value: string) => /[a-z]/i.test(value);
export const containsLowerCase = (value: string) => /[a-z]/.test(value);
export const containsNumber = (value: string) => /\d/.test(value);

const containsSpecialChar = (value: string) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
const isMinLength = (value: string, length: number) => value.length >= length;
export const isNotEmpty = (value: string) => !!value.length;
export const isString = (value: string) => typeof value === 'string';
export const isNotEmptyString = (value: string) => isString(value) && isNotEmpty(value);

export const isValidPassword = (value: string) => {
  return {
    isMinLength: value && isMinLength(value, 8),
    isValid: value && isString(value) && containsLetter(value) && (containsNumber(value) || containsSpecialChar(value)),
  };
};

export const isValidEmail = (value: string) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );

export const isValidUrl = (value: string) =>
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/.test(
    value,
  );
