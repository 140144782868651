import styled from 'styled-components';
import { border, color, fontFamily, fontSize, fontWeight } from '../../theme/StylePalette';

export const AButtonPrimary = styled.button`
  background-color: ${color.black};
  border-radius: ${border.radiusPrimary};
  border: ${border.black};
  box-sizing: border-box;
  color: ${color.white};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.secondary};
  font-weight: ${fontWeight.primary};
  height: 44px;
  letter-spacing: 1px;
  padding: 12px 14px 8px;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    background-color: ${color.white};
    color: ${color.black};
  }
`;

export const AButtonSecondary = styled(AButtonPrimary)`
  background-color: ${color.white};
  color: ${color.black};

  &:hover {
    background-color: ${color.black};
    color: ${color.white};
  }
`;

export const AButtonSubmit = styled(AButtonPrimary)`
  margin-top: 20px;
`;
