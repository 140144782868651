import { Reducer } from 'redux';
import { CLEAR, UPDATE } from '../action/type';

export const returnItem: Reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE.RETURN_ITEM:
      return action.value;
    case CLEAR.RETURN_ITEM:
      return {};
    default:
      return state;
  }
};
