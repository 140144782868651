import { OHeader } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { logout } from '../../common/action/creator';
import { IAppStore } from '../../common/type';
import { useT } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  basePathWeb: string;
  handleLogout: (e: Event) => void;
  location: {
    pathname: string;
  };
}

/* Helpers */
const mapStateToProps = ({ basePathWeb }: IAppStore) => ({ basePathWeb });

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleLogout: (e: Event) => {
    e.preventDefault();
    dispatch(logout());
  },
});

/* Component */
const HeaderBase = ({ basePathWeb, handleLogout, location: { pathname } }: IProps) => {
  const { t } = useT();
  const viewingProfile = new RegExp('/profile/?').test(pathname);
  const viewingOrderDetail = new RegExp('/orders/[0-9]+/?').test(pathname);
  const viewingReturn = pathname.includes('return');
  const backHref = viewingOrderDetail ? '/' : basePathWeb;
  const backText = t('common::back');
  const displayBack = pathname === '/' || viewingOrderDetail || viewingProfile || viewingReturn;

  return (
    <OHeader
      logoHref={basePathWeb}
      logoutHandler={handleLogout as () => void}
      redirectHref={displayBack ? backHref : ''}
      redirectText={backText}
    />
  );
};

export const Header = withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderBase));
