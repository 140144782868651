import { fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { Row } from 'component/layout/Row';
import { AInfoTooltip } from '../../component_lib/atom';
import {formatPrice} from "../../helper/formatPrice";

/* Interfaces */
export interface TableLineItemProps {
  name: string;
  rate?: string;
  tooltip?: string;
}

interface TableRowProps {
  amount: string;
  currency: string;
}

interface IMTableRowPrimaryProps extends TableRowProps {
  name: string;
}
interface IMTableRowSecondaryProps extends TableLineItemProps, TableRowProps {
  credit?: boolean;
}

/* Styles */
const LineItem = styled.div`
  font-family: ${fontFamilies.regular};
  text-transform: capitalize;
`;

const Total = styled(LineItem)`
  font-family: ${fontFamilies.bold};
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 24px;

  @media (max-width: 1023px) {
    margin-top: 40px;
  }
`;

/* Components */
export const TableRowOrderSummaryBold = ({ amount, currency, name }: IMTableRowPrimaryProps) => (
  <Row justifyContent="space-between">
    <Total>{name}</Total>
    <Total>{`${currency} ${formatPrice(amount, currency)}`}</Total>
  </Row>
);

export const TableRowOrderSummaryNormal = ({
  amount,
  credit,
  currency,
  name,
  rate,
  tooltip,
}: IMTableRowSecondaryProps) => (
  <Row justifyContent="space-between">
    <LineItem>
      {name}
      {rate ? ` (${rate}%)` : null}
      {tooltip ? <AInfoTooltip text={tooltip} /> : null}
    </LineItem>
    <LineItem>
      {credit ? '-' : null}
      {`${currency} ${formatPrice(amount, currency)}`}
    </LineItem>
  </Row>
);
