import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Row } from 'component/layout/Row';
import { setReturnType } from '../../common/action/creator';
import { IAppStore, ReturnType } from '../../common/type';
import { useT } from '../../helper/i18n';
import { ColumnReturn } from '../layout/Column';
import { ReturnRadioButton } from '../button/RadioButton';
import { ReturnSelectionPickup } from './ReturnPickup';
import { ReturnSeparatorWeb } from './ReturnSeparationBar';
import { ReturnSelectionDropOff } from './ReturnDropOff';

interface IProps {
  handleReturnShippingType: (e: React.FormEvent<HTMLInputElement>) => void;
  selection: string;
}

const mapStateToProps = ({ returnShippingType }: IAppStore) => ({
  selection: returnShippingType,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleReturnShippingType: (e: React.FormEvent<HTMLInputElement>) => {
    const returnType = e.currentTarget.value.toString();
    if (returnType === ReturnType.DROP_OFF || returnType === ReturnType.PICK_UP) dispatch(setReturnType(returnType));
  },
});

const ReturnShippingWebBase: React.FC<IProps> = ({ selection, handleReturnShippingType }) => {
  const { t } = useT();
  return (
    <Row>
      <ColumnReturn>
        <ReturnRadioButton
          checked={selection === ReturnType.PICK_UP}
          label={t('returns::book:selectOrderPickup')}
          onChange={handleReturnShippingType}
          value={ReturnType.PICK_UP}
          labelId={ReturnType.PICK_UP}
        />
        <div
          style={{
            opacity: selection === ReturnType.PICK_UP ? 1 : 0.5,
            pointerEvents: selection === ReturnType.PICK_UP ? 'auto' : 'none',
          }}
        >
          <ReturnSelectionPickup />
        </div>
      </ColumnReturn>
      <ReturnSeparatorWeb />
      <ColumnReturn>
        <ReturnRadioButton
          checked={selection === ReturnType.DROP_OFF}
          label={t('returns::book:selectBringPackage')}
          onChange={handleReturnShippingType}
          value={ReturnType.DROP_OFF}
          labelId={ReturnType.DROP_OFF}
        />
        <div
          style={{
            opacity: selection === ReturnType.DROP_OFF ? 1 : 0.5,
            pointerEvents: selection === ReturnType.DROP_OFF ? 'auto' : 'none',
          }}
        >
          <ReturnSelectionDropOff />
        </div>
      </ColumnReturn>
    </Row>
  );
};

export const ReturnShippingWeb = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReturnShippingWebBase));
