import { Reducer } from 'redux';
import { TOGGLE } from '../action/type';

export const returnPolicyModal: Reducer = (state = false, action) => {
  switch (action.type) {
    case TOGGLE.RETURN_POLICY_MODAL:
      return !state;
    default:
      return state;
  }
};
