import styled from 'styled-components';

const Row = styled.div<{ alignItems?: string; flex?: number; justifyContent?: string }>`
  display: flex;
  ${({ flex }) => (flex ? `flex: ${flex};` : null)}
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
`;

/* Components */
const RowReturns = styled(Row)`
  @media (min-width: 1024px) {
    width: 40%;
  }
`;

const RowResponsive = styled(Row)`
  gap: 96px;

  @media (max-width: 1023px) {
    gap: 0;
    flex-direction: column;
  }
`;

export { Row, RowReturns, RowResponsive };
