import { ACartImage } from 'component_lib';
import MediaQuery from 'react-responsive';
import { IOrder } from '../../common/type';
import { TableOrderRowInfo } from './TableOrderRowInfo';
import { TableRow, InfoRow } from './TableRow';
import { Column } from '../layout/Column';

/* Component */
export const TableOrderListRow = (props: IOrder) => {
  const { imgSrc } = props;

  return (
    <>
      <TableRow desktopHeight="320px" mobileHeight="468px">
        <MediaQuery maxWidth={1023}>
          <Column alignItems="center">
            <ACartImage src={imgSrc} />
          </Column>
          <Column marginLeft={'25px'}>
            <InfoRow>
              <TableOrderRowInfo {...props} />
            </InfoRow>
          </Column>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <Column flex={3}>
            <ACartImage src={imgSrc} />
          </Column>
          <TableOrderRowInfo {...props} />
        </MediaQuery>
      </TableRow>
    </>
  );
};
