import styled from 'styled-components';

const PaginationList = styled.ul`
  display: flex;
  margin: 20px 0 0;
  border-radius: 3px;

  @media (max-width: 1023px) {
    justify-content: center;
  }

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export { PaginationList };
