import { AModalBackdrop } from '../../atom';
import { IMModalProcessingMessage, MModalProcessingMessage } from '../../molecule';

/* Interfaces */
interface IOModalProcessingProps extends IMModalProcessingMessage {
  visible: boolean;
}

/* Component */
export const OModalProcessing = ({ message, visible }: IOModalProcessingProps) => {
  if (visible) {
    return (
      <AModalBackdrop>
        <MModalProcessingMessage message={message} />
      </AModalBackdrop>
    );
  }
  return null;
};
