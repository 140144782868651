import styled from 'styled-components';

const PaginationWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 10px;

  @media (max-width: 1023px) {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
  }
`;

export { PaginationWrapper };
