import React from 'react';
import styled from 'styled-components';
import { border, color } from '../../theme/StylePalette';

/* Interfaces */
export interface IACheckboxProps {
  checked: boolean;
  handler: (e: React.FormEvent<HTMLInputElement>) => void;
}

/* Styles */
const Checkbox = styled.input`
  appearance: none;
  background: ${color.white}
  border: ${border.greyDark};
  border-radius: ${border.radiusSecondary};
  height: 16px;
  margin: 0 5px -2px 0;
  width: 16px;

  &:checked {
    background: ${color.black};
    background-image: url(https://d3nn9jcidi1lkh.cloudfront.net/mkt/icon/checkmark_white.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 11px;
    border: ${border.black};
  }

  html[dir='rtl'] & {
    margin: 0 0 -2px 5px;
  }
`;

/* Component */
export const ACheckboxPrimary = ({ checked, handler }: IACheckboxProps) => (
  <Checkbox checked={checked} onChange={handler} type="checkbox" />
);
