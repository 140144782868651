import { LanguagesConfig } from '../types/LanguagesConfig';

export const getActiveLangOrOverride = ({
  langIsoCode2,
  countryIsoCode2,
  languagesConfig,
}: {
  countryIsoCode2: string;
  langIsoCode2: string;
  languagesConfig: LanguagesConfig;
}): string => {
  const foundOverride = Object.entries(languagesConfig.overrides).find(
    ([_, { countriesOverridden, baseLanguages }]) =>
      countriesOverridden.includes(countryIsoCode2) && baseLanguages.includes(langIsoCode2),
  );
  if (foundOverride) {
    return foundOverride[0];
  }

  const foundLang = Object.keys(languagesConfig.languages).find((langName) => langName === langIsoCode2);
  return foundLang || 'en';
};
