import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getTrackShipmentData } from '../../common/action/creator';
import { IAppStore } from '../../common/type';
import { ArtificialLink } from './Link';
import { useT } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  handleTrackShipment: (storeOrderId: string) => void;
  storeOrderId: string;
}

/* Helpers */
const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>, ownProps: IProps) => ({
  handleTrackShipment: () => dispatch(getTrackShipmentData(ownProps.storeOrderId)),
});

/* Component */
const TrackOrderLinkBase = ({ handleTrackShipment }: IProps) => {
  const { t } = useT();
  return <ArtificialLink onClick={handleTrackShipment as any}>{t('ordersAndShipping::whereOrder')}</ArtificialLink>;
};

export const TrackOrderLink = withRouter(connect(null, mapDispatchToProps)(TrackOrderLinkBase));
