import { Reducer } from 'redux';
import { CLEAR, UPDATE } from '../action/type';

export const trackShipmentData: Reducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE.TRACK_SHIPMENT_DATA:
      return action.value;
    case CLEAR.TRACK_SHIPMENT_DATA:
      return [];
    default:
      return state;
  }
};

export const trackShipmentMessage: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.TRACK_SHIPMENT_MESSAGE:
      return action.value;
    case CLEAR.TRACK_SHIPMENT_MESSAGE:
      return '';
    default:
      return state;
  }
};
