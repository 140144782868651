import { FC } from 'react';
import styled from 'styled-components';
import { CLOUDINARY_CDN } from 'helper/helpers';

interface AuthInputCheckIconsProps {
  clickShow?: () => void;
  isError: boolean;
  showCheckMark: boolean;
}

const ShowPwdIcon = styled.img<{ showCheckMark: boolean }>`
  cursor: pointer;
  top: 50%;
  position: absolute;
  right: ${({ showCheckMark }) => (showCheckMark ? '40px' : '10px')};
  transform: translateY(-50%);

  html[dir='rtl'] & {
    left: ${({ showCheckMark }) => (showCheckMark ? '40px' : '10px')};
    right: unset;
  }
`;

const CheckMarkIcon = styled.img`
  top: 50%;
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;

  html[dir='rtl'] & {
    left: 10px;
    right: unset;
  }
`;

const AuthInputCheckIcons: FC<AuthInputCheckIconsProps> = ({ clickShow, isError, showCheckMark }) => {
  return (
    <>
      {clickShow && (
        <ShowPwdIcon
          onClick={clickShow}
          showCheckMark={showCheckMark}
          src={`${CLOUDINARY_CDN}/icons/show_hidden.svg`}
        />
      )}
      {showCheckMark && (
        <CheckMarkIcon src={`${CLOUDINARY_CDN}/icons/${isError ? 'info_red.svg' : 'check_with_circle_green.svg'}`} />
      )}
    </>
  );
};

export { AuthInputCheckIcons };
