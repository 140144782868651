import styled from 'styled-components';

import { fontFamilies, colors } from '@italist/component-library';

const Row = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`;

const Column = styled.div`
  width: 40%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const Strong = styled.span`
  font-family: ${fontFamilies.bold};
`;

const Orange = styled.span`
  color: ${colors.orange};
`;

const Ul = styled.ul`
  margin-top: 24px;
`;

const P = styled.ul`
  margin-top: 24px;
`;

const Li = styled.li`
  position: relative;
  line-height: 24px;
  margin-bottom: 24px;
  padding-left: 24px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    top: 10px;
    left: 10px;
    background-color: ${colors.black};
  }
`;

export { Strong, Ul, Li, P, Row, Column, Orange };
