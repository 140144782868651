import { AAnimationTextGlow, ATextTertiary } from '../../atom';
import styled from 'styled-components';

/* Interfaces */
interface IMSuccessMessageProps {
  message: string;
}

/* Styles */
const Message = styled(ATextTertiary)`
  position: absolute;
  animation: ${AAnimationTextGlow.getName()} 4s ease-out 1;
  margin: 20px 0;
`;

/* Component */
export const MSuccessMessage = ({ message }: IMSuccessMessageProps) =>
  message ? (
    <div style={{ position: 'relative' }}>
      <Message>{message}</Message>
    </div>
  ) : null;
