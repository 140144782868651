export const findGreatestLessThanOrEqualIdx = (
  arr: number[],
  val: number
): number | null => {
  if (!arr || arr.length === 0 || arr[0] > val) {
    return null;
  }

  let i = 0;
  let j = arr.length - 1;
  let mid = 0;

  while (i < j) {
    mid = Math.floor((i + j) / 2);

    if (arr[mid] === val) {
      return mid;
    }

    // if val is less than array mid element, then search in left
    if (val < arr[mid]) {
      if (mid > 0 && val >= arr[mid - 1]) {
        return mid - 1;
      }

      // repeat for left half
      j = mid;
    }
    // if val is greater than mid el
    else {
      if (mid < arr.length - 1 && val <= arr[mid + 1]) {
        if (val === arr[mid + 1]) {
          return mid + 1;
        }
        return mid;
      }

      // repeat for right half
      i = mid + 1;
    }
  }

  if (i === j && arr[i] <= val) {
    return i;
  }

  return null;
};

export const findLeastGreaterThanOrEqualIdx = (
  arr: number[],
  val: number
): number | null => {
  if (!arr || arr.length === 0 || arr[arr.length - 1] < val) {
    return null;
  }

  let i = 0;
  let j = arr.length - 1;
  let mid = 0;

  while (i < j) {
    mid = Math.floor((i + j) / 2);

    if (arr[mid] === val) {
      return mid;
    }

    // if val is less than array mid element, then search in left
    if (val < arr[mid]) {
      if (mid > 0 && val >= arr[mid - 1]) {
        if (val === arr[mid - 1]) {
          return mid - 1;
        }
        return mid;
      }

      // repeat for left half
      j = mid;
    }
    // if val > arr[mid]
    else {
      if (mid < arr.length - 1 && val <= arr[mid + 1]) {
        return mid + 1;
      }

      // repeat for right half
      i = mid + 1;
    }
  }

  if (i === j && arr[i] >= val) {
    return i;
  }

  return null;
};
