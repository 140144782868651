import { fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { arabic12FontSizeCSSRule, arabic14FontSizeCSSRule } from '../../helper/fonts';

export const TextLink = styled.a<{ color?: string; fontSize?: string; underline?: boolean }>`
  font-family: ${fontFamilies.regular};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  ${({ color }) => (color ? `color: ${color};` : '')}
  ${({ underline }) => (underline ? 'text-decoration: underline;' : '')}

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const LinkTertiary = TextLink.withComponent(Link);

export const ModalLink = styled(TextLink.withComponent('span'))`
  font-size: 16px;  
  cursor: pointer;
  text-decoration: underline;
  @media(max-width: 1023px) {
    font-size: 14px;

    html[dir='rtl'] & {
      ${arabic14FontSizeCSSRule}
    }
  }
`;

export const ArtificialLink = styled(ModalLink.withComponent('p'))`
  font-size: 12px;

  html[dir='rtl'] & {
    ${arabic12FontSizeCSSRule}
  }
`;

export const DownloadLink = styled.a`
  font-family: ${fontFamilies.regular};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  cursor: pointer;
  text-decoration: underline;
  text-transform: capitalize;
`;
