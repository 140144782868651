import { Reducer } from 'redux';
import { UPDATE } from '../action/type';

export const paperlessReturnFlag: Reducer = (state = false, action) => {
  switch (action.type) {
    case UPDATE.PAPERLESS_RETURN_FLAG:
      return action.value;
    default:
      return state;
  }
};
