import { FC } from 'react';
import styled from 'styled-components';
import { CLOUDINARY_CDN } from 'helper/helpers';
import { arabic12FontSizeCSSRule, arabic14FontSizeCSSRule } from '../../helper/fonts';

const Indicator = styled.span<{ isError: boolean; isPristine: boolean }>`
  color: ${({ isPristine, isError }) => (!isPristine && isError ? 'rgb(255, 52, 52)' : '#000')};
  font-size: 12px;
  line-height: 150%;

  @media (max-width: 1023px) {
    font-size: 14px;
    html[dir='rtl'] & {
      ${arabic14FontSizeCSSRule}
    }
  }

  html[dir='rtl'] & {
    ${arabic12FontSizeCSSRule}
  }
`;

const IndicatorIcon = styled.img`
  vertical-align: middle;
  margin-left: 10px;

  html[dir='rtl'] & {
    margin-left: unset;
    margin-right: 10px;
  }
`;

interface PasswordIndicatorProps {
  isError: boolean;
  isPristine: boolean;
  showIndicator: boolean;
  text: string;
}

const PasswordIndicator: FC<PasswordIndicatorProps> = ({ isError, isPristine, showIndicator, text }) => (
  <Indicator isError={isError} isPristine={isPristine}>
    {text}
    {showIndicator && <IndicatorIcon alt="" src={`${CLOUDINARY_CDN}/icons/check_mark_base.svg`} />}
    {!isPristine && isError && (
      <IndicatorIcon alt="" src={`${CLOUDINARY_CDN}/icons/${isError ? 'cancel_icon_red.svg' : 'cancel-icon.svg'}`} />
    )}
  </Indicator>
);

export { PasswordIndicator };
