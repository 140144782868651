import languagesConfig from 'i18n_data/config/languages.json';
import countriesConfig from 'i18n_data/config/countries.json';
import i18nts from '@italist/i18n-ts';
import { useT } from './useT';

export const { loadI18nResources, getActiveLangOrFallback, I18nTSContext } = i18nts;

export { useT, languagesConfig, countriesConfig };
export { Tr, Trp } from './Tr';
export { withT } from './withT';
export { initI18n } from './i18next';
export { i18nNamespaces, commonNamespaces } from './namespaces';

// can't be in separate files, because it's impossible to reexport types in this Typescript config
export type TranslationFunc = ReturnType<typeof useT>['t'];
export type TranslationFuncWithPlaceholders = ReturnType<typeof useT>['tp'];
