import styled from 'styled-components';
import { ALayoutRowNoMargin, color } from 'component_lib';
import { fontFamilies, colors } from '@italist/component-library';

export const RowBackground = styled(ALayoutRowNoMargin)`
  &&& {
    background-color: ${color.greyLight};
    padding: 8px 0;
  }
`;

export const RowWrapper = styled(ALayoutRowNoMargin)`
  &&& {
    margin: 0 12px;
  }
`;

export const ButtonWrapper = styled.div<{ maxWidth: string }>`
  width: 100%;
  @media (min-width: 1024px) {
    max-width: ${({ maxWidth }) => maxWidth};
  }
`;

export const ButtonSubLabel = styled.div<{ maxWidth: string }>`
  width: 100%;
  text-align: center;
  @media (min-width: 1024px) {
    max-width: ${({ maxWidth }) => maxWidth};
  }
`;

export const Layout = styled.div<{
  margin?: string;
  marginDesktop?: string;
  marginMobile?: string;
  maxWidthDesktop?: string;
  maxWidthMobile?: string;
  minWidthDesktop?: string;
  minWidthMobile?: string;
}>`
  @media (max-width: 1023px) {
    margin: ${({ marginMobile, margin }) => (marginMobile || margin ? `${marginMobile || margin}` : '0 30px')};
    max-width: ${({ maxWidthMobile }) => maxWidthMobile};
    min-width: ${({ minWidthMobile }) => minWidthMobile};
  }
  @media (min-width: 1024px) {
    margin: ${({ marginDesktop, margin }) => (marginDesktop || margin ? `${marginDesktop || margin}` : '0 auto')};
    max-width: ${({ maxWidthDesktop }) => maxWidthDesktop};
    min-width: ${({ minWidthDesktop }) => minWidthDesktop};
  }
`;

export const Ol = styled.ol`
  counter-reset: item;
  list-style-type: none;

  & > li {
    list-style-position: inside;
    counter-increment: item;

    &.red {
      color: ${colors.red};
    }
  }

  & > li:before {
    font-family: ${fontFamilies.bold};
    color: inherit;
    content: counter(item) '. ';
  }
`;

export const Ul = styled.ul`
  margin-top: 24px;
`;

export const Li = styled.li`
  position: relative;
  line-height: 24px;
  margin-bottom: 24px;
  padding-left: 24px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    top: 10px;
    left: 10px;
    background-color: ${colors.black};
  }
`;

export const Block = styled.span`
  display: block;
  margin-bottom: 24px;
`;
