import { colors } from '@italist/component-library';
import styled from 'styled-components';

/* Interfaces */
export interface IABarProps {
  grow?: boolean;
  height?: string;
  offsetY?: string;
}

/* Components */
export const ABarPrimary = styled.div`
  background-color: ${colors.grayE5};
  ${({ grow }: IABarProps) => (grow ? 'flex-grow: 1;' : null)}
  height: ${({ height }: IABarProps) => height || '1px'};
  ${({ offsetY }: IABarProps) => (offsetY ? `margin-bottom: ${offsetY};` : null)}
`;

export const ABarSecondary = styled(ABarPrimary)`
  background-color: ${colors.white};
`;
