import React from 'react';
import styled from 'styled-components';
import { DEFAULT_PAGE_SIZE, getTotalPages } from '../../helper/helpers';
import { PaginationElement } from './PaginationElement';
import { PaginationList } from './PaginationList';
import { PaginationWrapper } from './PaginationWrapper';

interface IProps {
  handleFetchOrders: (offset: number) => void;
  totalOrders: number;
}

type ArrowDirection = 'left' | 'right';
const toggleDirection = (direction: ArrowDirection) => {
  return direction === 'left' ? 'right' : 'left';
};

const Arrow = styled.div<{ direction: ArrowDirection }>`
  background: url(https://d3nn9jcidi1lkh.cloudfront.net/mkt/icon/arrow_${({ direction }) => direction}_black.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px;
  cursor: pointer;
  height: 20px;
  margin-${({ direction }) => toggleDirection(direction)}: 20px;
  width: 20px;

  @media (min-width: 1024px) {
    display: none;
  }

  html[dir='rtl'] & {
    background: url(https://d3nn9jcidi1lkh.cloudfront.net/mkt/icon/arrow_${({ direction }) =>
      toggleDirection(direction)}_black.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px;
    margin-${({ direction }) => direction}: 20px;
    margin-${({ direction }) => toggleDirection(direction)}: unset;
  }
`;

export const Pagination: React.FC<IProps> = ({ totalOrders, handleFetchOrders }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const offset = urlParams.get('offset') || 0;
  const currentPage = Math.floor(Number.parseInt(`${offset}`, 10) / DEFAULT_PAGE_SIZE) + 1;
  const totalPages = getTotalPages(totalOrders);
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  const handlePageClick = React.useCallback(
    (selectedPage) => {
      const newOffset = (selectedPage - 1) * DEFAULT_PAGE_SIZE;
      if (newOffset === offset) {
        return;
      }
      handleFetchOrders(newOffset);
    },
    [handleFetchOrders, offset],
  );

  const buildSeedArray = React.useCallback((length, curr, isFinalPage = false) => {
    const arrayFromLength = Array.from(new Array(length));
    if (isFinalPage) {
      return arrayFromLength.map((_, i) => curr - (length - (i + 1)));
    }
    return arrayFromLength
      .map((_, i) => {
        if (i + 1 < Math.ceil(length / 2)) {
          return curr - (i + 1);
        }
        if (i + 1 === Math.ceil(length / 2)) {
          return curr;
        }
        return curr + (length - i);
      })
      .sort((a, b) => a - b);
  }, []);

  const pagesNumber = React.useMemo(() => {
    let seedArray;

    if (totalPages === 2) {
      return [];
    }
    if (currentPage === totalPages) {
      if (totalPages > 5) {
        seedArray = buildSeedArray(5, currentPage, true);
        seedArray = seedArray.slice(0, -1);
      } else {
        seedArray = buildSeedArray(totalPages, currentPage, true);
        if (totalPages > 2 && totalPages <= 5) {
          seedArray = seedArray.slice(0, -1);
          seedArray.shift();
        } else if (totalPages > 2) {
          seedArray = seedArray.slice(0, -1);
        } else {
          seedArray.shift();
        }
      }
    } else if (currentPage >= 6) {
      seedArray = buildSeedArray(5, currentPage);
      if (currentPage === totalPages - 1) {
        seedArray = seedArray.slice(0, -2);
      }
      if (currentPage === totalPages - 2) {
        seedArray = seedArray.slice(0, -1);
      }
    } else if (totalPages >= 7) {
      seedArray = Array.from(new Array(7)).map((_, i) => i + 1);
      seedArray.shift();
    } else {
      seedArray = Array.from(new Array(totalPages - 1)).map((_, i) => i + 1);
      seedArray.shift();
    }

    return seedArray;
  }, [currentPage, totalPages, buildSeedArray]);

  return (
    <>
      <PaginationWrapper>
        {totalPages >= 2 && (
          <>
            <PaginationList>
              <Arrow direction="left" onClick={() => hasPreviousPage && handlePageClick(currentPage - 1)} />
              <PaginationElement currentPage={currentPage} handlePageClick={handlePageClick} pageNumber={1} />
              {currentPage >= 6 && <PaginationElement showDots />}
              {pagesNumber.map((page) => (
                <PaginationElement
                  key={`page_element_${page}`}
                  currentPage={currentPage}
                  handlePageClick={handlePageClick}
                  pageNumber={page}
                />
              ))}
              {currentPage <= totalPages - 4 && totalPages > 7 && <PaginationElement showDots />}
              <PaginationElement currentPage={currentPage} handlePageClick={handlePageClick} pageNumber={totalPages} />
              <Arrow direction="right" onClick={() => hasNextPage && handlePageClick(currentPage + 1)} />
            </PaginationList>
          </>
        )}
      </PaginationWrapper>
    </>
  );
};
