import { keyframes } from 'styled-components';

export const AAnimationTextGlow = keyframes`
  25% {
    text-shadow: 2px 2px 10px rgba(150, 150, 150, 0.5),
      -2px 2px 10px rgba(150, 150, 150, 0.5),
      2px -2px 10px rgba(150, 150, 150, 0.5),
      -2px -2px 10px rgba(150, 150, 150, 0.5);
  }
`;
