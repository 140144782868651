import React from 'react';
import { ABarPrimary } from 'component_lib';
import MediaQuery from 'react-responsive';
import { IOrderItem } from '../../common/type';
import { TableOrderDetailHeader } from './TableOrderDetailHeader';
import { TableOrderDetailRow } from './TableOrderDetailRow';

/* Interfaces */
interface IProps {
  items: IOrderItem[];
  orderStatus: string;
}

/* Helpers */
const generateRows = (items: IOrderItem[], orderStatus: string) =>
  items.reduce((rows: React.ReactNode[], row: IOrderItem, index) => {
    rows.push(
      <TableOrderDetailRow
        brand={row.brand}
        categoryId={row.categoryId}
        currency={row.currency}
        imgSrc={row.imgSrc}
        index={row.index}
        includeReturnColumn
        itemColor={row.itemColor}
        key={`${row.storeOrderNumber}-${index}`}
        model={row.model}
        modelNumber={row.modelNumber}
        orderDate={row.orderDate}
        orderStatus={orderStatus}
        price={row.price}
        productStoreOrderId={row.productStoreOrderId}
        productVersionOptionId={row.productVersionOptionId}
        quantity={row.quantity}
        returnId={row.returnId}
        returnStatus={row.returnStatus}
        size={row.size}
        sizeType={row.sizeType}
        storeOrderId={row.storeOrderId}
        storeOrderNumber={row.storeOrderNumber}
        storeOrderStatus={row.storeOrderStatus}
      />,
    );

    if (index < items.length - 1) {
      rows.push(<ABarPrimary key={`bar-${row.storeOrderNumber}-${index}`} />);
    }

    return rows;
  }, []);

/* Component */
export const TableOrderDetail = ({ items, orderStatus }: IProps) => (
  <>
    <MediaQuery minWidth={1024}>
      <TableOrderDetailHeader showReturn />
    </MediaQuery>

    {generateRows(items, orderStatus)}
  </>
);
