import { colors, fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { useT } from 'helper/i18n';
import { TextLink } from 'component/link/Link';
import { ALayoutSpacer, ALogoItalist } from '../../atom';
import { arabic12FontSizeCSSRule, arabic14FontSizeCSSRule } from '../../../helper/fonts';

/* Interfaces */
interface IRedirectProps {
  redirectHref?: string;
  redirectText?: string;
}

interface IOHeaderProps extends IRedirectProps {
  logoHref?: string;
  logoutHandler: () => void;
}

/* Styles */
const RedirectLink = styled(TextLink)`
  align-items: center;
  display: flex;
  text-decoration: none;
`;

const BackToShopping = styled(TextLink)`
  color: ${colors.black};
  font-family: ${fontFamilies.regular};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 1023px) {
    font-size: 14px;
    html[dir='rtl'] & {
      ${arabic14FontSizeCSSRule}
    }
  }

  html[dir='rtl'] & {
    ${arabic12FontSizeCSSRule}
  }
`;

const Logout = styled(TextLink)`
  font-family: ${fontFamilies.regular};
  color: ${colors.black};
  cursor: pointer;
  font-size: 12px;
  line-height: 24px;
  @media (max-width: 1023px) {
    font-size: 14px;
    html[dir='rtl'] & {
      ${arabic14FontSizeCSSRule}
    }
  }

  html[dir='rtl'] & {
    ${arabic12FontSizeCSSRule}
  }
`;

const OHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  background-color: ${colors.white};
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2;
  border-bottom: solid 1px ${colors.grayE5};
`;

const HeaderRow = styled.div`
  display: flex;
  flex: 1;

  @media (min-width: 1024px) {
    margin: 0 40px;
  }

  @media (max-width: 1023px) {
    margin: 0 15px;
  }
`;

const HeaderColumn = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

/* Helpers */
const Redirect = ({ redirectHref, redirectText }: IRedirectProps) =>
  redirectHref ? (
    <RedirectLink href={redirectHref}>
      <BackToShopping>{redirectText}</BackToShopping>
    </RedirectLink>
  ) : null;

/* Component */
export const OHeader = ({ logoHref, logoutHandler, redirectHref, redirectText }: IOHeaderProps) => {
  const { t } = useT();
  return (
    <>
      <OHeaderContainer>
        <HeaderRow>
          <HeaderColumn style={{ justifyContent: 'flex-star' }}>
            <Redirect redirectHref={redirectHref} redirectText={redirectText} />
          </HeaderColumn>
          <HeaderColumn style={{ justifyContent: 'center' }}>
            <ALogoItalist href={logoHref} />
          </HeaderColumn>
          <HeaderColumn style={{ justifyContent: 'flex-end' }}>
            <Logout onClick={logoutHandler}>{t('common::signOut')}</Logout>
          </HeaderColumn>
        </HeaderRow>
      </OHeaderContainer>

      <ALayoutSpacer height={'50px'} />
    </>
  );
};
