import { colors, fontFamilies } from '@italist/component-library';
import { ALayoutSpacer } from 'component_lib';
import { Link, withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import { browserPath } from '../../common/path';
import { useT } from '../../helper/i18n';
import { TextLink } from '../link/Link';

/* Interfaces */
interface IProps {
  history: { location: { pathname: string } };
}

/* Styles */
const HeadingLink = styled(TextLink.withComponent(Link))<{ position: number; withSeparator?: boolean }>`
  padding: 12px 20px;
  text-decoration: none;
	@media (max-width: 1023px) {
    display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		${({ withSeparator }) => (withSeparator ? `border-right: solid 1px ${colors.grayAA};` : '')}
	}

  @media (min-width: 1024px) {
    padding: ${({ position }) => (position === 0 ? '12px 10px 12px 20px' : '12px 20px 11px 10px')};
    width: 200px;
    text-align: ${({ position }) => (position === 0 ? 'right' : 'left')};

    html[dir='rtl'] & {
      padding: ${({ position }) => (position === 0 ? '12px 20px 12px 10px' : '12px 10px 11px 20px')};
      text-align: ${({ position }) => (position === 0 ? 'left' : 'right')};
    }
  }
`;

const Heading = styled.div`
  display: flex;

  @media (max-width: 1023px) {
    background-color: ${colors.black};
    height: 100px;
  }
  @media (min-width: 1024px) {
    margin: 0 auto;
    width: 450px;
    display: flex;
    justify-content: center;
  }
`;

const HeadingText = styled.span<{ active: boolean }>`
  font-family: ${fontFamilies.regular};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${({ active }) => (active ? colors.black : colors.grayAA)};
  text-transform: capitalize;

  @media (max-width: 1023px) {
    text-align: center;
    text-transform: uppercase;
    color: ${({ active }) => (active ? colors.white : colors.grayAA)};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
  }
`;

/* Component */
const NavigationHeadingBase = ({
  history: {
    location: { pathname },
  },
}: IProps) => {
  const { t } = useT();
  return (
    <>
      <MediaQuery minWidth={1024}>
        <ALayoutSpacer height={'30px'} />
      </MediaQuery>
      <Heading>
        <HeadingLink to={browserPath.orders()} withSeparator position={0}>
          <HeadingText active={pathname.indexOf('profile') !== 1}>{t('ordersAndShipping::tabTitle')}</HeadingText>
        </HeadingLink>

        <HeadingLink to={browserPath.profile()} position={1}>
          <HeadingText active={pathname.indexOf('profile') === 1}>{t('profile::tabTitle')}</HeadingText>
        </HeadingLink>
      </Heading>
      <MediaQuery minWidth={1024}>
        <ALayoutSpacer height={'50px'} />
      </MediaQuery>
    </>
  );
};

export const NavigationHeading = withRouter(NavigationHeadingBase);
