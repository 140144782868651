import { Reducer } from 'redux';
import { INIT } from '../action/type';

export const i18n: Reducer = (state = {}, action) => {
  switch (action.type) {
    case INIT.I18N:
      return action.value;
    default:
      return state;
  }
};
