import {useSelector} from "react-redux";
import {IAppStore} from "../../common/type";

export const PosthogIdentify = () => {
  const userId = useSelector((state: IAppStore) => state.userId);
  const countryCode = useSelector((state: IAppStore) => state.mktCountry);
  const preferredLangIsoCode2 = useSelector((state: IAppStore) => state.i18n.langIsoCode2);
  if (!userId) {
    return null;
  }
  return (
      <script data-usercentrics="PostHog.com" type="text/plain">
        {`
          (function waitForPosthog() {
            if (window.posthog && window.posthog._isIdentified !== undefined) {
              if (!window.posthog._isIdentified()) {
                window.posthog.identify("${userId}", {countryCode: "${countryCode}", language: "${preferredLangIsoCode2}"});
               }
            } else {
              setTimeout(waitForPosthog, 100);
            }
          })();
          `}
      </script>
  )
}