import { Button } from '@italist/component-library';
import React from 'react';
import { ALayoutSpacer, MSuccessMessage } from 'component_lib';
import { Layout, ButtonWrapper } from 'component/layout/Layout';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { validateProfile } from '../../common/action/creator';
import { IAppStore } from '../../common/type';
import { FormCustomerGender, FormCustomerName, FormCustomerPassword } from '../../component';
import { track, TrackingEvents } from '../../helper/tracking';
import { withT, TranslationFunc } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  email: string;
  handleProfileSubmit: () => void;
  profileFormSuccessMessage: string;
  t: TranslationFunc;
}

/* Helpers */
const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleProfileSubmit: () => dispatch(validateProfile()),
});

const mapStateToProps = ({ email, profileFormSuccessMessage }: IAppStore) => ({
  email,
  profileFormSuccessMessage,
});

/* Component */
class PProfileBase extends React.Component<IProps> {
  public componentDidMount() {
    const { email } = this.props;
    track(TrackingEvents.profileViewed, undefined, email);
  }

  public render() {
    const { handleProfileSubmit, profileFormSuccessMessage, t } = this.props;
    return (
      <Layout marginDesktop="0 auto" style={{ maxWidth: '420px' }}>
        <ALayoutSpacer height={profileFormSuccessMessage ? '10px' : '30px'} />
        <FormCustomerName />
        <ALayoutSpacer height={'35px'} />
        <FormCustomerGender />
        <ALayoutSpacer height={'65px'} />
        <FormCustomerPassword />
        <ALayoutSpacer height="32px" />
        <ButtonWrapper maxWidth="300px">
          <Button fullWidth onClick={handleProfileSubmit} padding={{ x: 15, y: 12 }} text={t('common::confirm')} />
        </ButtonWrapper>
        <MSuccessMessage message={profileFormSuccessMessage ? t('profile::save:success') : ''} />
        <ALayoutSpacer height={'65px'} />
      </Layout>
    );
  }
}

export const PProfile = withT(withRouter(connect(mapStateToProps, mapDispatchToProps)(PProfileBase)));
