import React from 'react';
import { ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { Layout } from 'component/layout/Layout';
import { Text } from 'component/text/Text';
import { RowReturns } from 'component/layout/Row';
import { browserPath } from '../../common/path';
import { IAppStore, IOrderItem, IReturnSummary } from '../../common/type';
import {
  ButtonBackForward,
  RedirectIfWrongUrlId,
  ReturnProgressBar,
  TableOrderDetailHeader,
  TableOrderDetailRow,
  TableReturnSummary
} from '../../component';
import { TextLink } from '../../component/link/Link';
import { TranslationFunc, withT } from '../../helper/i18n';
import { track, TrackingEvents } from '../../helper/tracking';
import { Tr } from '../../helper/i18n/Tr';

/* Interfaces */
interface IProps {
  email: string;
  history: any;
  returnItem: IOrderItem;
  returnSummary: IReturnSummary;
  t: TranslationFunc;
}

/* Helpers */
const mapStateToProps = ({ email, returnItem, returnSummary }: IAppStore) => ({
  email,
  returnItem,
  returnSummary,
});

/* Component */
class PReturnOverviewBase extends React.Component<IProps> {
  public componentDidMount() {
    const { email, returnItem, returnSummary } = this.props;
    const { currency, productVersionOptionId, storeOrderNumber } = returnItem;
    const { total } = returnSummary;

    track(
      TrackingEvents.confirmProductViewed,
      {
        order: {
          currency,
        },
        product: {
          product_version_option_id: productVersionOptionId,
        },
        return_order: {
          store_order: {
            store_order_number: storeOrderNumber,
          },
          total_refund: total,
        },
      },
      email,
    );
  }

  public render() {
    const { email, returnItem, returnSummary, history, t } = this.props;

    const {
      brand,
      currency,
      imgSrc,
      index,
      itemColor,
      model,
      modelNumber,
      orderDate,
      price,
      productStoreOrderId,
      productVersionOptionId,
      quantity,
      returnId,
      returnStatus,
      size,
      sizeType,
      storeOrderId,
      storeOrderNumber,
      storeOrderStatus,
    } = returnItem;

    const { rowsList, total } = returnSummary;

    return (
      <>
        <RedirectIfWrongUrlId />

        <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
          <ALayoutSpacer desktopHeight={'25px'} mobileHeight={'40px'} />
          <ReturnProgressBar activeStep={0} />
        </Layout>

        <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
          <ALayoutSpacer desktopHeight={'100px'} mobileHeight={'50px'} />
          <Text bold>{t('returns::overview:title')}</Text>
          <ALayoutSpacer desktopHeight={'110px'} mobileHeight={'50px'} />

          <MediaQuery minWidth={1023}>
            <TableOrderDetailHeader />
          </MediaQuery>

          <TableOrderDetailRow
            brand={brand}
            currency={currency}
            imgSrc={imgSrc}
            index={index}
            itemColor={itemColor}
            model={model}
            modelNumber={modelNumber}
            orderDate={orderDate}
            price={price}
            productStoreOrderId={productStoreOrderId}
            productVersionOptionId={productVersionOptionId}
            quantity={quantity}
            returnId={returnId}
            returnStatus={returnStatus}
            size={size}
            sizeType={sizeType}
            storeOrderId={storeOrderId}
            storeOrderNumber={storeOrderNumber}
            storeOrderStatus={storeOrderStatus}
          />

          <ALayoutSpacer height={'50px'} />

          <TableReturnSummary
            currency={currency}
            title={t('returns::returnSummary')}
            total={total}
            rowsList={rowsList}
          />

          <ALayoutSpacer desktopHeight={'132px'} mobileHeight={'68px'} />

          <RowReturns>
            <Text>
              <Tr
                components={{
                  anchor: ({ children }) => <TextLink fontSize="inherit">{children}</TextLink>,
                }}
                k="returns::overview"
              />
            </Text>
          </RowReturns>

          <ButtonBackForward
            handleBack={() => {
              track(TrackingEvents.returnEnded, { my_account: { page: 'Confirm Product Page' } }, email);
              history.push(browserPath.orders());
            }}
            backHref=""
            backText={t('common::cancel')}
            handler={() => {
              track(
                TrackingEvents.returnContinued,
                {
                  my_account: { page: 'Confirm Product Page' },
                },
                email,
              );
              history.push(browserPath.chooseReturnShippingDate());
            }}
            forwardText={t('common::confirm')}
          />
        </Layout>
      </>
    );
  }
}

export const PReturnOverview = withT(withRouter(connect(mapStateToProps)(PReturnOverviewBase)));
