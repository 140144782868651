import styled from 'styled-components';

const Column = styled.div<{ alignItems?: string; flex?: number; marginLeft?: string; paddingRight?: string }>`
  display: flex;
  flex-direction: column;
  margin-left: ${({ marginLeft }) => marginLeft || 0};
  padding-right: ${({ paddingRight }) => paddingRight || 0};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  flex: ${({ flex }) => (typeof flex !== undefined ? flex : 1)};
  @media (max-width: 1023px) {
    align-items: flex-start;
  }

  html[dir='rtl'] & {
    margin-left: unset;
    margin-right: ${({ marginLeft }) => marginLeft || 0};
    padding-left: ${({ paddingRight }) => paddingRight || 0};
    padding-right: unset;
  }
`;

const ColumnReturn = styled(Column)`
  flex: 0.5;

  @media (max-width: 1023px) {
    flex: 1;
  }
`;

export { Column, ColumnReturn };
