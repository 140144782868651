import { Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import { border, color } from '../../theme/StylePalette';
import { AAnimationFadeInPrimary } from '../animation/AFade';

export const AModalDialogPrimary = styled(Col)`
  &&& {
    max-width: 500px;
    z-index: 2;
  }
`;

export const AModalDialogSecondary = styled(AModalDialogPrimary)`
  &&& {
    animation: ${AAnimationFadeInPrimary.getName()} 0.25s ease-in-out 1;
    background-color: ${color.white};
    border-radius: ${border.radiusPrimary};
    max-height: 85vh;
    max-width: 530px;
    padding: 20px;
  }
`;
