import { ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Row } from 'component/layout/Row';
import { Layout } from 'component/layout/Layout';
import { Text } from 'component/text/Text';
import MediaQuery from 'react-responsive';
import { submitReturnShippingDate } from '../../common/action/creator';
import { IAppStore } from '../../common/type';
import { ButtonBackForward, RedirectIfEmptySummary, ReturnProgressBar } from '../../component';
import { track, TrackingEvents } from '../../helper/tracking';
import { useT } from '../../helper/i18n';
import { ReturnShippingWeb, ReturnShippingMobile } from '../../component/ReturnShipping';

interface IProps {
  email: string;
  handleSubmitShippingDate: () => void;
  history: any;
}

const mapStateToProps = ({ email, returnShippingType }: IAppStore) => ({
  email,
  selection: returnShippingType,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>, ownProps: IProps) => ({
  handleSubmitShippingDate: () => dispatch(submitReturnShippingDate(ownProps.history)),
});

const PReturnShippingDateBase = ({ email, handleSubmitShippingDate, history }: IProps) => {
  const { t } = useT();
  return (
    <>
      <RedirectIfEmptySummary />
      <ALayoutSpacer height={'25px'} />
      <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
        <ALayoutSpacer desktopHeight={'25px'} mobileHeight={'40px'} />
        <ReturnProgressBar activeStep={1} />
      </Layout>

      <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
        <ALayoutSpacer desktopHeight={'50px'} mobileHeight={'40px'} />
        <Row justifyContent={'center'}>
          <Text bold fontSizeDesktop={'20px'} fontSizeMobile={'20px'}>
            {t('returns::book:selectShipping')}
          </Text>
        </Row>
        <ALayoutSpacer desktopHeight={'60px'} mobileHeight={'30px'} />

        <MediaQuery minWidth={1024}>
          <ReturnShippingWeb />
        </MediaQuery>
        <MediaQuery maxWidth={1023}>
          <ReturnShippingMobile />
        </MediaQuery>

        <ButtonBackForward
          handleBack={() => {
            track(TrackingEvents.returnEnded, { my_account: { page: 'Book Pickup Page' } }, email);
            history.push('/');
          }}
          backHref=""
          backText={t('common::cancel')}
          forwardText={t('common::confirm')}
          handler={() => {
            track(TrackingEvents.returnContinued, { my_account: { page: 'Book Pickup Page' } }, email);
            handleSubmitShippingDate();
          }}
        />
      </Layout>
    </>
  );
};

export const PReturnShippingDate = withRouter(connect(mapStateToProps, mapDispatchToProps)(PReturnShippingDateBase));
