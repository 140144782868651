import styled from 'styled-components';

const Underline = styled.span`
  text-decoration: underline;
`;

const TextLink = styled.a`
  font-size: inherit;
  font-family: inherit;
  color: inherit;
`;

export { Underline, TextLink };
