import { useState } from 'react';
import { Input } from '@italist/component-library';
import { ATitleTertiary, ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Column } from 'component/layout/Column';
import { Row } from 'component/layout/Row';
import {
  updateConfirmPassword,
  updateCurrentPassword,
  updateNewPassword,
  validateConfirmPassword,
} from '../../common/action/creator';
import { IAppStore } from '../../common/type';
import { useT } from '../../helper/i18n';
import { AuthInputCheckIcons } from './AuthInputCheckIcons';
import { PasswordIndicator } from './PasswordIndicator';
import { isNotEmptyString } from '../../helper/validate';

/* Interfaces */
interface IProps {
  currentPassword: string;
  currentPasswordError: string;
  email: string;
  handleCurrentPassword: (value: string) => void;
  handleNewPassword: (value: string) => void;
  handleTrack: (event: string) => void;
  newPassword: string;
  newPasswordError: string;
  passwordIndicator: any;
}

/* Helpers */
const mapStateToProps = ({
  currentPassword,
  currentPasswordError,
  newPassword,
  newPasswordError,
  passwordIndicator,
}: IAppStore) => ({
  currentPassword,
  currentPasswordError,
  newPassword,
  newPasswordError,
  passwordIndicator,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleConfirmPassword: (value: string) => {
    dispatch(updateConfirmPassword(value));
    dispatch(validateConfirmPassword());
  },
  handleCurrentPassword: (value: string) => {
    dispatch(updateCurrentPassword(value));
  },
  handleNewPassword: (value: string) => {
    dispatch(updateNewPassword(value));
  },
});

/* Component */
const FormCustomerPasswordBase = (props: IProps) => {
  const {
    currentPassword,
    currentPasswordError,
    handleCurrentPassword,
    handleNewPassword,
    newPassword,
    newPasswordError,
    passwordIndicator,
  } = props;
  const { t } = useT();
  const [showPasswordRule, setShowPasswordRule] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(true);
  const [passwordPristine, setPasswordPristine] = useState(true);

  const getCurrentPasswordErrorText = (error) =>
    error === 'Current password is invalid'
      ? t('profile::error:currentPasswordInvalid')
      : t(`profile::error:${error}` as any);

  const checkIfPasswordsEmpty = () => {
    if (showPasswordRule && !isNotEmptyString(currentPassword) && !isNotEmptyString(newPassword)) {
      setShowPasswordRule(false);
    }
  };

  return (
    <>
      <Row>
        <ATitleTertiary>{t('profile::editPassword')}</ATitleTertiary>
      </Row>
      <ALayoutSpacer height="35px" />
      <Row>
        <Input
          desktopWidth="420px"
          isError={currentPasswordError !== ''}
          isPassword
          errorText={currentPasswordError !== '' ? (getCurrentPasswordErrorText(currentPasswordError) as any) : ''}
          mobileWidth="100%"
          onBlur={checkIfPasswordsEmpty}
          onInfoButtonClicked={() => {}}
          onValueChanged={handleCurrentPassword}
          placeholder={t('profile::oldPwd:placeholder')}
          value={currentPassword}
        />
      </Row>
      <ALayoutSpacer height="32px" />
      <div style={{ position: 'relative' }}>
        <Row>
          <Input
            desktopWidth="420px"
            errorText={newPasswordError !== '' ? (getCurrentPasswordErrorText(newPasswordError) as any) : ''}
            isError={newPasswordError !== ''}
            isPassword={showPasswordInput}
            mobileWidth="100%"
            onBlur={() => {
              handleNewPassword(newPassword);
              setPasswordPristine(false);
              checkIfPasswordsEmpty();
            }}
            onFocus={() => {
              if (!showPasswordRule) {
                setShowPasswordRule(true);
              }
            }}
            onInfoButtonClicked={() => {}}
            onValueChanged={handleNewPassword}
            placeholder={t('profile::newPwd:placeholder')}
            value={newPassword}
          />
          <AuthInputCheckIcons
            clickShow={() => setShowPasswordInput(!showPasswordInput)}
            isError={!passwordIndicator.isMinLength || !passwordIndicator.isValid}
            showCheckMark={!passwordPristine}
          />
        </Row>
      </div>
      {showPasswordRule && (
        <Row
          style={{
            marginTop: newPasswordError ? '30px' : '10px',
          }}
        >
          <Column>
            <PasswordIndicator
              isError={!passwordIndicator.isMinLength}
              isPristine={passwordPristine}
              showIndicator={passwordIndicator.isMinLength}
              text={t('profile::pwdTooltip:p1')}
            />
            <PasswordIndicator
              isError={!passwordIndicator.isValid}
              isPristine={passwordPristine}
              showIndicator={passwordIndicator.isValid}
              text={t('profile::pwdTooltip:p2')}
            />
          </Column>
        </Row>
      )}
    </>
  );
};

export const FormCustomerPassword = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormCustomerPasswordBase));
