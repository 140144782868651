import { Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import { ABarPrimary, ALayoutRowNoMargin, ATitleSecondary } from '../../atom';

/* Interfaces */
interface IMPageHeadingProps {
  text: string;
}

/* Styles */
const ColTitle = styled(Col)`
  margin: 15px 0 10px;
`;

/* Component */
export const MPageHeading = ({ text }: IMPageHeadingProps) => (
  <>
    <ALayoutRowNoMargin around="xs">
      <ColTitle xs={11}>
        <ATitleSecondary>{text}</ATitleSecondary>
      </ColTitle>
    </ALayoutRowNoMargin>
    <ABarPrimary height={'2px'} />
  </>
);
