import styled from 'styled-components';
import { TextLink } from '../../../component/link/Link';

/* Interfaces */
interface IALogoItalist {
  href?: string;
}

/* Styles */
const Img = styled.img`
  height: 30px;
`;

/* Helpers */
const imgSrc = 'https://d3nn9jcidi1lkh.cloudfront.net/mkt/logo.jpg';

/* Component */
export const ALogoItalist = ({ href }: IALogoItalist) =>
  href ? (
    <TextLink href={href}>
      <Img src={imgSrc} />
    </TextLink>
  ) : (
    <Img src={imgSrc} />
  );
