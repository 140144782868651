import { OrderStatusId, ReturnReason } from '../common/type';
import { TranslationFunc } from './i18n';

export const DEFAULT_PAGE_SIZE = 25;

export const getTotalPages = (totalOrders) => {
  return Math.ceil(totalOrders / DEFAULT_PAGE_SIZE);
};

export const dhlDirectContact = (isJapan?: boolean) =>
  `https://mydhl.express.dhl/${isJapan ? 'jp/ja' : 'us/en'}/help-and-support.html#/contact_us`;

export const customerCareEmail = 'customercare@italist.com';

export const mailTo = (email: string) => `mailto:${encodeURIComponent(email)}`;

export const translateShipping = (orderStatusId: string) => {
  switch (orderStatusId) {
    case OrderStatusId.shipped:
      return 'Shipped';
    case OrderStatusId.processing:
    case OrderStatusId.accepted:
      return 'Pending Shipment';
    case OrderStatusId.cancelled:
      return 'Cancelled';
    default:
      return 'Delivered';
  }
};

export const translateReasons = (reasonId: string, t: TranslationFunc) => {
  switch (reasonId) {
    case ReturnReason.changedMind:
      return t('returns::reasons:changedMind');
    case ReturnReason.itemTooBig:
      return t('returns::reasons:tooBig');
    case ReturnReason.itemTooSmall:
      return t('returns::reasons:tooSmall');
    case ReturnReason.colorHueSeemedDifferent:
      return t('returns::reasons:colorHueDifferent');
    case ReturnReason.materialSeemedDifferent:
      return t('returns::reasons:materialDifferent');
    case ReturnReason.colorCompletelyDifferent:
      return t('returns::reasons:colorDifferent');
    case ReturnReason.sizeOnLabelDifferentFromOneOrdered:
      return t('returns::reasons:sizeDifferent');
    case ReturnReason.faultyDirtyItem:
      return t('returns::reasons:faultyDirty');
    default:
      return t('returns::reasons:productDifferent');
  }
};

export const CLOUDINARY_CDN = 'https://cdn-images.italist.com/image/upload/v1583949723';

export const defectedOrIncorrectItemReasonIds = ['15', '16', '8', '17'];
