import React from 'react';
import { ABarPrimary, ALayoutSpacer } from 'component_lib';
import { useT } from '../../helper/i18n';
import { TableTitle } from './TableTitle';

import { Column } from '../layout/Column';
import { Row } from '../layout/Row';

/* Component */
export const TableOrderListHeader = () => {
  const { t } = useT();
  const headings = React.useMemo(() => {
    return (
      <>
        <Column flex={3}>
          <TableTitle>{t('ordersAndShipping::itemsPurchased')}</TableTitle>
        </Column>
        <Column flex={2}>
          <TableTitle>{t('ordersAndShipping::orderDate')}</TableTitle>
        </Column>
        <Column flex={2}>
          <TableTitle>{t('ordersAndShipping::orderNumber')}</TableTitle>
        </Column>
        <Column flex={2}>
          <TableTitle>{t('ordersAndShipping::orderStatus')}</TableTitle>
        </Column>
        <Column flex={2}>
          <TableTitle>{t('ordersAndShipping::shippingStatus')}</TableTitle>
        </Column>
        <Column flex={1}>
          <TableTitle>{t('ordersAndShipping::quantity')}</TableTitle>
        </Column>
        <Column flex={1}>
          <TableTitle>{t('ordersAndShipping::price')}</TableTitle>
        </Column>
      </>
    );
  }, []);
  return (
    <>
      <Row>{headings}</Row>
      <ALayoutSpacer height="20px" />
      <ABarPrimary />
    </>
  );
};
