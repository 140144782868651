import { Reducer } from 'redux';
import { CLEAR, UPDATE } from '../action/type';

export const processingModalText: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.PROCESSING_MODAL_TEXT:
      return action.value;
    case CLEAR.PROCESSING_MODAL_TEXT:
      return '';
    default:
      return state;
  }
};
