import { ALayoutSpacer } from 'component_lib';
import { Row } from 'component/layout/Row';
import { Text } from 'component/text/Text';
import { DhlLink } from './DhlLink';
import { useT } from '../../helper/i18n';

/* Component */
export const ReturnSelectionDropOff: React.FC = () => {
  const { t } = useT();
  return (
    <>
      <ALayoutSpacer desktopHeight={'30px'} mobileHeight={'30px'} />
      <Text bold>{t('returns::book:dropOffToDHL')}</Text>
      <ALayoutSpacer desktopHeight={'30px'} mobileHeight={'25px'} />
      <Row>
        <Text fontSizeDesktop="12px">{t('returns::book:dropOffPackageAndPrintDocuments')}</Text>
      </Row>
      <ALayoutSpacer desktopHeight={'30px'} mobileHeight={'50px'} />
      <DhlLink desktopHeight={'25px'} mobileHeight={'25px'} desktopTextSize={'12px'} />
    </>
  );
};
