import { Input } from '@italist/component-library';
import isEmpty from 'lodash/isEmpty';
import { Row } from 'react-flexbox-grid';
import { ATitleTertiary, ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { updateFirstName, updateLastName } from '../../common/action/creator';
import { IAppStore } from '../../common/type';
import { useT } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  firstName: string;
  firstNameError: string;
  handleFirstName: (value: string) => void;
  handleLastName: (value: string) => void;
  lastName: string;
  lastNameError: string;
}

/* Helpers */
const mapStateToProps = ({ firstName, firstNameError, lastName, lastNameError }: IAppStore) => ({
  firstName,
  firstNameError,
  lastName,
  lastNameError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleFirstName: (value: string) => {
    dispatch(updateFirstName(value));
  },
  handleLastName: (value: string) => {
    dispatch(updateLastName(value));
  },
});

/* Component */
const FormCustomerNameBase = (props: IProps) => {
  const { firstName, firstNameError, handleFirstName, handleLastName, lastName, lastNameError } = props;
  const { t } = useT();

  if (!isEmpty(firstNameError) || !isEmpty(lastNameError)) {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      <Row>
        <ATitleTertiary>{t('profile::generalInfo')}</ATitleTertiary>
      </Row>
      <ALayoutSpacer height="30px" />
      <Row>
        <Input
          desktopWidth="420px"
          isError={!isEmpty(firstNameError)}
          errorText={!isEmpty(firstNameError) ? t(`profile::error:firstName`) : ''}
          mobileWidth="100%"
          onInfoButtonClicked={() => {}}
          onValueChanged={handleFirstName}
          placeholder={t('profile::namePlaceholder')}
          value={firstName}
        />
      </Row>
      <ALayoutSpacer height="30px" />
      <Row>
        <Input
          desktopWidth="420px"
          isError={!isEmpty(lastNameError)}
          errorText={!isEmpty(lastNameError) ? t(`profile::error:lastName`) : ''}
          mobileWidth="100%"
          onInfoButtonClicked={() => {}}
          onValueChanged={handleLastName}
          placeholder={t('profile::surnamePlaceholder')}
          value={lastName}
        />
      </Row>
    </>
  );
};

export const FormCustomerName = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormCustomerNameBase));
