import React from 'react';
import { ALayoutSpacer } from 'component_lib';
import { Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { updateReturnReasonId } from '../../common/action/creator';
import { IAppStore } from '../../common/type';
import { RadioButton } from '../button/RadioButton';

/* Interfaces */
interface IProps {
  handleReturnReasonId: (e: React.FormEvent<HTMLInputElement>) => void;
  label: string;
  reasonId: string;
  selectedReasonId: string;
  spacer: string;
}

/* Helpers */
const mapStateToProps = ({ returnReasonId }: IAppStore) => ({ selectedReasonId: returnReasonId });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleReturnReasonId: (e: React.FormEvent<HTMLInputElement>) => dispatch(updateReturnReasonId(e.currentTarget.value)),
});

/* Component */
const FormReturnReasonBase = (props: IProps) => {
  const { handleReturnReasonId, label, reasonId, selectedReasonId, spacer } = props;

  return (
    <Col xs={12}>
      <ALayoutSpacer height={spacer} />
      <RadioButton
        checked={selectedReasonId === reasonId}
        onChange={handleReturnReasonId}
        label={label}
        value={reasonId}
      />
    </Col>
  );
};

export const FormReturnReason = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormReturnReasonBase));
