import React from 'react';
import { AModalBackdrop, AModalBodyScroll, AModalDialogSecondary } from '../../atom';
import { MModalHeader } from '../../molecule';

/* Interfaces */
interface IOModalInfoProps {
  children: React.ReactNode;
  handleClose: () => void;
  title: string;
  visible: boolean;
}

/* Component */
export const OModalInfo = ({ children, handleClose, title, visible }: IOModalInfoProps) => {
  if (visible) {
    return (
      <AModalBackdrop>
        <AModalDialogSecondary>
          <MModalHeader handleClose={handleClose} title={title} />
          <AModalBodyScroll>{children}</AModalBodyScroll>
        </AModalDialogSecondary>
      </AModalBackdrop>
    );
  }
  return null;
};
