import { trFactory } from './Tr/Tr';
import { getActiveLangOrFallback } from './getActiveLangOrFallback';
import { getI18nextOptions } from './getI18nextOptions';
import { loadI18nResources } from './loadI18nResources';
import { useT } from './useT';
import { withT } from './withT';
import { I18nTSContext } from './I18nTSContext';
import {
  translatedCountryChangePopupMsgs,
  translatedCountryNames,
  translatedCurrencyCode,
  translatedLangNames,
} from './translatedCountryData';

export { TranslationFunc, TranslationFuncWithPlaceholders } from './translationFuncs';
export { I18nTSContextType } from './I18nTSContext';

export default {
  getActiveLangOrFallback,
  getI18nextOptions,
  I18nTSContext,
  loadI18nResources,
  translatedCountryChangePopupMsgs,
  translatedCountryNames,
  translatedCurrencyCode,
  translatedLangNames,
  trFactory,
  useT,
  withT,
};
