import styled from 'styled-components';
import { border, color, fontFamily, fontSize, fontWeight } from '../../theme/StylePalette';

/* Interfaces */
export interface IATextInputProps {
  disabled?: boolean;
  error?: boolean;
}

/* Component */
export const ATextInputPrimary = styled.input`
  background-color: ${({ disabled }: IATextInputProps) => (disabled ? color.greyLight : color.white)};
  border-radius: ${border.radiusPrimary};
  border: ${({ error }: IATextInputProps) => (error ? border.redDark : border.greyDark)};
  box-sizing: border-box;
  color: ${color.black};
  font-family: ${fontFamily.secondary}
  font-size: ${fontSize.secondary};
  font-weight: ${fontWeight.secondary};
  height: 44px;
  margin: ${({ error }: IATextInputProps) => (error ? '10px 0 1px' : '10px 0 15px')};
  padding: 12px 10px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
