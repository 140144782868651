import { TFunctionDetailedResult } from 'i18next';
import React, { ComponentType, ReactNode } from 'react';
import { SortedMap } from 'sweet-collections';
import { findGreatestLessThanOrEqualIdx, findLeastGreaterThanOrEqualIdx } from '../collections/sortedArray';

const getOpeningTagsRe = /<[\u200f\u200e ]*([^<>/]+)[\u200f\u200e ]*>/gsu;
const getClosingTagsRe = /<[\u200f\u200e ]*\/([^<>/]+)[\u200f\u200e ]*>/gsu;

const createTreeNode = ({
  components,
  text,
  matchedOpeningTags,
  startIdx: s,
  sortedTagsPositions,
  endIdxExcluded,
}: {
  components: Record<string, ComponentType>;
  endIdxExcluded: number;
  matchedOpeningTags: SortedMap<
    number,
    {
      closingTagEndIdxExcluded: number;
      closingTagStartIdx: number;
      endIdxExcluded: number;
      isOpeningTag: boolean;
      name: string;
    }
  >;
  sortedTagsPositions: number[];
  startIdx: number;
  text: string | TFunctionDetailedResult<string, any>;
}): ReactNode[] => {
  const nodes: ReactNode[] = [];

  const firstOpeningTagInRangeArrIdx = findLeastGreaterThanOrEqualIdx(sortedTagsPositions, s);
  if (firstOpeningTagInRangeArrIdx === null) {
    nodes.push((text as string).slice(s, endIdxExcluded));
    return nodes;
  }

  const lastOpeningTagInRangeArrIdx = findGreatestLessThanOrEqualIdx(sortedTagsPositions, endIdxExcluded - 1);
  if (lastOpeningTagInRangeArrIdx === null || firstOpeningTagInRangeArrIdx > lastOpeningTagInRangeArrIdx) {
    nodes.push((text as string).slice(s, endIdxExcluded));
    return nodes;
  }

  let startIdx = s;
  for (let tagArrIdx = firstOpeningTagInRangeArrIdx; tagArrIdx <= lastOpeningTagInRangeArrIdx; tagArrIdx++) {
    const tagStartIdx = sortedTagsPositions[tagArrIdx];
    const tag = matchedOpeningTags.get(tagStartIdx);
    if (tag === null || tag === undefined) {
      console.error(`internal error - missing tag at ${tagStartIdx} in matchedOpeningTags`, { matchedOpeningTags });
      break;
    }

    if (tagStartIdx < startIdx) {
      continue;
    }

    if (tagStartIdx > startIdx) {
      nodes.push((text as string).slice(startIdx, tagStartIdx));
      startIdx = tagStartIdx;
    }

    const C = components[tag.name];
    if (!C) {
      console.error(`component "${tag.name}" is missing`, { C, text });
      startIdx = tag.closingTagEndIdxExcluded;
      return nodes;
    }
    nodes.push(
      <C key={`${tag.closingTagStartIdx}$${tag.endIdxExcluded}$${text}`}>
        {createTreeNode({
          components,
          endIdxExcluded: tag.closingTagStartIdx,
          matchedOpeningTags,
          sortedTagsPositions,
          startIdx: tag.endIdxExcluded,
          text,
        })}
      </C>,
    );

    startIdx = tag.closingTagEndIdxExcluded;
  }

  if (startIdx < endIdxExcluded) {
    nodes.push((text as string).slice(startIdx, endIdxExcluded));
  }

  return nodes;
};

const buildTagTree = ({
  components,
  tags,
  text,
}: {
  components: Record<string, ComponentType>;
  tags: SortedMap<
    number,
    {
      endIdxExcluded: number;
      isOpeningTag: boolean;
      name: string;
    }
  >;
  text: string | TFunctionDetailedResult<string, any>;
}): ReactNode[] => {
  const matchedOpeningTags = new SortedMap<
    number,
    {
      closingTagEndIdxExcluded: number;
      closingTagStartIdx: number;
      endIdxExcluded: number;
      isOpeningTag: boolean;
      name: string;
    }
  >((a: number, b: number) => a - b);

  const openTagsStartPositions: Record<string, number[]> = {};
  const openTag = (tagName: string, tagStartPos: number) => {
    if (!openTagsStartPositions[tagName]) {
      openTagsStartPositions[tagName] = [];
    }
    openTagsStartPositions[tagName].push(tagStartPos);
  };
  const removeLastOpenTagPos = (tagName: string): number | null => {
    const arr = openTagsStartPositions[tagName];
    if (!arr || arr.length === 0) {
      return null;
    }
    return arr.pop() as number;
  };

  tags.forEach((tag, tagStartIdx) => {
    if (tag.isOpeningTag) {
      openTag(tag.name, tagStartIdx);
      return;
    }

    // tag is a closing tag
    const lastOpenTagOfSameNamePos = removeLastOpenTagPos(tag.name);
    if (lastOpenTagOfSameNamePos === null) {
      console.error(`trying to close tag that hasn't been open: "${tag.name}" at pos ${tagStartIdx} in "${text}"`);
      return;
    }

    matchedOpeningTags.set(lastOpenTagOfSameNamePos, {
      ...(tags.get(lastOpenTagOfSameNamePos) as any),
      closingTagEndIdxExcluded: tag.endIdxExcluded,
      closingTagStartIdx: tagStartIdx,
    });
  });

  const sortedTagsPositions = Array.from(matchedOpeningTags.keys());

  return createTreeNode({
    components,
    endIdxExcluded: (text as string).length,
    matchedOpeningTags,
    sortedTagsPositions,
    startIdx: 0,
    text,
  });
};

const getTagsData = ({
  text,
  findTagRegExp,
}: {
  findTagRegExp: RegExp;
  text: string | TFunctionDetailedResult<string, any>;
}): { endIdxExcluded: number; name: string; startIdx: number }[] => {
  const tags: { endIdxExcluded: number; name: string; startIdx: number }[] = [];

  let m: RegExpExecArray | null;
  do {
    m = findTagRegExp.exec(text as string);
    if (m) {
      const idx = m.index;
      const endIdxExcluded = findTagRegExp.lastIndex;
      const name = m[1];

      tags.push({ endIdxExcluded, name, startIdx: idx });
    }
  } while (m !== null);

  return tags;
};

export class TagTree {
  private root: ReactNode[];

  constructor(text: string | TFunctionDetailedResult<string, any>, components: Record<string, ComponentType>) {
    const openingTags = getTagsData({ findTagRegExp: getOpeningTagsRe, text });
    const closingTags = getTagsData({
      findTagRegExp: getClosingTagsRe,
      text,
    });

    const tags: SortedMap<
      number,
      {
        endIdxExcluded: number;
        isOpeningTag: boolean;
        name: string;
      }
    > = new SortedMap((a: number, b: number) => a - b);

    [
      ...openingTags.map((t) => ({ ...t, isOpeningTag: true })),
      ...closingTags.map((t) => ({ ...t, isOpeningTag: false })),
    ].forEach((t) => tags.set(t.startIdx, t));

    this.root = buildTagTree({
      components,
      tags,
      text,
    });
  }

  public getNodes(): ReactNode[] {
    return this.root;
  }
}
