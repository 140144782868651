import { fontFamilies, Button } from '@italist/component-library';
import React from 'react';
import styled from 'styled-components';
import { ALayoutSpacer } from 'component_lib';
import { Layout, ButtonWrapper } from 'component/layout/Layout';
import { DownloadLink } from 'component/link/Link';
import { Column } from 'component/layout/Column';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { downloadInvoices } from '../../common/action/creator';
import { IAppStore, IOrderDetail, IStoreOrderTracking } from '../../common/type';
import { TableOrderDetail, TableOrderSummary } from '../../component';
import { translateShipping } from '../../helper/helpers';
import { track, TrackingEvents } from '../../helper/tracking';
import { withT, TranslationFunc } from '../../helper/i18n';
import { arabic14FontSizeCSSRule } from '../../helper/fonts';

/* Interfaces */
interface IProps {
  email: string;
  match: {
    params: {
      orderId: string;
    };
  };
  orders: IOrderDetail[];
  t: TranslationFunc;
}

/* Styles */
const OrderNumber = styled.span`
  font-family: ${fontFamilies.bold};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;

  @media (max-width: 1023px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    html[dir='rtl'] & {
      ${arabic14FontSizeCSSRule}
    }
  }
`;

const SummaryWrapper = styled.span`
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    min-width: 300px;
  }
`;

/* Helpers */
const mapStateToProps = ({ email, orders }: IAppStore) => ({ email, orders });

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleDownloadInvoices: (orderId: string) => dispatch(downloadInvoices(orderId)),
});

/* Component */
class POrderDetailBase extends React.Component<IProps> {
  public componentDidMount() {
    const { email, match, orders } = this.props;
    const order = orders ? orders.filter((o) => o.orderId === match.params.orderId)[0] : null;

    if (order) {
      const storeOrders: IStoreOrderTracking[] = [];
      order.items.forEach((item, index) => {
        storeOrders.push({
          shipping_status: translateShipping(item.storeOrderStatus.order_status_id),
          store_order_number: order.storeOrderNumbers[index],
          store_order_status: item.storeOrderStatus.status,
        });
      });

      track(
        TrackingEvents.orderPageViewed,
        {
          order: {
            currency: order.currency,
            custom_duties: order.customs,
            grand_total: order.price,
            order_date: order.orderDate,
            order_number: order.orderNumber,
            products: order.productVersionOptionIds,
            promotion: order.promotion,
            quantity: order.quantity,
            sales_tax: order.caTax || order.vat,
            shipping: order.shipping,
            store_orders: storeOrders,
            subtotal: order.subtotal,
            vat: order.vat,
          },
        },
        email,
      );
    }
  }

  public render() {
    const { email, match, orders, t } = this.props;
    const order = orders ? orders.filter((o) => o.orderId === match.params.orderId)[0] : null;

    if (!order) {
      return null;
    }

    const {
      caTax,
      currency,
      customs,
      hasInvoices,
      isTaxUeZone,
      items,
      orderId,
      orderNumber,
      orderStatus,
      price,
      promotion,
      shipping,
      subtotal,
      tax,
      taxRate,
    } = order;

    return (
      <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
        <ALayoutSpacer height={'20px'} />

        <OrderNumber>
          {t('ordersAndShipping::orderNumber')}: {orderNumber}
        </OrderNumber>
        <ALayoutSpacer desktopHeight={'100px'} mobileHeight="35px" />

        <TableOrderDetail items={items} orderStatus={orderStatus.order_status_id} />

        <ALayoutSpacer desktopHeight="60px" mobileHeight="60px" />

        <Column alignItems="flex-end">
          <SummaryWrapper>
            <TableOrderSummary
              caTax={caTax}
              currency={currency}
              customs={customs}
              isTaxUeZone={isTaxUeZone}
              promotion={promotion}
              shipping={shipping}
              subtotal={subtotal}
              tax={tax}
              taxRate={taxRate}
              title={t('ordersAndShipping::orderSummary')}
              total={price}
            />
            {hasInvoices ? (
              <DownloadLink onClick={downloadInvoices(orderId, orderNumber, email) as any}>
                {t('ordersAndShipping::downloadInvoice')}
              </DownloadLink>
            ) : null}
          </SummaryWrapper>
        </Column>

        <ALayoutSpacer height="60px" />

        <ButtonWrapper maxWidth="300px">
          <Link to={'/'}>
            <Button fullWidth lightTheme text={t('common::back')} />
          </Link>
        </ButtonWrapper>
        <ALayoutSpacer desktopHeight="135px" mobileHeight="85px" />
      </Layout>
    );
  }
}

export const POrderDetail = withT(withRouter(connect(mapStateToProps, mapDispatchToProps)(POrderDetailBase)));
