import styled from 'styled-components';
import { ATitleSecondary } from '../../atom';
import {formatPrice} from "../../../helper/formatPrice";

/* Interfaces */
interface IMTotalPriceProps {
  currency: string;
  total: string;
}

/* Styles */
const Total = styled(ATitleSecondary)`
  line-height: initial;
`;

/* Component */
export const MTotalPrice = ({ currency, total }: IMTotalPriceProps) => (
  <Total>
    {currency} {formatPrice(total, currency)}
  </Total>
);
