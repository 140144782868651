import { combineReducers } from 'redux';
import { IAppStore } from '../type';
import { basePathWeb } from './basePathWeb';
import { country } from './country';
import { mktCountry } from './mktCountry';
import { gender, genderError } from './customerGender';
import { firstName, firstNameError, lastName, lastNameError, userId } from './customerName';
import {
  confirmPassword,
  confirmPasswordError,
  currentPassword,
  currentPasswordError,
  newPassword,
  newPasswordError,
  passwordIndicator,
} from './customerPassword';
import { email } from './email';
import { i18n } from './i18n';
import { keys } from './keys';
import { orders } from './orders';
import { pagination } from './pagination';
import { processingModalText } from './processingModal';
import { profileFormSuccessMessage } from './profileFormSuccessMessage';
import { returnDocuments } from './returnDocuments';
import { returnDocumentsError } from './returnDocumentsError';
import { returnRequestExpiredError } from './returnRequestExpiredError';
import { returnId } from './returnId';
import { returnItem } from './returnItem';
import { returnPolicyModal } from './returnPolicyModal';
import { returnReasonId, returnReasons } from './returnReason';
import { returnReasonsSelected } from './returnReasonsSelected';
import { returnShippingDate, returnShippingHour } from './returnShippingDate';
import { returnSummary } from './returnSummary';
import { shipConfirmationNumber } from './shipConfirmationNumber';
import { shippingDates, shippingHours } from './shippingDates';
import { trackShipmentData, trackShipmentMessage } from './trackShipment';
import { returnShippingType } from './returnShippingType';
import { returnRestrictedCategories } from './returnRestrictedCategories';
import { paperlessReturnFlag } from "./lowerInvoiceFlag";

export const rootReducer = combineReducers<IAppStore>({
  basePathWeb,
  confirmPassword,
  confirmPasswordError,
  country,
  currentPassword,
  currentPasswordError,
  email,
  firstName,
  firstNameError,
  gender,
  genderError,
  i18n,
  keys,
  lastName,
  userId,
  lastNameError,
  paperlessReturnFlag,
  mktCountry,
  newPassword,
  newPasswordError,
  orders,
  pagination,
  passwordIndicator,
  processingModalText,
  profileFormSuccessMessage,
  returnDocuments,
  returnDocumentsError,
  returnRequestExpiredError,
  returnId,
  returnItem,
  returnPolicyModal,
  returnReasonId,
  returnReasons,
  returnReasonsSelected,
  returnShippingDate,
  returnShippingHour,
  returnShippingType,
  returnSummary,
  shipConfirmationNumber,
  shippingDates,
  shippingHours,
  trackShipmentData,
  trackShipmentMessage,
  returnRestrictedCategories,
});
