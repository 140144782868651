import { ALayoutSpacer } from 'component_lib';
import { colors } from '@italist/component-library';

import { ColumnReturn } from 'component/layout/Column';
import { Row } from 'component/layout/Row';
import { customerCareEmail, mailTo } from '../../helper/helpers';
import { Tr } from '../../helper/i18n/Tr';
import { Text } from '../text/Text';

import { Underline, TextLink } from './DifferentArticleSubtext.styles';

/* Component */
const DifferentArticleSubtext = () => (
  <Row>
    <ColumnReturn>
      <Text color={colors.red} bold>
        <Tr
          k="returns::print:text3"
          components={{
            anchor: () => <TextLink href={mailTo(customerCareEmail)}>{customerCareEmail}</TextLink>,
            Underline: ({ children }) => <Underline>{children}</Underline>,
          }}
        />
      </Text>
      <ALayoutSpacer height="24px" />
    </ColumnReturn>
  </Row>
);

export default DifferentArticleSubtext;
