import { Reducer } from 'redux';
import { UPDATE } from '../action/type';

export const shippingDates: Reducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE.SHIPPING_DATES:
      return action.value;
    default:
      return state;
  }
};

export const shippingHours: Reducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE.SHIPPING_HOURS:
      return action.value;
    default:
      return state;
  }
};
