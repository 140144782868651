type consentStatus = 'granted' | 'denied';

interface UserCentristData {
  consent: {
    services: { [key: string]: { consent: boolean; name: string } };
  };
  gcm: {
    adPersonalization: consentStatus;
    adStorage: consentStatus;
    adUserData: consentStatus;
    adsDataRedaction: boolean;
    analyticsStorage: consentStatus;
  };
  ui: {
    language: string;
  };
}

export const isConsentGranted = (service: string): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  const ucData = JSON.parse(localStorage?.getItem('ucData') ?? '{}') as UserCentristData;
  if (ucData?.consent?.services !== undefined) {
    let found = false;
    Object.entries(ucData.consent.services).forEach(([_, value]) => {
      if (value.name === service && value.consent) {
        found = true;
      }
    });
    return found;
  }
  return false;
};
