import { Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { AAnimationFadeInSecondary } from '../animation/AFade';

export const AModalBackdrop = styled(Row)`
  &&& {
    align-items: center;
    animation: ${AAnimationFadeInSecondary.getName()} 0.25s ease-in-out 1;
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    justify-content: center;
    left: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
`;
