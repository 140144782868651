import { Resource } from 'i18next';
import { getActiveLangOrOverride } from './helpers/getActiveLangOrOverride';
import { LanguagesConfig } from './types/LanguagesConfig';

export const getI18nextOptions = ({
  langIsoCode2,
  countryIsoCode2,
  namespaces,
  resources,
  languagesConfig,
}: {
  countryIsoCode2: string;
  langIsoCode2: string;
  languagesConfig: LanguagesConfig;
  namespaces: string[];
  resources: Resource;
}) => {
  const langOrOverride = getActiveLangOrOverride({
    countryIsoCode2,
    langIsoCode2,
    languagesConfig,
  });

  return {
    debug: false,
    defaultNS: 'common',
    fallbackLng: 'en',
    // keys will be the fallback
    initImmediate: false,
    interpolation: {
      // we do not use keys in form messages.welcome
      escapeValue: false, // react already safes from xss
    },
    keySeparator: false as false | string,
    lng: langOrOverride,
    ns: namespaces,
    nsSeparator: '::',
    // fallback to keys
    resources,
    returnEmptyString: false,
    returnNull: false,
  };
};
