import { colors, fontFamilies } from '@italist/component-library';
import styled from 'styled-components';
import { fontSize, fontWeight, lineHeight } from '../../theme/StylePalette';
import { arabic14FontSizeCSSRule } from '../../../helper/fonts';

/* Interfaces */
export interface IATitleProps {
  textColor?: string;
}

/* Components */
export const ATitlePrimary = styled.h1`
  color: ${({ textColor }: IATitleProps) => textColor || colors.black};
  display: inline;
  font-family: ${fontFamilies.regular};
  font-size: ${fontSize.primary};
  font-weight: ${fontWeight.primary};
  letter-spacing: 1px;
  line-height: ${lineHeight.primary};
  text-transform: capitalize;
`;

export const ATitleSecondary = styled(ATitlePrimary.withComponent('h2'))`
  font-size: ${fontSize.secondary};
  line-height: ${lineHeight.secondary};
`;

export const ATitleTertiary = styled(ATitlePrimary.withComponent('h3'))`
  font-family: ${fontFamilies.regular};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;

  @media (max-width: 1023px) {
    font-size: 14px;
    html[dir='rtl'] & {
      ${arabic14FontSizeCSSRule}
    }
  }
`;
