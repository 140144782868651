import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action, Dispatch } from 'redux';
import { toggleReturnPolicyModal } from '../../common/action/creator';
import { ModalLink } from './Link';

/* Interfaces */
interface IProps {
  children: React.ReactNode;
  handleReturnPolicyModal: () => Action;
}

/* Helpers */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleReturnPolicyModal: () => dispatch(toggleReturnPolicyModal()),
});

/* Component */
const ReturnPolicyModalLinkBase = ({ children, handleReturnPolicyModal }: IProps) => (
  <ModalLink onClick={handleReturnPolicyModal}>{children}</ModalLink>
);

export const ReturnPolicyModalLink = withRouter(connect(null, mapDispatchToProps)(ReturnPolicyModalLinkBase));
