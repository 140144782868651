import { Reducer } from 'redux';
import { UPDATE } from '../action/type';

export const returnReasonId: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.RETURN_REASON_ID:
      return action.value;
    default:
      return state;
  }
};

export const returnReasons: Reducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE.RETURN_REASONS:
      return action.value;
    default:
      return state;
  }
};
