import { OModalProcessing } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IAppStore } from '../../common/type';
import { useT } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  processingModalText: string;
}

/* Helpers */
const mapStateToProps = ({ processingModalText }: IAppStore) => ({ processingModalText });

/* Component */
const ModalProcessingBase = ({ processingModalText }: IProps) => {
  const { t } = useT();
  return (
    <OModalProcessing
      message={processingModalText ? t(`returns::modal:${processingModalText}` as any) : ''}
      visible={!!processingModalText}
    />
  );
};

export const ModalProcessing = withRouter(connect(mapStateToProps)(ModalProcessingBase));
