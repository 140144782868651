import styled from 'styled-components';

/* Interfaces */
export interface IArrowProps {
  size?: string;
}

/* Styles */
const Arrow = styled.img`
  display: inline-flex;
  height: ${({ size }: IArrowProps) => size || '14px'};
  width: auto;
`;

/* Components */
export const AArrowLeftWhite = ({ size }: IArrowProps) => (
  <Arrow size={size} src={'https://d3nn9jcidi1lkh.cloudfront.net/mkt/icon/arrow_left_white.png'} />
);
