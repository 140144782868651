import { fontFamilies, colors } from '@italist/component-library';
import styled from 'styled-components';
import { TableRowOrderSummaryNormal } from './TableRowOrderSummary';

/* Interfaces */
interface IReturnSummaryProps {
  currency: string;
  title?: string;
  total: string;
  rowsList: {label: string; amount: number;}[];
}

/* Styles */
const SummaryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media (max-width: 1023px) {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid ${colors.grayE5};
  }
`;

const Summary = styled.div`
  font-size: 16px;
  line-height: 30px;
  width: 345px;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 28px;
    width: 100%;
  }
`;

const TableTitle = styled.div`
  font-family: ${fontFamilies.bold};
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }
`;


/* Component */
export const TableReturnSummary = (props: IReturnSummaryProps) => {
  const {
    currency,
    title,
    rowsList,
  } = props;

  return (
    <SummaryWrapper>
      <Summary>
        <TableTitle>{title}</TableTitle>

        { rowsList.map((row) => (
          <TableRowOrderSummaryNormal key={row.label} amount={(row.amount/100).toFixed(2)} currency={currency} name={row.label} />
        ))}
      </Summary>
    </SummaryWrapper>
  );
};
