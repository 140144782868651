import { Reducer } from 'redux';
import { CLEAR, UPDATE } from '../action/type';
import { Gender } from '../type';

export const gender: Reducer = (state = Gender.Woman, action) => {
  switch (action.type) {
    case UPDATE.CUSTOMER_GENDER:
      return action.value;
    default:
      return state;
  }
};

export const genderError: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.CUSTOMER_GENDER_ERROR:
      return action.value;
    case CLEAR.CUSTOMER_GENDER_ERROR:
      return '';
    default:
      return state;
  }
};
