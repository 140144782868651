import { Col } from 'react-flexbox-grid';
import { ABarPrimary, ALayoutRowNoMargin, ALayoutSpacer, AModalClose, ATitleSecondary } from '../../atom';

/* Interfaces */
export interface IMModalHeaderProps {
  handleClose: () => void;
  title: string;
}

/* Component */
export const MModalHeader = ({ handleClose, title }: IMModalHeaderProps) => (
  <>
    <ALayoutRowNoMargin between="xs">
      <Col>
        <ATitleSecondary>{title}</ATitleSecondary>
        <ALayoutSpacer height={'10px'} />
      </Col>
      <Col>
        <AModalClose onClick={handleClose} />
      </Col>
    </ALayoutRowNoMargin>

    <ABarPrimary />

    <ALayoutSpacer height={'10px'} />
  </>
);
