import { keyframes } from 'styled-components';

export const AAnimationFadeInPrimary = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const AAnimationFadeInSecondary = keyframes`
  0% { background: rgba(0,0,0,0); }
  100% { background: rgba(0,0,0,0.8); }
`;
