import { ALayoutSpacer } from 'component_lib';
import { ReturnPolicyModalLink } from '..';
import { Text } from '../text/Text';
import { Tr } from '../../helper/i18n/Tr';

import { Strong, Ul, Li, P, Row, Column, Orange } from './ReturnWarning.styles';

const ReturnWarning = () => (
  <Row>
    <Column>
      <Text>
        <Tr
          components={{
            anchor: ({ children }) => <ReturnPolicyModalLink>{children}</ReturnPolicyModalLink>,
            Strong: ({ children }) => <Strong>{children}</Strong>,
            Ul: ({ children }) => <Ul>{children}</Ul>,
            Li: ({ children }) => <Li>{children}</Li>,
          }}
          k={'returns::policy:text1'}
        />
      </Text>
      <ALayoutSpacer mobileHeight="36px" />
    </Column>
    <Column>
      <Text>
        <Tr
          components={{
            Strong: ({ children }) => <Strong>{children}</Strong>,
            P: ({ children }) => <P>{children}</P>,
            Orange: ({ children }) => <Orange>{children}</Orange>,
          }}
          k={'returns::policy:text2'}
        />
      </Text>
    </Column>
  </Row>
);

export default ReturnWarning;
