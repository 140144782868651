import { Reducer } from 'redux';
import { CLEAR, UPDATE } from '../action/type';

export const profileFormSuccessMessage: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.PROFILE_FORM_MESSAGE:
      return action.value;
    case CLEAR.PROFILE_FORM_MESSAGE:
      return '';
    default:
      return state;
  }
};
