import React from 'react';
import { ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { clearReturnData } from '../../common/action/creator';
import { browserPath } from '../../common/path';
import { IAppStore } from '../../common/type';
import { ButtonBackForward, PageTitle } from '../../component';
import { Layout } from '../../component/layout/Layout';
import ReturnWarning from '../../component/ReturnWarning';
import { track, TrackingEvents } from '../../helper/tracking';
import { withT, TranslationFunc } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  email: string;
  handleResetReturnData: () => void;
  history: any;
  match: {
    params: {
      index: string;
      productStoreOrderId: string;
      storeOrderId: string;
    };
  };
  t: TranslationFunc;
}

/* Helpers */
const mapStateToProps = ({ email }: IAppStore) => ({
  email,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleResetReturnData: () => dispatch(clearReturnData()),
});

/* Component */
class PReturnWarningBase extends React.Component<IProps> {
  public componentDidMount() {
    this.props.handleResetReturnData();
  }

  public render() {
    const { productStoreOrderId, storeOrderId, index } = this.props.match.params;
    const { email, history, t } = this.props;

    return (
      <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
        <ALayoutSpacer mobileHeight={'70px'} />
        <PageTitle>{t('returns::process')}</PageTitle>
        <ALayoutSpacer height={'40px'} />

        <ReturnWarning />

        <ButtonBackForward
          handleBack={() => {
            track(TrackingEvents.returnEnded, { my_account: { page: 'Return Policy' } }, email);
            history.push(browserPath.orders());
          }}
          backHref=""
          backText={t('returns::noThanks')}
          handler={() => {
            track(TrackingEvents.returnContinued, { my_account: { page: 'Return Policy' } }, email);
            history.push(browserPath.newReturn(storeOrderId, productStoreOrderId, index));
          }}
          forwardText={t('returns::agree')}
        />
      </Layout>
    );
  }
}

export const PReturnWarning = withT(withRouter(connect(mapStateToProps, mapDispatchToProps)(PReturnWarningBase)));
