import { Dropdown } from '@italist/component-library';
import React from 'react';
import { ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Text } from 'component/text/Text';
import { Column } from 'component/layout/Column';
import {
  getAvailableShippingDates,
  updateReturnShippingDate,
  updateReturnShippingHour,
} from '../../common/action/creator';
import { IAppStore, IDropdownOption, IOrderItem } from '../../common/type';
import { TranslationFunc } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  fetchAvailableShippingDates: (storeOrderId: string) => void;
  handleReturnShippingDate: (e: string) => void;
  handleReturnShippingHour: (e: string) => void;
  returnItem: IOrderItem;
  returnShippingDate: string;
  returnShippingHour: string;
  shippingDates: IDropdownOption[];
  shippingHours: IDropdownOption[];
  t: TranslationFunc;
}

/* Helpers */
const mapStateToProps = ({
  returnItem,
  returnShippingDate,
  returnShippingHour,
  shippingDates,
  shippingHours,
}: IAppStore) => ({
  returnItem,
  returnShippingDate,
  returnShippingHour,
  shippingDates,
  shippingHours,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  fetchAvailableShippingDates: (storeOrderId: string) => dispatch(getAvailableShippingDates(storeOrderId)),
  handleReturnShippingDate: (e: string) => dispatch(updateReturnShippingDate(e)),
  handleReturnShippingHour: (e: string) => dispatch(updateReturnShippingHour(e)),
});

/* Component */
class FormShippingDateBase extends React.Component<IProps> {
  public componentDidMount() {
    if (this.props.returnItem.storeOrderId !== undefined) {
      this.props.fetchAvailableShippingDates(this.props.returnItem.storeOrderId);
    }
  }

  public translatedTimeOpts() {
    const { shippingHours, t } = this.props;
    return shippingHours
      ? shippingHours.map((v: IDropdownOption, idx: number) => ({
          label: t(`returns::book:timeOpt${idx}` as any),
          value: v.value,
        }))
      : [];
  }

  public render() {
    const {
      handleReturnShippingDate,
      handleReturnShippingHour,
      returnShippingDate,
      returnShippingHour,
      shippingDates,
      t,
    } = this.props;

    return (
      <>
        <Column>
          <Text bold capitalize>
            {t('returns::book:selectPickupDate')}
          </Text>
          <ALayoutSpacer height={'30px'} />
          <Dropdown items={shippingDates} onSelectedValue={handleReturnShippingDate} placeholder={returnShippingDate} />
        </Column>
        <ALayoutSpacer height={'54px'} />
        <Column>
          <Text bold capitalize>
            {t('returns::book:selectPickupTime')}
          </Text>
          <ALayoutSpacer height={'30px'} />
          <Dropdown
            items={this.translatedTimeOpts()}
            onSelectedValue={handleReturnShippingHour}
            placeholder={returnShippingHour}
          />
        </Column>
      </>
    );
  }
}

export const FormShippingDate = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormShippingDateBase));
