import React from 'react';
import { Row } from 'react-flexbox-grid';
import { ALayoutRowNoMargin, ATextError, ATitleTertiary, ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RadioButton } from 'component/button/RadioButton';
import { updateCustomerGender } from '../../common/action/creator';
import { Gender, IAppStore } from '../../common/type';
import { useT } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  gender: string;
  genderError: string;
  handleCustomerGender: (e: React.FormEvent<HTMLInputElement>) => void;
}

/* Helpers */
const mapStateToProps = ({ gender, genderError }: IAppStore) => ({ gender, genderError });

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleCustomerGender: (e: React.FormEvent<HTMLInputElement>) => dispatch(updateCustomerGender(e.currentTarget.value)),
});

/* Component */
const FormCustomerGenderBase = ({ gender, genderError, handleCustomerGender }: IProps) => {
  const { t } = useT();

  return (
    <>
      <Row>
        <ATitleTertiary>{t('profile::gender')}</ATitleTertiary>
      </Row>
      <ALayoutSpacer height="30px" />
      <Row>
        <RadioButton
          checked={gender === Gender.Woman}
          error={!!genderError}
          onChange={handleCustomerGender}
          value={Gender.Woman}
          label={t('profile::woman')}
        />
      </Row>
      <ALayoutSpacer height="20px" />
      <Row>
        <RadioButton
          checked={gender === Gender.Man}
          error={!!genderError}
          onChange={handleCustomerGender}
          value={Gender.Man}
          label={t('profile::man')}
        />
      </Row>
      <Row>
        <ALayoutRowNoMargin>{genderError ? <ATextError>{genderError}</ATextError> : null}</ALayoutRowNoMargin>
      </Row>
    </>
  );
};

export const FormCustomerGender = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormCustomerGenderBase));
