import { Button } from '@italist/component-library';
import React from 'react';
import { AInfoTooltip, ALayoutSpacer, MErrorMessage } from 'component_lib';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Layout, ButtonWrapper, ButtonSubLabel } from 'component/layout/Layout';
import { Row } from 'component/layout/Row';
import { ColumnReturn } from 'component/layout/Column';
import { Text, Strong } from 'component/text/Text';
import { track, TrackingEvents } from '../../helper/tracking';
import { fetchOrders, getReturnDetails } from '../../common/action/creator';
import { browserPath } from '../../common/path';
import {IAppStore, IOrderItem, IReturnDocuments, ReturnType} from '../../common/type';
import { ButtonBackForward, ReturnProgressBar } from '../../component';
import { TextLink } from '../../component/link/Link';
import { Tr, TranslationFunc, withT } from '../../helper/i18n';

/* Interfaces */
interface IProps {
  email: string;
  handleGetOrders: () => void;
  handleGetReturn: (returnId: string) => void;
  history: any;
  match: {
    params: {
      returnId: string;
    };
  };
  returnDocuments: IReturnDocuments;
  returnDocumentsError: boolean;
  returnId: string;
  returnRequestExpiredError: boolean;
  returnShippingType: ReturnType;
  t: TranslationFunc;
  paperlessReturnFlag?: boolean;
  returnItem: IOrderItem;
}

interface ITooltip {
  email: string;
  text: string;
  trackingEvent: string;
}

const trackInfoViewed = (trackingEvent: string, email: string) => {
  track(trackingEvent, undefined, email);
};

/* Helpers */
const Tooltip = ({ email, text, trackingEvent }: ITooltip) => (
  <>
    <MediaQuery minWidth={576}>
      <span onMouseEnter={() => trackInfoViewed(trackingEvent, email)}>
        <AInfoTooltip orientation={'right'} text={text} />
      </span>
    </MediaQuery>
    <MediaQuery maxWidth={575}>
      <span onMouseEnter={() => trackInfoViewed(trackingEvent, email)}>
        <AInfoTooltip orientation={'left'} text={text} />
      </span>
    </MediaQuery>
  </>
);

const mapStateToProps = (
  { email, returnDocuments, returnDocumentsError, returnRequestExpiredError, returnShippingType, returnId, paperlessReturnFlag, returnItem }: IAppStore,
  ownProps: IProps,
) => ({
  email,
  returnDocuments,
  returnDocumentsError,
  returnShippingType,
  returnRequestExpiredError,
  returnId: ownProps.match.params.returnId || returnId,
  paperlessReturnFlag: paperlessReturnFlag,
  returnItem: returnItem,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>) => ({
  handleGetOrders: () => dispatch(fetchOrders()),
  handleGetReturn: (returnId: string) => dispatch(getReturnDetails(returnId)),
});

const getDesc = (returnShippingType: ReturnType) =>
  returnShippingType === ReturnType.PICK_UP ? 'returns::print:desc1' : 'returns::print:desc';

const getDescPaperless = (returnShippingType: ReturnType) =>
  returnShippingType === ReturnType.PICK_UP ? 'returns::print:desc1Paperless' : 'returns::print:descPaperless';

function showPaperlessUsComponent(paperlessReturnFlag: boolean, countryCode: string) {
  return !paperlessReturnFlag || (paperlessReturnFlag && countryCode != "US")
}

/* Component */
class PReturnPrintBase extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    const { handleGetReturn, returnId } = this.props;
    if (returnId) {
      handleGetReturn(returnId);
    }
  }

  public render() {
    const {
      email,
      history,
      t,
      returnDocuments,
      returnDocumentsError,
      returnRequestExpiredError,
      returnId,
      returnShippingType,
      paperlessReturnFlag,
      returnItem,
    } = this.props;
    const { awb, invoice, returnNumber, storeOrderNumber, waybill } = this.props.returnDocuments;
    return (
      <>
        <ALayoutSpacer height={'25px'} />

        <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
          <ALayoutSpacer desktopHeight={'25px'} mobileHeight={'40px'} />
          <ReturnProgressBar activeStep={2} />
        </Layout>
        <ALayoutSpacer desktopHeight={'100px'} mobileHeight={'50px'} />
        <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
          {
            paperlessReturnFlag ? (
              <Text bold>{t('returns::print:titleWaybill')}</Text>
            ) : (
              <>
                {returnShippingType === ReturnType.PICK_UP && <Text bold>{t('returns::print:title1')}</Text>}
                {returnShippingType === ReturnType.DROP_OFF && <Text bold>{t('returns::print:title')}</Text>}
              </>
            )
          }
          <ALayoutSpacer desktopHeight={'40px'} mobileHeight={'25px'} />
          {returnDocuments && !returnDocumentsError && !returnRequestExpiredError ? (
            <>
              <Row>
                <ColumnReturn>
                  <Text>
                    <Tr
                      components={{
                        Strong: ({ children }) => <Strong>{children}</Strong>,
                      }}
                      k={paperlessReturnFlag ? getDescPaperless(returnShippingType) : getDesc(returnShippingType)}
                    />
                  </Text>
                </ColumnReturn>
              </Row>

              <ALayoutSpacer desktopHeight={'16px'} mobileHeight={'55px'} />
              {
                showPaperlessUsComponent(paperlessReturnFlag, returnItem.countryCode) && (
                  <Text bold>{t('returns::print:step1')}:</Text>
                )
              }
              <ALayoutSpacer desktopHeight={'8px'} mobileHeight={'8px'} />

              <Row alignItems="center">
                <ButtonWrapper maxWidth="300px">
                  <TextLink target="_blank" href={waybill}>
                    <Button
                      height="40px"
                      fullWidth
                      lightTheme
                      onClick={() => {
                        track(
                          TrackingEvents.waybillDownloaded,
                          {
                            return_order: {
                              awb,
                              return_number: returnNumber,
                              store_order: {
                                store_order_number: storeOrderNumber,
                              },
                            },
                          },
                          email,
                        );
                      }}
                      padding={{ x: 0, y: 9 }}
                      text={t('returns::print:downloadWaybill').toUpperCase()}
                    />
                  </TextLink>
                </ButtonWrapper>
                <Tooltip
                  email={email}
                  text={t('returns::waybillInfo')}
                  trackingEvent={TrackingEvents.proformaInfoViewed}
                />
              </Row>

              <ALayoutSpacer desktopHeight={'4px'} mobileHeight={'4px'} />
              <ButtonSubLabel maxWidth="300px">
                {!paperlessReturnFlag && (
                  <Text fontSizeDesktop="12px" fontSizeMobile="12px">
                    ({t('returns::print:waybillText')})
                  </Text>
                )}
              </ButtonSubLabel>
              {
                showPaperlessUsComponent(paperlessReturnFlag, returnItem.countryCode)
                && (
                  <>
                    <ALayoutSpacer desktopHeight={'16px'} mobileHeight={'16px'} />
                    <Text bold>{t('returns::print:step2')}:</Text>
                    <ALayoutSpacer desktopHeight={'8px'} mobileHeight={'8px'} />
                    <Row alignItems="center">
                      <ButtonWrapper maxWidth="300px">
                        <TextLink target="_blank" href={invoice}>
                          <Button
                            fullWidth
                            // height="40px"
                            lightTheme
                            onClick={() => {
                              track(
                                TrackingEvents.proformaDownloaded,
                                {
                                  return_order: {
                                    awb,
                                    return_number: returnNumber,
                                    store_order: {
                                      store_order_number: storeOrderNumber,
                                    },
                                  },
                                },
                                email,
                              );
                            }}
                            padding={{ x: 8, y: 8 }}
                            text={t('returns::print:downloadProforma').toUpperCase()}
                          />
                        </TextLink>
                      </ButtonWrapper>
                      <Tooltip
                        email={email}
                        text={t('returns::proformaInfo')}
                        trackingEvent={TrackingEvents.proformaInfoViewed}
                      />
                    </Row>
                  </>
                )
              }
              <ButtonBackForward
                handleBack={() => {
                  track(TrackingEvents.returnEnded, { my_account: { page: 'Print Documents Page' } }, email);
                  history.push(browserPath.orders());
                }}
                backHref=""
                backText={t('common::cancel')}
                handler={() => {
                  track(TrackingEvents.returnFinished, undefined, email);
                  history.push(browserPath.pack(returnId));
                }}
                forwardText={t('common::confirm')}
              />
            </>
          ) : (
            <>
              <ALayoutSpacer height={'50px'} />

              <ColumnReturn>
                {returnRequestExpiredError ? (
                  <MErrorMessage message={t('ordersAndShipping::returnExpired')} />
                ) : (
                  <MErrorMessage message={t('returns::print:somethingWrong')} />
                )}
              </ColumnReturn>

              <ButtonBackForward
                backHref=""
                backText={t('common::cancel')}
                forwardHref={browserPath.orders()}
                forwardText={t('common::confirm')}
                handleBack={() => {
                  this.props.handleGetOrders();
                  history.push(browserPath.orders());
                }}
              />
            </>
          )}
          {/* {this.shouldRenderSuccess() ? this.renderSuccess() : this.renderError()} */}
        </Layout>
      </>
    );
  }
}

export const PReturnPrint = withT(withRouter(connect(mapStateToProps, mapDispatchToProps)(PReturnPrintBase)));
