import { datadogRum } from '@datadog/browser-rum';
import {isConsentGranted} from "./consent";

export const initDataDogRum = () => {
  if (process.env.NODE_ENV === 'production' && isConsentGranted('Datadog Real User Monitoring')) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
      env: 'new-prod',
      site: 'datadoghq.com',
      service: 'my-account-app-rum',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
};
