import { ALayoutSpacer } from 'component_lib';
import MediaQuery from 'react-responsive';
import { browserPath } from '../../common/path';
import { IOrder, OrderStatusId } from '../../common/type';
import { LinkTertiary } from '../link/Link';
import { TrackOrderLink } from '../link/TrackOrderLink';
import { useT, TranslationFunc } from '../../helper/i18n';
import { TableLabel, TableText } from './TableText';
import { Column } from '../layout/Column';
import { Row } from '../layout/Row';
import {formatPrice} from "../../helper/formatPrice";

/* Helpers */
const displayAndMore = (orderId: string, visible: boolean, trackOrderSelected: any, t: TranslationFunc) =>
  visible && (
    <LinkTertiary onClick={trackOrderSelected} to={browserPath.orderDetail(orderId)} underline>
      {t('ordersAndShipping::displayMore')}
    </LinkTertiary>
  );

const translateShippingStatus = (orderStatusId: string, storeOrderId: string, t: TranslationFunc) => {
  switch (orderStatusId) {
    case OrderStatusId.shipped:
      return <TrackOrderLink storeOrderId={storeOrderId} />;
    case OrderStatusId.processing:
    case OrderStatusId.accepted:
      return <TableText>{t('ordersAndShipping::shipping:pending')}</TableText>;
    case OrderStatusId.cancelled:
      return <TableText>{t('ordersAndShipping::shipping:cancelled')}</TableText>;
    default:
      return <TableText>{t('ordersAndShipping::shipping:delivered')}</TableText>;
  }
};

const translateOrderStatus = (orderStatusId: string, t: TranslationFunc) => {
  switch (orderStatusId) {
    case OrderStatusId.accepted:
    case OrderStatusId.shipped:
      return <TableText>{t('ordersAndShipping::orderStatus:accepted')}</TableText>;
    case OrderStatusId.cancelled:
      return <TableText>{t('ordersAndShipping::orderStatus:cancelled')}</TableText>;
    case OrderStatusId.processing:
      return <TableText>{t('ordersAndShipping::orderStatus:processing')}</TableText>;
    default:
      return <TableText>{t('ordersAndShipping::orderStatus:completed')}</TableText>;
  }
};

/* Component */
export const TableOrderRowInfo = (props: IOrder) => {
  const { t } = useT();
  const {
    currency,
    orderDate,
    orderId,
    orderNumber,
    orderStatus,
    price,
    productVersionOptionIds,
    quantity,
    storeOrderIds,
    storeOrderNumbers,
    trackOrderSelected,
  } = props;

  const showAndMore = productVersionOptionIds.length > 1;

  return (
    <>
      <Column flex={2}>
        <MediaQuery maxWidth={1023}>
          <Row>
            <TableLabel>{t('ordersAndShipping::orderDate')}</TableLabel>
          </Row>
        </MediaQuery>
        <Row>
          <TableText>{orderDate}</TableText>
        </Row>
      </Column>
      <ALayoutSpacer mobileHeight={'15px'} />
      <Column flex={2}>
        <MediaQuery maxWidth={1023}>
          <Row>
            <TableLabel>{t('ordersAndShipping::orderNumber')}</TableLabel>
          </Row>
        </MediaQuery>
        <LinkTertiary onClick={trackOrderSelected} to={browserPath.orderDetail(orderId)} underline>
          <Row>
            <TableText>{orderNumber}</TableText>
          </Row>
        </LinkTertiary>
        <Row>
          <TableText>{storeOrderNumbers[0]}</TableText>
        </Row>
        <Row>{displayAndMore(orderId, showAndMore, trackOrderSelected, t)}</Row>
      </Column>
      <ALayoutSpacer mobileHeight={'15px'} />
      <Column flex={2}>
        <MediaQuery maxWidth={1023}>
          <Row>
            <TableLabel>{t('ordersAndShipping::orderStatus')}</TableLabel>
          </Row>
        </MediaQuery>
        <Row>
          <TableText>{translateOrderStatus(orderStatus.order_status_id, t)}</TableText>
        </Row>
      </Column>
      <ALayoutSpacer mobileHeight={'15px'} />
      <Column flex={2}>
        <MediaQuery maxWidth={1023}>
          <Row>
            <TableLabel>{t('ordersAndShipping::shippingStatus')}</TableLabel>
          </Row>
        </MediaQuery>
        <Row> {translateShippingStatus(orderStatus.order_status_id, storeOrderIds[0], t)}</Row>
      </Column>
      <ALayoutSpacer mobileHeight={'15px'} />

      <Column flex={1}>
        <Row>
          <TableText>{quantity}</TableText>
        </Row>
      </Column>

      <ALayoutSpacer mobileHeight={'15px'} />

      <Column flex={1}>
        <Row>
          <TableText>
            {currency} {formatPrice(price, currency)}
          </TableText>
        </Row>
      </Column>
    </>
  );
};
