import { ACartImage } from 'component_lib';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { Column } from '../layout/Column';
import { IAppStore, IOrderItem } from '../../common/type';
import { TableOrderDetailsRowInfo } from './TableOrderDetailsRowInfo';
import { TableRow, InfoRow } from './TableRow';

/* Interfaces */
interface IProps extends IOrderItem {
  email: string;
  includeReturnColumn?: boolean;
  orderStatus?: string;
}

/* Helpers */
const mapStateToProps = ({ email }: IAppStore) => ({
  email,
});

/* Component */
const TableOrderDetailRowBase = (props: IProps) => {
  const { imgSrc } = props;

  return (
    <>
      <TableRow desktopHeight={'320px'}>
        <MediaQuery maxWidth={1023}>
          <Column alignItems="center">
            <ACartImage src={imgSrc} />
          </Column>
          <Column marginLeft={'25px'}>
            <InfoRow>
              <TableOrderDetailsRowInfo {...props} />
            </InfoRow>
          </Column>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <Column flex={3} paddingRight={'20px'}>
            <ACartImage src={imgSrc} />
          </Column>
          <TableOrderDetailsRowInfo {...props} />
        </MediaQuery>
      </TableRow>
    </>
  );
};

export const TableOrderDetailRow = withRouter(connect(mapStateToProps)(TableOrderDetailRowBase));
