import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { browserPath } from '../../common/path';
import { IAppStore, IOrderDetail, IOrderItem, IReturnSummary } from '../../common/type';

/* Interfaces */
interface IProps {
  match: {
    params: {
      index: string;
      productStoreOrderId: string;
      storeOrderId: string;
    };
  };
  orders: IOrderDetail[];
  returnSummary: IReturnSummary;
}

/* Helpers */
const mapStateToProps = ({ orders, returnSummary }: IAppStore) => ({ orders, returnSummary });

/* Component */
const RedirectIfWrongUrlIdBase = ({ match, orders, returnSummary }: IProps) => {
  if (!orders) {
    return null;
  }
  const id = match.params.storeOrderId;
  const productId = match.params.productStoreOrderId;
  const { index } = match.params;

  if (!id || !productId) {
    return <Redirect to={browserPath.orders()} />;
  }
  const order = orders.find((o: IOrderDetail) => o.storeOrderIds.some((storeOrderId: string) => storeOrderId === id));
  const item = order
    ? order.items.find(
        (it: IOrderItem) => it.storeOrderId === id && it.productStoreOrderId === productId && it.index === index,
      )
    : null;

  if (item && item.returnId) {
    return <Redirect to={browserPath.printReturn(item.returnId)} />;
  }

  return !Object.keys(returnSummary).length ? <Redirect to={browserPath.newReturn(id, productId, index)} /> : null;
};

export const RedirectIfWrongUrlId = withRouter(connect(mapStateToProps)(RedirectIfWrongUrlIdBase));
