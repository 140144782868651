import React from 'react';
import styled from 'styled-components';

const PageLiElement = styled.li<{ currentPage?: number; pageNumber?: number; showDots?: boolean }>`
  ${({ showDots }) => (!showDots ? 'cursor: pointer;' : '')}
  display: inline-block;
  height: 29px;
  width: 29px;
  margin: 0 5px;
  color: #000;
  border: 0px;
  font-family: 'Avenir Black', 'Arial', sans-serif;
  font-size: 15px;
  ${({ currentPage, pageNumber }) =>
    pageNumber && pageNumber === currentPage ? 'background-color: #ECEEEF;' : 'background-color: #FFFFFF;'};
  border-radius: 100%;
  ${({ showDots }) => (showDots ? `margin-top: 6px; text-align: center; height: 0px; ` : '')}
  &:hover {
    ${({ showDots }) => (!showDots ? 'background-color: #eceeef;' : '')};
  }
  @media (max-width: 1023px) {
    margin: 0;
    height: 24px;
    width: 24px;
  }
`;

const PageHrefElement = styled.a`
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginationElement: React.FC<{
  currentPage?: number;
  handlePageClick?: (e: any) => void;
  pageNumber?: number;
  showDots?: boolean;
}> = ({ pageNumber, currentPage, handlePageClick, showDots }) => (
  <>
    <PageLiElement currentPage={currentPage} pageNumber={pageNumber} showDots={showDots}>
      {!showDots && (
        <PageHrefElement
          data-cy={`link-pagination-${currentPage === 1 ? 'current' : '1'}`}
          onClick={() => handlePageClick && handlePageClick(pageNumber)}
        >
          {pageNumber}
        </PageHrefElement>
      )}
      {showDots && <span>...</span>}
    </PageLiElement>
  </>
);

export { PaginationElement };
