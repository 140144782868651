import { ALayoutSpacer } from 'component_lib';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Layout } from 'component/layout/Layout';

import { submitReturnReasonId } from '../../common/action/creator';
import { IAppStore, IOrderDetail, IOrderItem, IReturnReason } from '../../common/type';
import {
  ButtonBackForward,
  FormReturnReasons,
  ReturnProgressBar,
  TableOrderDetailHeader,
  TableOrderDetailRow,
} from '../../component';
import { track, TrackingEvents } from '../../helper/tracking';
import { withT, TranslationFunc } from '../../helper/i18n';
import { Text } from '../../component/text/Text';

/* Interfaces */
interface IProps {
  email: string;
  handleSubmitReason: (storeOrderId: string, productStoreOrderId: string, item: IOrderItem) => void;
  history: any;
  match: {
    params: {
      index: string;
      productStoreOrderId: string;
      storeOrderId: string;
    };
  };
  orders: IOrderDetail[];
  returnReasonId: string;
  returnReasons: IReturnReason[];
  t: TranslationFunc;
}

/* Helpers */
const getItemForReturn = (storeOrderId: string, productStoreOrderId: string, index: string, orders: IOrderDetail[]) => {
  const order = orders ? orders.filter((o) => o.storeOrderIds.indexOf(storeOrderId) > -1) : [];

  if (order[0]) {
    const item = order[0].items.filter((i) => i.productStoreOrderId === productStoreOrderId && i.index === index);

    if (item[0]) {
      return item[0];
    }
  }

  return null;
};

const mapStateToProps = ({ email, orders, returnReasonId, returnReasons }: IAppStore) => ({
  email,
  orders,
  returnReasonId,
  returnReasons,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStore, null, Action>, ownProps: IProps) => ({
  handleSubmitReason: (storeOrderId: string, productStoreOrderId: string, item: IOrderItem) =>
    dispatch(submitReturnReasonId(storeOrderId, productStoreOrderId, item, ownProps.history)),
});

/* Component */
const PReturnNewBase = (props: IProps) => {
  const {
    email,
    handleSubmitReason,
    match: {
      params: { productStoreOrderId, storeOrderId, index },
    },
    orders,
    history,
    t,
  } = props;

  const item = getItemForReturn(storeOrderId, productStoreOrderId, index, orders);

  if (!item) {
    return null;
  }

  const handleSubmit = () => {
    const { returnReasonId, returnReasons } = props;
    const trackReason = returnReasons.find((reason) => reason.reasonId === returnReasonId) || {
      reason: '',
    };
    track(
      TrackingEvents.reasonSelected,
      {
        return_order: { reason: trackReason.reason },
      },
      email,
    );
    handleSubmitReason(storeOrderId, productStoreOrderId, item);
  };

  const {
    brand,
    currency,
    imgSrc,
    itemColor,
    model,
    modelNumber,
    orderDate,
    price,
    quantity,
    productVersionOptionId,
    returnId,
    returnStatus,
    size,
    sizeType,
    storeOrderNumber,
    storeOrderStatus,
  } = item;

  return (
    <>
      <ALayoutSpacer height={'25px'} />
      <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
        <ALayoutSpacer desktopHeight={'25px'} mobileHeight={'40px'} />
        <ReturnProgressBar activeStep={0} />
      </Layout>

      <Layout maxWidthDesktop="calc(100% - 320px)" minWidthDesktop="950px">
        <ALayoutSpacer desktopHeight={'100px'} mobileHeight={'50px'} />
        <Text bold>{t('returns::reasons:title')}</Text>
        <ALayoutSpacer desktopHeight={'110px'} mobileHeight={'50px'} />
        <MediaQuery minWidth={1023}>
          <TableOrderDetailHeader />
        </MediaQuery>
        <TableOrderDetailRow
          brand={brand}
          currency={currency}
          imgSrc={imgSrc}
          index={index}
          itemColor={itemColor}
          model={model}
          modelNumber={modelNumber}
          orderDate={orderDate}
          price={price}
          productStoreOrderId={productStoreOrderId}
          productVersionOptionId={productVersionOptionId}
          quantity={quantity}
          returnId={returnId}
          returnStatus={returnStatus}
          size={size}
          sizeType={sizeType}
          storeOrderId={storeOrderId}
          storeOrderNumber={storeOrderNumber}
          storeOrderStatus={storeOrderStatus}
        />
        <ALayoutSpacer desktopHeight={'100px'} />
        <FormReturnReasons t={t} />

        <ButtonBackForward
          handleBack={() => {
            track(TrackingEvents.returnEnded, { my_account: { page: 'Select Reason Page' } }, email);
            history.push('/');
          }}
          backHref=""
          backText={t('returns::noThanks')}
          forwardText={t('returns::nextStep')}
          handler={handleSubmit}
        />
      </Layout>
    </>
  );
};

export const PReturnNew = withT(withRouter(connect(mapStateToProps, mapDispatchToProps)(PReturnNewBase)));
