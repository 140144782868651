import styled from 'styled-components';
import { color } from '../../theme/StylePalette';

export const AModalClose = styled.div`
  height: 20px;
  position: relative;
  width: 20px;

  &:before,
  &:after {
    background-color: ${color.black};
    content: ' ';
    height: 13px;
    left: 10px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
    width: 2px;
  }

  &:after {
    transform: rotate(-45deg);
  }
`;
