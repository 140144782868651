import styled, { keyframes } from 'styled-components';

/* Styles */
// http://cssload.net/en/spinners/4
const spin = keyframes`
  0%,
  100% {
    box-shadow:
      15px 15px rgb(79,77,73),
      -15px 15px rgb(223,223,223),
      -15px -15px rgb(79,77,73),
      15px -15px rgb(223,223,223);
  }
  25% {
    box-shadow:
      -15px 15px rgb(223,223,223),
      -15px -15px rgb(79,77,73),
      15px -15px rgb(223,223,223),
      15px 15px rgb(79,77,73);
  }
  50% {
    box-shadow:
      -15px -15px rgb(79,77,73),
      15px -15px rgb(223,223,223),
      15px 15px rgb(79,77,73),
      -15px 15px rgb(223,223,223);
  }
  75% {
    box-shadow:
      15px -15px #dfdfdf,
      15px 15px #1f1f1f,
      -15px 15px #dfdfdf,
      -15px -15px #1f1f1f;
  }
`;

export const AAnimationSpinnerPrimary = styled.div`
  animation: ${spin.getName()} ease infinite 4.6s;
  border-radius: 100%;
  box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
    15px -15px rgb(223, 223, 223);
  height: 15px;
  margin: 0 auto;
  width: 15px;
`;

/* Components */
export const AAnimationSpinnerSecondary = () => <img src="/loading.gif" alt="loading" />;
