import { ALayoutSpacer, ATextTertiary, ATitleSecondary } from '../../atom';
import { TextLink } from '../../../component/link/Link';
import styled from 'styled-components';

/* Interfaces */
interface IMCardAddressProps {
  address1: string;
  address2: string;
  city: string;
  country: string;
  firstName: string;
  href: string;
  lastName: string;
  phone: string;
  postalCode: string;
  stateRegion: string;
  title: string;
}

/* Styles */
const EditLink = styled(TextLink)`
  text-transform: uppercase;
`;

/* Component */
export const MCardAddress = (props: IMCardAddressProps) => {
  const { address1, address2, city, country, firstName, href, lastName, phone, postalCode, stateRegion, title } = props;

  return (
    <>
      <ATitleSecondary>{title}</ATitleSecondary>
      <ALayoutSpacer height={'20px'} />
      <ATextTertiary>
        {firstName} {lastName}
      </ATextTertiary>
      <ATextTertiary>{address1}</ATextTertiary>
      {address2 ? <ATextTertiary>{address2}</ATextTertiary> : null}
      <ATextTertiary>
        {city}, {stateRegion} {postalCode}
      </ATextTertiary>
      <ATextTertiary>{country}</ATextTertiary>
      <ATextTertiary>{phone}</ATextTertiary>
      <ALayoutSpacer height={'10px'} />
      <EditLink href={href}>edit</EditLink>
    </>
  );
};
