import { fontFamilies } from '@italist/component-library';
import {
  ALayoutSpacer,
  AListBulletItem,
  AListUnordered,
  ATextTertiary,
  ATitleTertiary,
  color,
  OModalInfo,
} from 'component_lib';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { toggleReturnPolicyModal } from '../../common/action/creator';
import { IAppStore } from '../../common/type';
import { useT } from '../../helper/i18n';
import { Tr } from '../../helper/i18n/Tr';
import { TextLink } from '../link/Link';

/* Interfaces */
interface IProps {
  handleReturnPolicyModal: () => void;
  returnPolicyModal: boolean;
}

/* Helpers */
const mapStateToProps = ({ returnPolicyModal }: IAppStore) => ({
  returnPolicyModal,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleReturnPolicyModal: () => dispatch(toggleReturnPolicyModal()),
});

/* Styles */
const Paragraph = styled(ATextTertiary)`
  margin-top: 10px;
`;

const Bold = styled.span`
  font-family: ${fontFamilies.bold};
`;

/* Component */
const ModalReturnsPolicyBase = ({ handleReturnPolicyModal, returnPolicyModal }: IProps) => {
  const { t } = useT();
  const paperlessReturnFlag = useSelector((state: IAppStore) => state.paperlessReturnFlag);

  return (
    <OModalInfo
      handleClose={handleReturnPolicyModal}
      title={t('returns::modal:policy:title')}
      visible={returnPolicyModal}
    >
      <ATitleTertiary>{t('returns::modal:policy:subtitle1')}</ATitleTertiary>
      <Paragraph>
        <Bold>{t('returns::modal:policy:p1')}</Bold>
      </Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p2')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>
        <Bold>{t('returns::modal:policy:p3')}</Bold>
      </Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p4')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>
        <Tr
          k={paperlessReturnFlag ? "returns::modal:policy:p5Paperless" : "returns::modal:policy:p5"}
          components={{
            anchor: ({ children }) => (
              <a style={{ color: color.black, textDecoration: 'underline' }} href={``}>
                {children}
              </a>
            ),
            bold: Bold,
          }}
        />
      </Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p6')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p7')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p8')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p9')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p10')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p11')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p12')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p13')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p14')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p15')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p16')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p17')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p18')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p19')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p20')}</Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p21')}</Paragraph>
      <ALayoutSpacer height={'20px'} />

      <ATitleTertiary>{t('returns::modal:policy:subtitle2')}</ATitleTertiary>
      <AListUnordered>
        <AListBulletItem>{t('returns::modal:policy:p22')}</AListBulletItem>
        <AListBulletItem>{t('returns::modal:policy:p23')}</AListBulletItem>
        <AListBulletItem>{t('returns::modal:policy:p24')}</AListBulletItem>
        <AListBulletItem>{t('returns::modal:policy:p25')}</AListBulletItem>
        <AListBulletItem>{paperlessReturnFlag ? t('returns::modal:policy:p26Paperless') : t('returns::modal:policy:p26')}</AListBulletItem>
        <AListBulletItem>{t('returns::modal:policy:p27')}</AListBulletItem>
        <AListBulletItem>
          <Tr
            k="returns::modal:policy:p28"
            components={{
              anchor: () => <TextLink href="mailto:customercare@italist.com">customercare@italist.com</TextLink>,
            }}
          />
        </AListBulletItem>
      </AListUnordered>
      <ALayoutSpacer height={'20px'} />

      <ATitleTertiary>{t('returns::modal:policy:subtitle3')}</ATitleTertiary>
      <Paragraph>{t('returns::modal:policy:p29')}</Paragraph>
      <ALayoutSpacer height={'20px'} />

      <ATitleTertiary>{t('returns::modal:policy:subtitle4')}</ATitleTertiary>
      <Paragraph>
        <Tr k="returns::modal:policy:p30" components={{ bold: Bold }} />
      </Paragraph>
      <ALayoutSpacer height={'8px'} />
      <Paragraph>{t('returns::modal:policy:p31')}</Paragraph>
      <ALayoutSpacer height={'20px'} />

      <ATitleTertiary>{t('returns::modal:policy:subtitle5')}</ATitleTertiary>
      <Paragraph>{t('returns::modal:policy:p32')}</Paragraph>
    </OModalInfo>
  );
};

export const ModalReturnsPolicy = withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalReturnsPolicyBase));
